<!-- eslint-disable max-lines -->
<template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button
                v-if="detailData.projectCode"
                v-has="authorityCode.subAddNewOpportunity"
                type="text" icon="el-icon-circle-plus-outline" size="medium" @click="handleAddRow('opportunity')"
                :disabled="isDisableBtn">新增商机</lots-button>
                <!-- v-if="!detailData.projectCode || +projectData.isLinkOppo !== 1 || btnPermissionsArr.includes(authorityCode.subAddNewCompanyS)" -->
            <lots-button
                v-has="authorityCode.subAddNewCompany" type="text" icon="el-icon-circle-plus-outline" size="medium" @click="handleAddRow('company')" :disabled="isDisableBtn">新增分公司</lots-button>
            <!-- <lots-button type="text" icon="el-icon-circle-check" size="medium" @click="handleSaveRows" v-show="detailPageState !== STATE_NEW" :disabled="isDisableBtn">保存</lots-button> -->
            <lots-button
                v-has="authorityCode.subAddNewCompanyBatch"
                v-if="detailData.contractGroupCode && $route.name === 'contractGroupManage'"
                type="text" icon="el-icon-refresh" size="medium" @click="handleSyncData" v-show="detailPageState !== STATE_NEW" :disabled="isDisableBtn">批量更新分公司</lots-button>
            <lots-button
                v-has="authorityCode.subDeleteCompany"
                type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows('normal')" v-show="detailPageState !== STATE_NEW" :disabled="isDisableBtn">删除</lots-button>
            <lots-button
                v-has="authorityCode.subAddNewOpportunityPrivilege"
                v-show="![STATE_NEW, STATE_OTHER_CHANGE].includes(detailPageState) && $route.name !== 'contractGroupManage'"
                type="text" icon="el-icon-circle-plus-outline" size="medium" @click="handleAddRow('opportunityPrivilege')">
                特权新增商机
            </lots-button>
            <lots-button
                v-has="authorityCode.subDeleteCompanyPrivilege"
                v-show="![STATE_NEW, STATE_OTHER_CHANGE].includes(detailPageState) && $route.name !== 'contractGroupManage'"
                type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows('privilege')" :loading="privilegeDeleteLoading">
                特权删除
            </lots-button>
            <lots-button
                v-has="authorityCode.subAddSiteCompanyBatch"
                v-if="!hiddenSiteBatch"
                type="text" icon="el-icon-circle-plus-outline" size="medium"
                @click="handleAddSiteBatch"
                :disabled="isDisableBtn || (!detailData.contractCode && !editTableTotalData.length)"
                :loading="privilegeDeleteLoading">
                批量维护服务平台
            </lots-button>
        </div>
        <div class="company-detail-table">
            <edit-table-box
                v-if="detailPageState === 'new'"
                class="prescreen"
                ref="editTableBox"
                :selection="config.selection"
                :columns="config.columns"
                :key="editTableKey"
                :height="tableHeight"
                :tableData="editTableTotalData"
                @selection-change="handleSelectedRow"
                @element-change="handleChangeRow">
                <template #col-append="{ item, row }">
                    <span v-if="item.prop === 'site'">{{row['siteNames']}}</span>
                </template>
                <template #edit-col-append="{ item, row, rIndex }">
                    <otpAdvanceMulti
                        v-if="item.prop === 'site' && detailPageState === 'new' && !row.disableProps.includes(item.prop)"
                        :ref="'advanceMuti' + rIndex"
                        @getAdvanceValue="(data) => { getAdvanceValue(data, row, item); }"
                        :mValue="row.site"
                        :formData="{ companyCode: row.companyCode }"
                        :params="item"
                        :disabled="row.disableProps ? row.disableProps.includes(item.prop) : true">
                    </otpAdvanceMulti>
                </template>
            </edit-table-box>
            <table-box
                v-else
                ref="tableBoxRead"
                :height="tableHeight"
                :selection="true"
                v-loading="subTabTable.tableLoading"
                :columns="detailData.contractGroupCode ? config.columnsGroupReadonly : config.columnsReadonly"
                :rows="subTabTable.totalData"
                :actions="actions"
                @selection-change="handleSelectedRow"
                @action-click="actionClick"
                @row-click="handleRowClick">
                    <template v-slot:col-append="{ col, row }">
                        <div v-if="col.prop === 'isPureWarehouse'">
                            <lots-select
                                v-model="row[col.prop]"
                                :optionsKey="col.optionsKey"
                                :disabled="readonly || isDisableBtn || (detailData.oilIsGroupCustomer + '' === '0')"
                                :placeholder="(readonly || isDisableBtn) ? '' : '请选择'"
                                @change="(data) => { changeIsWarehouse(row, data) }">
                            </lots-select>
                        </div>
                        <div v-else-if="col.prop === 'opportunityCode'">
                            <span v-if="btnPermissionsArr.includes('0707000062') && detailPageState !== STATE_OTHER_CHANGE && row.isSelected">
                                <lots-md-input
                                    :label="row[col.prop]"
                                    :config="opportunityAdvance(row)"
                                    :cascadeData="row"
                                    @change="(data) => { getAdvanceValue(data, row, col); }"
                                ></lots-md-input>
                            </span>
                            <span v-else>{{ row[col.prop] }}</span>
                        </div>
                    </template>
                </table-box>
            <div class="footer">
                <lots-pagination
                    v-if="detailPageState !== 'new'"
                    @size-change='subTabTable.sizeChange'
                    :current-page.sync='subTabTable.pageNo'
                    @current-change='subTabTable.pageChange'
                    size="small" layout='total, sizes, prev, pager, next, jumper'
                    :total='subTabTable.pageTotal'/>
            </div>
        </div>
        <!-- 添加分公司（非初次新增合同） -->
        <advance-dialog
            ref="advanceDialog"
            @confirm="companySaveSure">
        </advance-dialog>
        <!-- 批量添加分公司（新增合同时）或服务平台（编辑合同时） -->
        <advance-multi-dialog
            ref="advanceMultiDialog"
            @confirm="detaliSearchSure"
            @getCheckedList="detailCheckedList">
        </advance-multi-dialog>
        <!-- 新增商机-弹框（新增 / 编辑） -->
        <opportunityList
            v-if="opportunityListVisible"
            :detailData="detailData"
            :detailPageState="detailPageState"
            @handleClose="updateOpportunityListVisible"
            @confirm="(data) => addOpportunityConfirm(data, 'normal')"></opportunityList>
        <!-- 特权新增商机-弹框 -->
        <opportunityPrivilege
            v-if="opportunityPrivilegeVisible"
            :detailData="detailData"
            :detailPageState="detailPageState"
            @handleClose="updateOpportunityPrivilegeVisible"
            @confirm="(data) => addOpportunityConfirm(data, 'privilege')"></opportunityPrivilege>
    </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import editTableBox from '@/components/lots/editTableBox/Index';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import otpAdvanceMulti from '@/components/lots/otpAdvanceMulti';
import advanceDialog from '@/components/lots/otpAdvanceDialog';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import advanceMultiDialog from '@/components/lots/otpAdvanceMultiDialog';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { Message, MessageBox } from 'element-ui';
import { STATE_OTHER_CHANGE, STATE_NEW, STATE_MAIN } from '@/modules/mdm/constant/contractManage.js';
import {
    companyList, companyDelete, companyAddBatch, otherChangeCompanyList, otherChangeCompanyDelete,
    otherChangeCompanyAddBatch, siteInfoSave, siteChangeSave, getCdTsCustomerProjectsApi, companyUpdateBatch,
    privilegeAddBatch, privilegeRemove, siteUpdateBatch, changeSiteUpdateBatch, siteInfoSavePrivilege, getSiteInfoCompanyAll,
    getChangeSiteInfoCompanyAll, getSiteBatch
} from '@mdm/api/contractManage/contractFootPageApi.js';
import opportunityList from './opportunityList/Index.vue';
import opportunityPrivilege from './opportunityListPrivilege/Index.vue';
import _ from 'lodash';
import store from '@/store/index';
import lotsMdInput from '@/components/lots/lotsMdInput';
import otherUtils from '@/modules/mdm/utils/otherCommonFunc.js';
const companyApi = '/contract/company/base/page';
const changeCompanyApi = '/contract/company/change/base/page';
export default {
    name: '',
    components: { lotsPagination, editTableBox, tableBox, otpAdvanceMulti, advanceMultiDialog, advanceDialog, opportunityList, lotsButton, lotsSelect, opportunityPrivilege, lotsMdInput },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const btnPermissionsArr = store.getters.btnPermissions;
        const config = reactive(_.cloneDeep(tableConfig));
        let listApi = reactive({});
        let deleteApi = reactive({});
        const isDisableBtn = ref(false);
        const actions = reactive({
            fixedWidth: 250,
            list: [
                {
                    label: '维护服务平台',
                    event: 'addSite',
                    displayRule: (row) => {
                        return !!row.id && !isDisableBtn.value && btnPermissionsArr.includes(authorityCode.subAddSiteCompany) && !props.readonly;
                    }
                },
                {
                    label: '特权添加服务平台',
                    event: 'addSitePrivilege',
                    displayRule: (row) => {
                        return !!row.id && btnPermissionsArr.includes(authorityCode.subAddSiteCompanyPrivilege)
                            && props.detailPageState !== STATE_OTHER_CHANGE;
                    }
                }
            ]
        });
        if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
            listApi = otherChangeCompanyList;
            deleteApi = otherChangeCompanyDelete;
        } else {
            listApi = companyList;
            deleteApi = companyDelete;
        }
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.detailData.contractCode === '0000' ? '' : props.detailData.contractCode;
                if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
                    condition.contractChangeCode = props.detailData.contractChangeCode;
                }
                return condition;
            };
            afterGetList(res) { // 展示的表格呈编辑状态
                if (+res.code === 0) {
                    res.data.list.forEach((e, i) => {
                        e.edit = -3;
                    });
                    this.totalData.value = res.data.list.map(item => {
                        return {
                            ...item,
                            opportunityCode: item.opportunityCode || '',
                            opportunityName: item.opportunityName || '',
                            isSelected: undefined
                        };
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
                // 变更弹，主合同显示
                if (props.detailPageState === STATE_OTHER_CHANGE || props.detailPageState === STATE_MAIN) {
                    getSiteBatchBtn();
                }
            };
        };
        const subTabTable = new DataDetailClass({
            Api: {
                listApi,
                deleteApi
            }
        });
        const opportunityPrivilegeVisible = ref(false); // LCRM商机弹框
        const updateOpportunityPrivilegeVisible = (val) => {
            opportunityPrivilegeVisible.value = val;
        };
        const opportunityAdvance = computed(() => {
            function getAdvanceConfig(row) {
                const beforeRequest = (params) => {
                    params.companyCode = row.companyCode;
                    return params;
                };
                return { ...config.opportunityAdvance, beforeRequest };
            }
            return getAdvanceConfig;
        });
        const handleRowClick = (row) => {
            // setupContext.refs.tableBoxRead.$refs.elTable.toggleRowSelection(row);
            subTabTable.totalData.value.forEach(item => {
                if (item.id === row.id) {
                    item.isSelected = true;
                } else {
                    item.isSelected = undefined;
                }
            });
        };
        const editTableKey = ref(true);
        const btnLoading = ref(false);
        const handleType = ref(''); // 多选弹框打开变量：company打开分公司 site打开服务平台
        const handleAddRow = async (type) => {
            if (type === 'company') {
                companyAddRow();
            } else if (type === 'opportunity') {
                // 商机列表弹框
                updateOpportunityListVisible(true);
            } else if (type === 'opportunityPrivilege') {
                // LCRM商机列表弹框
                updateOpportunityPrivilegeVisible(true);
            }
        };
        const companyAddRow = async () => {
            const { params, apiUrl } = getCompanyParams({}, companyApi, changeCompanyApi);
            config.companyAdvanceParams.advanceUrl = apiUrl;
            setupContext.refs.advanceMultiDialog.show(true, config.companyAdvanceParams, params, allCompanyList.value);
            handleType.value = 'company';
        };
        // initParams 初始参数，initUrl 初始url changeUrl 变更单url
        const getCompanyParams = (initParams = {}, initUrl = '', changeUrl = '') => {
            const params = { ...initParams, contractCode: props.detailData.contractCode };
            let apiUrl = initUrl;
            if (props.detailData.contractChangeCode) {
                apiUrl = changeUrl;
                params.contractChangeCode = props.detailData.contractChangeCode;
            }
            return {
                params,
                apiUrl
            };
        };
        const allCompanyList = ref([]);
        const selectedRows = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selectedRows.value = selection;
        };
        const handleChangeRow = ({ prop, val, type, row, index }) => {
            if (type === 'advance' && props.detailPageState === STATE_NEW) {
                if (val['companyCode']) {
                    row.disableProps = [];
                    row.site = [];
                    // 为了解决改变分公司，清空服务平台数据后，点击服务平台弹框，弹框选中数据依旧保留原来选中的数据的bug(暂时找不到更好的办法)
                    row.disableProps = ['site'];
                    setTimeout(() => {
                        row.disableProps = [];
                    }, 100);
                } else {
                    row.disableProps = ['site'];
                    row.site = [];
                }
            }
        };
        const getAdvanceValue = _.debounce((data, row, item) => {
            if (item.prop === 'site') {
                row[item.prop] = data;
                allSiteList.value = allSiteList.value.filter(it => it.companyCode !== row.companyCode);
                const selectList = data.map(it => {
                    return {
                        companyCode: row.companyCode,
                        companyNameCn: row.companyName,
                        siteCode: it.siteCode,
                        siteNameCn: it.siteName
                    };
                });
                allSiteList.value = otherUtils.distinctData(allSiteList.value.concat(selectList), 'siteCode');
            } else if (item.prop === 'opportunityCode') {
                Object.keys(data).forEach(key => {
                    row[key] = data[key];
                });
                companySaveSure([row], 'privilege'); // 特权编辑商机数据保存
            }
        }, 100);
        const privilegeDeleteLoading = ref(false);
        const handleDeleteRows = async (type) => { // 调批量删除接口
            if (selectedRows.value.length === 0) {
                Message.warning('请选择需要删除的条目');
                return;
            }
            let passFlag = true;
            selectedRows.value.forEach(item => {
                if (!item.id) {
                    passFlag = false;
                }
            });
            if (!passFlag) return Message.warning('存在新增未保存的数据，请先保存或取消编辑');
            const ids = JSON.parse(JSON.stringify(selectedRows.value)).map((v) => v.id);
            const params = { ids };
            if (props.detailPageState === STATE_OTHER_CHANGE) {
                params.contractChangeCode = props.detailData.contractChangeCode;
            } else {
                params.contractCode = props.detailData.contractCode;
            }
            if (type && type === 'privilege') { // 特权删除
                MessageBox.confirm(`请确认是否删除勾选的${selectedRows.value.length}条数据？`, '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    privilegeDeleteLoading.value = true;
                    privilegeRemove(params).then(res => {
                        if (+res.code === 0) {
                            Message.success('删除成功');
                            subTabTable.getList();
                        }
                    }).finally(() => {
                        privilegeDeleteLoading.value = false;
                    });
                });
            } else { // 普通删除
                subTabTable.deleteRow(params, false);
            }
        };
        const handleSyncData = () => {
            if (!subTabTable.totalData.value.length) {
                return Message.warning('分公司信息为空，请添加分公司、服务平台数据');
            }
            MessageBox.confirm('此操作将更新该合同组下所有合同明细分公司、服务平台数据，确认继续吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                companyUpdateBatch({
                    contractCode: props.detailData.contractCode,
                    contractGroupCode: props.detailData.contractGroupCode
                }).then(res => {
                    if (+res.code === 0) {
                        Message.success('更新成功');
                        subTabTable.getList();
                    }
                });
            });
        };
        watch(
            () => [props.detailPageState, props.detailData.contractStatus, props.detailData.contractChangeStatus, props.activeName, props.detailData.contractCode, props.detailData.businessType],
            // eslint-disable-next-line complexity
            async (newVal) => {
                if (newVal[3] === 'companyDetail') {
                    config.columns.forEach((item) => {
                        if (item.prop === 'site') {
                            item.mustFill = newVal[0] === STATE_NEW;
                            item.disabled = newVal[0] !== STATE_NEW;
                        } else if (item.prop === 'isPureWarehouse') {
                            item.mustFill = Boolean(newVal[5] && newVal[5].length && newVal[5].some(item => (item === '1' || item === '2')));
                            item.disabled = props.detailData.oilIsGroupCustomer + '' === '0';
                        }
                    });
                    setTimeout(() => {
                        editTableKey.value = !editTableKey.value;
                    }, 1000);
                    if (newVal[0] === STATE_NEW) {
                        isDisableBtn.value = false;
                    } else if (newVal[0] === STATE_OTHER_CHANGE) {
                        isDisableBtn.value = newVal[2] + '' === 'undefined' || newVal[2] + '' !== '1' || ([1, 2].includes(+props.detailData.changeType)); // 价格信息、日期信息变更只读
                    } else {
                        isDisableBtn.value = !(newVal[1] + '' === '0' || newVal[1] + '' === '1');
                    }
                    if (newVal[4]) {
                        if (((newVal[0] !== STATE_NEW) && (newVal[0] !== STATE_OTHER_CHANGE)) || (newVal[0] === STATE_OTHER_CHANGE && props.detailData.contractChangeCode)) {
                            await subTabTable.getList();
                            if (props.detailData.projectCode) {
                                getCdTsCustomerProjectsApi({ projectCode: props.detailData.projectCode }).then(res => {
                                    if (+res.code === 0 && res.data.list && res.data.list.length) {
                                        projectData.value = res.data.list[0];
                                    }
                                });
                            }
                        }
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        const siteDetailData = ref({});
        const actionClickIndex = ref(null); // 点击的行索引值
        const privilege = ref(false);
        const actionClick = (event, row, index) => {
            actionClickIndex.value = index;
            if (['addSite', 'addSitePrivilege'].includes(event)) {
                if (row.id) { // 有id则允许添加
                    privilege.value = event === 'addSitePrivilege';
                    const ininParams = { companyCode: row.companyCode, contractCode: props.detailData.contractCode };
                    const { params } = getCompanyParams(ininParams);
                    const siteALlList = (row.site || row.siteList || []).map(it => {
                        it.companyCode = row.companyCode;
                        it.siteNameCn = it.siteName;
                        it.companyName = row.companyName;
                        it.contractCode = row.contractCode;
                        return it;
                    });
                    setupContext.refs.advanceMultiDialog.show(true, config.siteAdvanceParams, params, siteALlList);
                    handleType.value = 'site';
                } else {
                    Message.warning('分公司未保存');
                }
            }
        };
        const editTableTotalData = ref([]);
        const siteList = ['siteBatch', 'site', 'newSiteBatch', 'company'];
        // eslint-disable-next-line complexity
        const detaliSearchSure = async (data) => {
            // if (!data.length && (handleType.value !== 'siteBatch' || )) return;
            if (!data.length && (siteList.indexOf(handleType.value) < 0)) return;
            if (handleType.value === 'site') { // 主合同状态，批量添加服务平台弹框确认
                const params = {
                    companyCode: subTabTable.totalData.value[actionClickIndex.value].companyCode,
                    contractCode: props.detailData.contractCode,
                    contractChangeCode: props.detailData.contractChangeCode ? props.detailData.contractChangeCode : undefined,
                    siteList: setCurrentSiteList(data, props.detailData.contractCode)
                };
                let res = '';
                if (props.detailPageState === STATE_OTHER_CHANGE) { // 其他变更添加
                    params.contractCompanyId = subTabTable.totalData.value[actionClickIndex.value].id;
                    res = await siteChangeSave(params);
                } else {
                    params.contractCompanyId = subTabTable.totalData.value[actionClickIndex.value].id;
                    if (privilege.value) { // 特权添加
                        res = await siteInfoSavePrivilege(params);
                    } else { // 普通添加
                        res = await siteInfoSave(params);
                    }
                }
                if (res.code === '0') {
                    Message.success('保存成功');
                    subTabTable.getList();
                }
            } else if (handleType.value === 'siteBatch') {
                const initParams = {
                    contractCode: props.detailData.contractCode,
                    siteList: setCurrentSiteList(data)
                };
                const { params, apiUrl } = getCompanyParams(initParams, siteUpdateBatch, changeSiteUpdateBatch);
                const res = await apiUrl(params);
                if (res.code === '0') {
                    Message.success('保存成功');
                    subTabTable.getList();
                }
            } else if (handleType.value === 'company') {
                if (props.detailPageState === STATE_NEW) { // 新增状态，批量添加分公司弹框确认
                    data.map(item => {
                        item.allowEdit = true;
                        item.edit = 2;
                        item.disableProps = [];
                        // 合同“内外部业务”为内部时，合同分公司明细的“是否纯仓”赋默认值 N否，外部时不设置
                        item.isPureWarehouse = props.detailData.oilIsGroupCustomer + '' === '0' ? 'N' : null;
                        props.detailData.oilIsGroupCustomer + '' === '0' && item.disableProps.push('isPureWarehouse');
                        const currentData = editTableTotalData.value.find(it => item.companyCode === it.companyCode);
                        if (currentData) {
                            item.site = _.cloneDeep(currentData.site);
                        }
                        return item;
                    });
                    editTableTotalData.value = data;
                    editTableTotalData.value = _.uniqBy(editTableTotalData.value, 'companyCode');
                    // 获取当前重复数据，列表中已存在的数据
                    const siteList = _.filter(editTableTotalData.value, (it) => {
                        return allSiteList.value.some(val => val.companyCode === it.companyCode);
                    });
                    // 获取当前列表中已存在的服务平台数据
                    const siteFilter = _.flattenDeep(siteList.map(it => {
                        return it.site;
                    }));
                    // 获取allSiteList 和 siteFilter 交集数据，并获取allSiteList重复数据的值
                    allSiteList.value = _.intersectionBy(allSiteList.value, siteFilter, 'siteCode');
                    // editTableKey.value = !editTableKey.value;
                    setTimeout(() => {
                        editTableKey.value = !editTableKey.value;
                    }, 1000);
                    allCompanyList.value = editTableTotalData.value;
                } else { // 编辑合同,批量添加分公司确认
                    companySaveSure(data);
                }
            } else if (handleType.value === 'newSiteBatch') {
                setcompanyListForSiteList(data);
            }
            handleType.value = '';
        };
        // 设置当前已选择的数据
        const setCurrentSiteList = (data, contractCode) => {
            return data.map(it => {
                const item = {
                    companyCode: it.companyCode,
                    companyName: it.companyName || it.companyNameCn,
                    siteCode: it.siteCode,
                    siteName: it.siteName || it.siteNameCn,
                    contractCode
                } || [];
                const currentItem = allSiteList.value.find(ele => ele.siteCode === it.siteCode);
                if (currentItem) {
                    item.id = currentItem.id;
                }
                return item;
            });
        };
        // 新增合同-批量添加服务平台
        const setcompanyListForSiteList = (data) => {
            editTableTotalData.value = editTableTotalData.value.map(item => {
                item.site = [];
                item.site = [...item.site, ...data.filter(it => it.companyCode === item.companyCode)];
                item.site = otherUtils.distinctData(item.site, 'siteCode');
                item.site = item.site.map(it => {
                    return {
                        siteCode: it.siteCode,
                        siteName: it.siteName || it.siteNameCn
                    };
                });
                return item;
            });
            if (!props.detailData.contractCode) {
                allSiteList.value = data;
            };
        };
        const detailCheckedList = () => {};
        const companySaveSure = async (data, type) => {
            let params = null;
            if (Array.isArray(data)) {
                params = data.map((item) => {
                    return {
                        ...item,
                        contractCode: props.detailData.contractCode,
                        contractChangeCode: props.detailData.contractChangeCode ? props.detailData.contractChangeCode : null,
                        // 合同“内外部业务”为内部时，合同分公司明细的“是否纯仓”赋默认值 N否，外部时不设置
                        isPureWarehouse: item.isPureWarehouse || (props.detailData.oilIsGroupCustomer + '' === '0' ? 'N' : null)
                    };
                });
            }
            if (!checkIsWarehouse(params)) {
                return false;
            }
            let res = '';
            if (type && type === 'privilege') { // 特权新增
                res = await privilegeAddBatch(params);
            } else if (props.detailPageState === STATE_OTHER_CHANGE) {
                res = await otherChangeCompanyAddBatch(params);
            } else {
                res = await companyAddBatch(params);
            }

            if (res && +res.code === 0) {
                Message.success('保存成功');
                subTabTable.getList();
            }
        };
        const opportunityListVisible = ref(false); // 商机弹框
        const updateOpportunityListVisible = (val) => {
            opportunityListVisible.value = val;
        };
        const addOpportunityConfirm = (data = {}, type) => {
            updateOpportunityListVisible(false);
            updateOpportunityPrivilegeVisible(false);
            if (props.detailPageState === STATE_NEW) { // 新增合同 (批量)
                pushDataFn(data.opportunityList);
            } else { // 编辑合同 （单条）
                // 调分公司保存接口
                companySaveSure(data.opportunityList, type);
            }
        };
        const pushDataFn = (data) => {
            const list = _.cloneDeep(data);
            if (list && list.length) {
                list.forEach((item) => {
                    item.allowEdit = true;
                    item.edit = 2;
                    // 合同“内外部业务”为内部时，合同分公司明细的“是否纯仓”赋默认值 N否，外部时不设置
                    item.isPureWarehouse = item.isPureWarehouse || (props.detailData.oilIsGroupCustomer + '' === '0' ? 'N' : null);
                    item.disableProps = ['companyName', 'warehouseType']; // 禁用分公司编辑
                });
                editTableTotalData.value.push(...list);
                setTimeout(() => {
                    editTableKey.value = !editTableKey.value;
                }, 1000);
            }
        };
        const projectData = ref({});
        const changeIsWarehouse = (row, data) => {
            companySaveSure([row]);
        };
        // 业务类别包含：“仓储/装卸”时，分公司明细的“是否纯仓”不能为空（校验）
        const checkIsWarehouse = (rows) => {
            const { businessType } = props.detailData;
            if (props.detailData.oilIsGroupCustomer + '' === '0' // 内部业务才校验（外部暂时不校验）
                && businessType && businessType.length && businessType.some(item => (item === '1' || item === '2'))
                && rows.some(item => !item.isPureWarehouse)) {
                Message.warning('业务类别包含：“仓储/装卸”时，分公司明细的“是否纯仓”不能为空');
                return false;
            } else {
                return true;
            }
        };
        const allSiteList = ref([]);
        const getAllSitList = () => {
            if (!props.detailData.contractCode) return;
            const { params, apiUrl } = getCompanyParams({}, getSiteInfoCompanyAll, getChangeSiteInfoCompanyAll);
            return apiUrl(params).then((res) => {
                if (res && +res.code === 0) {
                    allSiteList.value = res.data.map(it => {
                        it.siteNameCn = it.siteName;
                        return it;
                    });
                }
            }).catch(() => {
                allSiteList.value = [];
            });
        };
        const handleAddSiteBatch = async () => {
            await getAllSitList();
            if (props.detailData.contractCode) {
                const { params } = getCompanyParams({});
                setupContext.refs.advanceMultiDialog.show(true, config.siteAdvancePrivilegeParams, params, allSiteList.value);
                handleType.value = 'siteBatch';
            } else {
                const companyList = editTableTotalData.value?.map(item => item.companyCode) || [];
                setupContext.refs.advanceMultiDialog.show(true, config.siteAdvancePrivilegeParams, { companyCodeList: companyList }, allSiteList.value);
                handleType.value = 'newSiteBatch'; // 新增合同-批量添加服务平台
            }
        };
        const hiddenSiteBatch = ref(false);
        const getSiteBatchBtn = () => {
            if (!props.detailData.contractCode || props.readonly) {
                return true;
            }
            getSiteBatch({ contractCode: props.detailData.contractCode }).then((res) => {
                if (res && +res.code === 0) {
                    hiddenSiteBatch.value = res.data; // true：重复，既不显示按钮
                }
            }).catch(() => {

            });
        };
        onMounted(() => {
            pushDataFn(props.detailData.opportunityList); // 预填充商机（分公司）信息
        });
        return {
            config,
            actions,
            subTabTable,
            btnLoading,
            handleAddRow,
            handleSelectedRow,
            handleChangeRow,
            handleDeleteRows,
            getAdvanceValue,
            STATE_NEW,
            STATE_OTHER_CHANGE,
            siteDetailData,
            actionClick,
            companySaveSure,
            detaliSearchSure,
            detailCheckedList,
            isDisableBtn,
            editTableTotalData,
            authorityCode,
            opportunityListVisible,
            updateOpportunityListVisible,
            opportunityPrivilegeVisible,
            updateOpportunityPrivilegeVisible,
            addOpportunityConfirm,
            projectData,
            btnPermissionsArr,
            handleSyncData,
            changeIsWarehouse,
            editTableKey,
            opportunityAdvance,
            handleRowClick,
            privilegeDeleteLoading,
            handleAddSiteBatch,
            privilege,
            hiddenSiteBatch
        };
    }
};
</script>
<style lang="less">
    .company-detail-table {
        .el-button+.el-button {
            margin-left: 0;
        }
        .el-button {
            margin-right: 10px;
        }
    }
</style>
