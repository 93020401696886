export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '业务类型',
            prop: 'businessType',
            type: 'select',
            optionsKey: 'SYS_BMS_BUSI_TYPE',
            mustFill: true,
            width: 85
        },
        {
            label: '费用名称',
            prop: 'costTypeName',
            // mustFill: true,
            type: 'advance',
            width: '100px',
            name: '费用名称',
            value: 'costTypeName',
            advanceLabel: '费用弹出框',
            advanceConfig: [
                { prop: 'nameCn', value: '费用名称' }
            ],
            tableConfig: [
                { prop: 'code', value: '费用编码' },
                { prop: 'nameCn', value: '费用名称' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/efFeeHeads`,
            // advanceCascade: 'queryType',
            advanceCascade: 'businessType#businessTypeCode',
            cbParams: ['code#costTypeCode', 'nameCn#costTypeName']
        },
        {
            label: '税率',
            prop: 'rate',
            type: 'advance',
            width: '100px',
            mustFill: true,
            value: 'rate',
            name: '税率',
            advanceLabel: '税率弹出框',
            advanceConfig: [
                { prop: 'percentageRateStr', value: '税率名称' }
            ],
            tableConfig: [
                { prop: 'taxRateCode', value: '税率编码' },
                { prop: 'percentageRate', value: '税率名称' }
            ],
            'prefix': '/api-mdm',
            advanceUrl: `/efTaxRate/popTaxRate/out`,
            cbParams: ['taxRateCode', 'percentageRate#rate']
        },
        {
            label: '创建人',
            prop: 'createUserName'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        },
        {
            label: '修改人',
            prop: 'updateUserName'
        },
        {
            label: '修改时间',
            prop: 'updateTime'
        }
    ]
};
