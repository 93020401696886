import configurl from '@/config/user.env';
export const tableConfig = {
    name: 'priceDetail',
    selection: false,
    height: 600,
    columns: [],
    importConfig: {
        action: configurl.apiUrl + '/api-lcp-order/aging/config/upload',
        multiple: false,
        accept: 'xlsx/text',
        showFileList: false,
        fileType: '只能上传Excel文件',
        manualUpload: true
    }
};

export const searchBoxConfig = {
    name: 'priceDetailSubTabSearch',
    searchFields: [
        {
            name: '变更来源',
            value: 'originCode',
            type: 'input',
            isFixed: true,
            span: 8
        },
        {
            name: '明细状态',
            value: 'status',
            type: 'select',
            optionNum: 'SYS_CRM_CONTRACT_PRICE_STATUS',
            isFixed: true,
            span: 7
        }
    ]
};
