import request from '@/utils/http';
import config from '@/config/user.env';
const apiHost = 'api-lcrm';

// 价格维护 ===> 价目表新增
export const addPriceApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/header/add`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格维护 ===> 价目表特权新增
export const addPrivilegePriceApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/header/privilege/add`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格维护 ===> 价目表特权启用
export const priceEnableApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/header/enable`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 价格维护 ===> 价目表主页面查询
export const getPricePageApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/header/page`,
        method: 'get',
        params
    }).then(res => res);
};

// 价格维护 ===> 价目明细表导出
export const priceDetailexport = (params) => {
    return request({
        url: `${apiHost}/contract/price/detail/export`,
        method: 'get',
        params
    }).then(res => res);
};

// 价格维护 ===> 价目明细表清空明细
export const priceClear = (params) => {
    return request({
        url: `/${apiHost}/contract/price/detail/remove`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格维护 ===> 价目明细表删除价目
export const priceDel = (params) => {
    return request({
        url: `/${apiHost}/contract/price/detail/remove/all`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格维护 ===> 价目明细表清空明细
export const pricePrivilegeDel = (params) => {
    return request({
        url: `/${apiHost}/contract/price/detail/privilege/remove/all`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格维护 ===> 价目明细表导入（增量/覆盖导入）
export const priceImport = (data) => {
    return request({
        url: `/${apiHost}/contract/price/detail/import${data}`,
        method: 'post'
    }).then(res => res);
};

// 价格维护 ===> 价目明细表特权导入（增量/覆盖导入）
export const pricePrivilegeImport = (params, data) => {
    return request({
        url: `/${apiHost}/contract/price/detail/privilege/import${data}`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格维护 ===> 计费模板查询
export const priceTemplate = (params) => {
    return request({
        url: `/${apiHost}/contract/price/column/template`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 查询单据类型
export const getOrderType = (params) => {
    return request({
        url: config.env === 'SIT' ? `https://anapi-uat.annto.com/api-bcp-web/order/type/page` : `api-bcp-web/order/type/page`,
        method: 'get',
        params
    }).then(res => res);
};
// 价格维护 ===> 产品中台新增
export const prefillAddApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/header/prefill/add`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 价格维护 ===> 查询价格明细表头
export const priceColumnListApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/column/list`,
        method: 'get',
        params
    }).then(res => res);
};
// 价格维护 ===> 查询价格明细
export const priceDetailPageApi = (data) => {
    return request({
        url: `/${apiHost}/contract/price/detail/page`,
        method: 'post',
        data
    }).then(res => res);
};
// 价格维护 ===> 推送合同价格
export const priceRatePushApi = (params) => {
    return request({
        url: `/${apiHost}/contract/price/rate/push`,
        method: 'post',
        data: params
    }).then(res => res);
};

// 价格明细导入模板下载
export const priceColumnDownloadTemplateApi = (params = {}) => {
    return request({
        url: `/${apiHost}/contract/price/column/download/template`,
        method: 'post',
        data: params
    });
};
// 价格维护 ===> 待复制合同
export const getPriceTemplateApi = (data) => {
    return request({
        url: `/${apiHost}/contract/info/copy/page`,
        method: 'post',
        data
    }).then(res => res);
};
// 价格维护 ===> 计费模版
export const getCopyPriceTemplateApi = (data) => {
    return request({
        url: `/${apiHost}/contract/price/header/copy`,
        method: 'post',
        data
    }).then(res => res);
};
// 价格维护 ===> 复制计费模版
export const saveCopyPriceTemplateApi = (data) => {
    return request({
        url: `/${apiHost}/contract/price/header/copy/add`,
        method: 'post',
        data
    }).then(res => res);
};
// 价格维护 ===> 检查价格表是否为空
export const getCheckPriceTemplate = (data) => {
    return request({
        url: `/${apiHost}/contract/price/header/copy/verify`,
        method: 'post',
        data
    }).then(res => res);
};
// 价格维护 ===> 检查价格表是否锁住
export const priceImportLock = (params) => {
    return request({
        url: `/${apiHost}/contract/price/detail/import/lock`,
        method: 'get',
        params
    }).then(res => res);
};
