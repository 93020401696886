<template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button
                v-has="authorityCode.subAddNewPrescreen"
                type="text"
                icon="el-icon-circle-plus-outline"
                size="medium"
                @click="handleAddRow"
                :disabled="isDisableBtn"
                >新增</lots-button
            >
            <lots-button
                v-has="authorityCode.subSavePrescreen"
                type="text"
                icon="el-icon-circle-check"
                size="medium"
                @click="handleSaveRows"
                :disabled="isDisableBtn"
                :loading="saveLoading"
                >保存</lots-button
            >
            <lots-button
                v-has="authorityCode.subDeletePrescreen"
                type="text"
                icon="el-icon-delete"
                size="medium"
                @click="handleSaveRows('del')"
                :disabled="isDisableBtn"
                >删除</lots-button
            >
        </div>
        <div class="file-detail-table">
            <edit-table-box
                class="prescreen"
                v-loading="subTabTable.tableLoading"
                :control="true"
                ref="editTableBox"
                :selection="config.selection"
                :columns="config.columns"
                :height="tableHeight"
                :tableData="subTabTable.totalData"
                :actions="config.actions"
                :editDisableRule="() => isDisableBtn"
                @selection-change="handleSelectedRow"
                @element-change="switchStatus">
                <template #edit-col-append="{ item, row }">
                    <el-select
                        v-model="row[item.prop]"
                        filterable
                        remote
                        clearable
                        reserve-keyword
                        placeholder="请输入编码查询"
                        @focus="remoteMethod"
                        :remote-method="remoteMethod"
                        :loading="remoteLoading">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </edit-table-box>
            <div class="footer">
                <lots-pagination
                    @size-change="subTabTable.sizeChange"
                    :current-page.sync="subTabTable.pageNo"
                    @current-change="subTabTable.pageChange"
                    size="small"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="subTabTable.pageTotal" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch, onActivated } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import {
    preContractList,
    preContractDelete,
    preContractSave,
    otherChangePreContractList,
    otherChangePreContractDelete,
    otherChangePreContractSave
} from '@mdm/api/contractManage/contractFootPageApi.js';
import { getContractInfoList } from '@mdm/api/contractManage/contractListApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { Message } from 'element-ui';
import { STATE_OTHER_CHANGE, STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import utils from '@/components/utils/common.js';

export default {
    name: '',
    components: {
        // tableBox,
        lotsPagination,
        // lotsSelect,
        // otpAdvance,
        // DialogImportFile,
        lotsButton,
        editTableBox
    },
    props: {
        detailPageState: {
            // 是否跳转变更页传递了个页面状态过来
            type: String,
            default() {
                return '';
            }
        },
        activeName: {
            type: String,
            default: () => ''
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const config = reactive(tableConfig);
        const isDisableBtn = ref(false);
        const saveLoading = ref(false);
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.detailData.contractCode;
                if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
                    condition.contractChangeCode = props.detailData.contractChangeCode || null;
                }
                return condition;
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        v.validTime = utils.formatDate(v.validTime);
                        v.failTime = utils.formatDate(v.failTime);
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {
                listApi:
          props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0
              ? otherChangePreContractList
              : preContractList,
                deleteApi:
          props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0
              ? otherChangePreContractDelete
              : preContractDelete
                // batchDeleteApi: preContractDelete,
            }
        });
        const btnLoading = ref(false);
        const handleAddRow = () => {
            setupContext.refs.editTableBox.addRow();
        };
        const selectedRows = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selectedRows.value = selection.map(v => {
                v.contractCharacter = '4';
                return v;
            });
        };
        // star:预签编码远程搜索逻辑
        // const list = ref([{ label: '测试', value: '101' }]);
        const options = ref([]);
        const remoteLoading = ref(false);
        const remoteMethod = async (query) => {
            options.value = [];
            const params = {
                contractCode: query.isTrusted ? '' : query,
                contractCharacter: '4',
                pageNo: 1,
                pageSize: 10
            };
            Object.keys(params).forEach(v => {
                if (typeof params[v] === 'undefined' || !(params[v])) {
                    delete params[v];
                }
            });
            if (query) {
                // 模拟调取接口
                const res = await getContractInfoList(params);
                let dataArr = [];
                if (res && +res.code === 0) {
                    dataArr = [...res.data.list];
                }
                if (dataArr.length) {
                    options.value = dataArr.map((v, i) => {
                        return {
                            label: v.contractCode,
                            value: v.contractCode
                        };
                    });
                }
            } else {
                options.value = [];
            }
        };
        const switchStatus = () => {};
        // eslint-disable-next-line complexity
        const handleSaveRows = async (type = 'save') => {
            if (type === 'del') {
                if (selectedRows.value.length === 0) {
                    Message.error('请选择需要删除的条目');
                    return;
                }
            } else {
                if (!subTabTable.totalData.value.length) {
                    return Message.warning('目前无数据无法保存！');
                }
                try {
                    subTabTable.totalData.value.forEach(v => {
                        if (setupContext.refs.editTableBox.saveItemData(v) === false) {
                            throw Error();
                        }
                    });
                } catch (err) { return; }
            }
            const ids = JSON.parse(JSON.stringify(selectedRows.value)).map((v) => v.id);
            // 调批量删除接口
            if (type === 'del') {
                await subTabTable.deleteRow({ ids }, false);
                subTabTable.getList(); // 刷新列表
            } else {
                // 调批量保存接口
                subTabTable.totalData.value.forEach((e) => {
                    e.contractCode = props.detailData.contractCode === '0000' ? null : props.detailData.contractCode;
                    e.contractChangeCode = props.detailData.contractChangeCode || null;
                });
                saveLoading.value = true;
                const res
                    = props.detailPageState === STATE_OTHER_CHANGE
                        ? await otherChangePreContractSave(subTabTable.totalData.value)
                        : await preContractSave(subTabTable.totalData.value);
                saveLoading.value = false;
                if (res && +res.code === 0) {
                    Message({
                        message: '成功保存' + res.data + '条数据',
                        type: 'success'
                    });
                    subTabTable.getList(); // 刷新列表
                }
            }
        };
        watch(
            () => [props.detailPageState, props.detailData.contractChangeStatus, props.detailData.contractStatus, props.activeName, props.detailData.contractCode],
            // eslint-disable-next-line complexity
            (newVal) => {
                if (newVal[3] === 'prescreenDetail') {
                    if (newVal[0] === STATE_NEW) {
                        isDisableBtn.value = false;
                    } else if (newVal[0] === STATE_OTHER_CHANGE) {
                        isDisableBtn.value = newVal[1] + '' === 'undefined' || newVal[1] + '' !== '1' || ([1, 2].includes(+props.detailData.changeType)); // 价格信息、日期信息变更只读
                    } else {
                        isDisableBtn.value = !(newVal[2] + '' === '0' || newVal[2] + '' === '1');
                    }
                    if (newVal[4]) {
                        if ((newVal[0] !== STATE_NEW) && (newVal[0] !== STATE_OTHER_CHANGE)) {
                            subTabTable.getList();
                        } else if (newVal[0] === STATE_OTHER_CHANGE && props.detailData.contractChangeCode) {
                            subTabTable.getList();
                        }
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout();
        });
        return {
            isDisableBtn,
            config,
            subTabTable,
            btnLoading,
            handleAddRow,
            handleSelectedRow,
            switchStatus,
            handleSaveRows,
            options,
            remoteLoading,
            remoteMethod,
            saveLoading,
            authorityCode
        };
    }
};
</script>
