<template>
    <div class="price-content">
        <div class="file-detail-subtab price-detail-subtab">
            <div class="file-detail-title" >价格明细： </div>
            <div class="file-detail-btn price-detail-btn" v-if="!readonly">
                <div v-if="!empty">
                    <lots-button
                        v-if="isDetailShow"
                        v-has="authorityCode.importPriceDetail"
                        type="text"
                        icon="el-icon-upload2"
                        size="medium"
                        :loading="importDetailsLoadingPre"
                        :disabled="!ableEdit"
                        @click="importDetails(false)">导入明细
                    </lots-button>
                    <lots-button v-has="authorityCode.exportPriceDetail" type="text" icon="el-icon-download" size="medium" @click="handleExport"
                        :loading="detailExportLoading" :disabled="[STATE_REPLENISH, STATE_STOP].includes(detailPageState)
                            || (detailPageState === STATE_OTHER_CHANGE && +detailData.changeType !== 1)">导出明细</lots-button>
                    <!-- </report-export> -->
                    <lots-button
                        v-if="isDetailShow"
                        v-has="authorityCode.clearPriceDetail"
                        type="text"
                        icon="el-icon-circle-close"
                        size="medium"
                        :loading="clearLoading"
                        @click="closeTab"
                        :disabled="!ableEdit">清空草稿明细
                    </lots-button>
                    <!-- <div class="">价格表标号：{{currentMain.priceCode}}</div> -->
                    <div class="sub-tab-search-box">
                        <div class="price-code">价格表编号：{{currentMain.priceCode}}</div>
                        <search-box
                            ref="searchBox"
                            :search-key="searchConfig.name"
                            :fields="searchConfig.searchFields"
                            @search-change="handleSearch"
                            @elementChange="elementChange"
                        />
                    </div>
                </div>
            </div>
            <div class="file-detail-table">
                <table-box
                    v-loading="subTabTable.tableLoading"
                    :selection="config.selection"
                    :columns="columns"
                    :index="config.index"
                    :height="tableHeight"
                    :rows="subTabTable.totalData"
                    @sort-change="handleSort"
                    :key="rendertable">
                    <template #col-header="score">
                        <span>{{ score.col.label }}</span>
                        <el-popover
                            :visible="visible"
                            ref="popover"
                            placement="bottom"
                            trigger="click"
                            title="筛选条件"
                            :width="200"
                            >
                            <el-input v-if="!['select','advance'].includes(score.col.headerType)" v-model="searchHeaderParams[score.col.prop]" @blur="getheaderParamList()"
                              @keyup.enter.native="getheaderParamList()"  :placeholder="score.col.placeholder || `请输入${ score.col.label }`"  clearable @clear="getheaderParamList()"></el-input>
                            <lots-select
                                v-else-if="score.col.headerType === 'select'"
                                :optionsKey="score.col.params.params.optionsKey||''"
                                :placeholder="score.col.placeholder || `请选择${ score.col.label }`"
                                v-model="searchHeaderParams[score.col.prop]"
                                @change="getheaderParamList()"
                                clearable
                                style="width: 100%" />
                            <lots-md-input
                                v-else-if="score.col.headerType==='advance'"
                                v-model="searchHeaderParams[score.col.prop]"
                                :config="score.col.params.params"
                                :disabled="_.get(score.col, 'disabled', false)"
                                @change="(data) => { getAdvanceValue(data, score.col); }"
                            ></lots-md-input>
                            <i slot="reference" @click.stop="changeVisible"
                            :class="[changeIconActive(searchHeaderParams[score.col.prop], score.col.prop), 'price-icon iconfont icon-ad-search']"></i>
                        </el-popover>
                    </template>
                </table-box>
                <div class="footer">
                    <lots-pagination
                        @size-change="subTabTable.sizeChange"
                        :current-page.sync="subTabTable.pageNo"
                        @current-change="subTabTable.pageChange"
                        size="small"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="subTabTable.pageTotal"
                    />
                </div>
            </div>
        </div>
        <!-- 新增弹窗 -->
        <el-dialog
            @close="closeLog"
            v-loading="importLoading"
            title="附件新增"
            :visible.sync="addDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="470px">
            <el-form
                :model="filesForm"
                label-position="right"
                class="form-dialog"
                label-width="80px"
                ref="filesFormDialog">
                <div class="upload-btn">
                    <lots-button size="mini" type="primary" @click="downLoad(filesForm.priceTemplateUrl)"
                        :loading="downloadLoading">下载模板</lots-button>
                    <lots-upload-button :text="'上传附件'"  :bucket="'annto-lcrm'"
                        :disabled="isOtherChange ? ![1, 5].includes(+detailData.contractChangeStatus) : ![0, 1].includes(+detailData.contractStatus)"
                        @beforeUpload="beforeUpload"
                        @callback="(data) => { getUploadFileUrl(data); }">
                    </lots-upload-button>
                </div>
                <el-form-item label="附件名称" prop="accessoryName">
                    <el-input v-model="filesForm.accessoryName" size="mini"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <lots-button size="mini" @click="closeLog">取 消</lots-button>
            <lots-button v-if="isSpecialContract" size="mini" type="primary" @click="incrementImport(2)"
                :disabled="isOtherChange ? ![1, 5].includes(+detailData.contractChangeStatus) : ![0, 1].includes(+detailData.contractStatus)">变更导入</lots-button>
            </span>
        </el-dialog>
        <div id="closeVisible"></div>
    </div>
</template>

<script>
import { ref, reactive, computed, onMounted, toRefs, getCurrentInstance } from '@vue/composition-api';
import tableBox from '@/components/lots/tableBox/Index';
import lotsButton from '@/components/lots/lotsButton';
import searchBox from '@/components/lots/searchBox';
import lotsPagination from '@/components/lots/lotsPagination/Index';
// import reportExport from '@/components/lots/reportExportPlugin';
import { priceImport, priceColumnDownloadTemplateApi, priceDetailPageApi, priceColumnListApi, priceClear, priceDetailexport } from '@mdm/api/contractManage/priceApi.js';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig, searchBoxConfig } from './config.js';
import { STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE, STATE_MAIN } from '@/modules/mdm/constant/contractManage.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import store from '@/store/index';
import { Message } from 'element-ui';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import lotsMdInput from '@/components/lots/lotsMdInput';
import lotsUploadButton from '@/components/lots/oss/UploadBtn';
import _ from 'lodash';
import { initAllDialogParam } from '../../config.js';
import Vue from 'vue';
export default {
    name: '',
    components: {
        lotsPagination,
        tableBox,
        lotsButton,
        searchBox,
        lotsSelect,
        lotsMdInput,
        lotsUploadButton
        // reportExport
    },
    emits: ['otherChangeDetail'],
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        activeName: {
            type: String,
            default: () => ''
        },
        tableHeight: {
            type: Number,
            default: 600
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const reqHeaders = ref({ tenantCode: store.getters.currentTenant.tenantCode });
        const config = reactive(tableConfig);
        const searchConfig = reactive(searchBoxConfig);
        const rendertable = ref(true);
        const reference = ref(null);
        const state = reactive({
            visible: false,
            searchHeaderParams: {},
            uploadData: {
                fileName: ''
            },
            _
        });
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                const { contractCode, mainContract, contractCharacter } = props.detailData;
                const code = ['3', '5'].includes(String(contractCharacter)) ? mainContract : contractCode;
                condition.contractCode = code;
                condition.orderBy = orderBy.value;
                condition.orderByType = orderByType.value;
                condition.priceCode = currentMain.value.priceCode;
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(item => {
                        return {
                            ...item,
                            status: dictData.value.data['SYS_CRM_CONTRACT_PRICE_STATUS'][item.status]
                        };
                    });
                    this.pageTotal.value = +res.data.totalCount;
                    this.pageSize.value = +res.data.pageSize;
                    this.pageNo.value = +res.data.pageNo;
                }
            };
        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: priceDetailPageApi
            }
        });
        const ableEdit = computed(() => {
            if (currentMain.value && +currentMain.value.changeType === 1) {
                return false;
            } else if ([STATE_REPLENISH, STATE_STOP].includes(props.detailPageState)) {
                return false;
            } else if (isOtherChange.value) {
                return [1, 5].includes(+props.detailData.contractChangeStatus) && +props.detailData.changeType === 1;
            } else {
                return [0, 1].includes(+props.detailData.contractStatus);
            }
        });
        const isDetailShow = computed(() => {
            // 合同详情不展示 主合同、终止协议 补充协议
            if ([STATE_MAIN, STATE_REPLENISH, STATE_STOP].includes(props.detailPageState)) {
                return false;
            }
            return true;
        });
        const dictData = ref({});
        onMounted(async() => {
            dictData.value = await getDictDataApi({ dictCodes: 'SYS_CRM_CONTRACT_PRICE_STATUS' });
        });
        const empty = computed(() => {
            return JSON.stringify(currentMain.value) === '{}' || !currentMain.value;
        });
        const isOtherChange = computed(() => {
            return props.detailPageState === STATE_OTHER_CHANGE;
        });
        const isSpecialContract = computed(() => {
            return [STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE].includes(props.detailPageState);
        });
        const columns = ref([]);
        const currentMain = ref(null);
        const getDataList = async (main = currentMain.value) => {
            columns.value = [];
            state.searchHeaderParams = {};
            if (!main) return;
            await priceColumnListApi({ templateCode: main.templateCode }).then(res => {
                if (+res.code === 0 && res.data && res.data.length) {
                    columns.value = res.data.map((item, index) => {
                        let prop = '';
                        if (item.columnCode === 'etveType') {
                            prop = 'etveName';
                        } else if (item.columnCode === 'itemTypeCode') {
                            prop = 'itemTypeCodeName';
                        } else if (item.columnCode === 'orderType') {
                            prop = 'orderTypeName';
                        } else if (item.columnCode === 'materialGroup') {
                            prop = 'materialGroupName';
                        } else {
                            prop = item.columnCode;
                        }
                        const params = initAllDialogParam[item.columnCode] || {
                            type: 'input'
                        };

                        return {
                            label: item.columnName,
                            prop: prop,
                            headerType: params.type,
                            minWidth: 110,
                            sort: true,
                            params: params
                        };
                    });
                }
            });
            currentMain.value = { ...main };
            const params = {
                priceCode: currentMain.value.priceCode,
                pageSize: 10,
                pageNo: 1
            };
            // setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            subTabTable.getList(params);
        };
        // 上传失败
        const handleError = () => {
            Message.error('上传失败');
        };
        const filesForm = ref({});
        const addDialog = ref(false);
        const fileList = ref([]);
        const importDetailsLoadingPre = ref(false);
        const importDetailsLoadingPreS = ref(false);
        const importDetails = (isPrivilege = false) => {
            if (JSON.stringify(currentMain.value) === '{}' || !currentMain.value) {
                return Message.warning('请选中一条价格表数据导入');
            }
            addDialog.value = true;
        };
        const downloadLoading = ref(false);
        // 下载模板
        const downLoad = (data) => {
            const { contractCode, contractChangeCode } = props.detailData;
            downloadLoading.value = true;
            priceColumnDownloadTemplateApi({
                templateCode: currentMain.value.templateCode,
                originCode: isOtherChange.value ? contractChangeCode : contractCode
            }).then(res => {
                // utils.downloadStream(res, '价格明细导入模板.xlsx');
                if (+res.code === 0) {
                    Message.success('已发送导出请求,请稍后到右上角用户文件信息列表中下载');
                }
            }).finally(() => { downloadLoading.value = false; });
        };
        const closeLog = () => {
            fileList.value = [];
            filesForm.value.accessoryName = '';
            addDialog.value = false;
            state.uploadData = {};
        };
        const onChange = (file) => {
            fileList.value = [];
            filesForm.value.accessoryName = file.name;
            fileList.value.push(file);
        };
        const importLoading = ref(false);
        // 增量/覆盖导入
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const getUploadFileUrl = res => {
            if (+res.code === 0 && res.data) {
                state.uploadData = { ...state.uploadData, ...res.data };
                filesForm.value.accessoryName = res.data?.name || state.uploadData.fileName;
                ctx.$forceUpdate();
            } else {
                state.uploadData = { };
            }
        };
        const beforeUpload = res => {
            state.uploadData['fileName'] = res.file.name;
        };
        // 增量/覆盖导入
        const incrementImport = async (val) => {
            if (!state.uploadData?.downUrl) {
                return Message.warning('请先上传附件');
            }
            const { contractCode, contractChangeCode } = props.detailData;
            const params = {
                flag: val,
                priceCode: currentMain.value.priceCode,
                templateCode: currentMain.value.templateCode,
                jobId: currentMain.value.id,
                contractCode: currentMain.value.contractCode,
                filename: state.uploadData.name,
                fileUrl: state.uploadData.downUrl,
                originCode: isOtherChange.value ? contractChangeCode : contractCode
            };
            // 处理URL后面的参数拼接
            const urlParams = Object.keys(params).reduce((pre, cur) => {
                if (params[cur] || params[cur] === 0) {
                    if (pre.length !== 1) {
                        pre = `${pre}&${cur}=${params[cur]}`;
                    } else {
                        pre = `${pre}${cur}=${params[cur]}`;
                    }
                }
                return pre;
            }, '?');
            // 把接口需要的参数带进去
            let res = null;
            importLoading.value = true;
            res = await priceImport(urlParams).catch(() => { importLoading.value = false; });
            importLoading.value = false;
            if (+res.code === 0) {
                Message.success('数据处理中，异常结果请查询右上角文件信息');
                getDataList(currentMain.value);
                rendertable.value = !rendertable.value; // 解决表格滚动后导入数据成功后，表格表头与内容错位问题
                closeLog();
                setupContext.emit('pollingImportLock');
            } else {
                // 报错清空文件
                fileList.value = [];
                filesForm.value.accessoryName = '';
                state.uploadData = {};
            }
        };
        // 价目明细表导出
        const detailExportLoading = ref(false);
        const handleExport = () => {
            if (JSON.stringify(currentMain.value) === '{}' || !currentMain.value) {
                return Message.warning('请选择一条价格表数据导出');
            }
            const params = {
                priceCode: currentMain.value.priceCode,
                templateCode: currentMain.value.templateCode
            };
            detailExportLoading.value = true;
            priceDetailexport(params).then(res => {
                if (+res.code === 0) {
                    Message.success('已发送导出请求,请稍后到右上角用户文件列表中下载');
                }
            }).finally(() => { detailExportLoading.value = false; });
        };
        const clearLoading = ref(false);
        const closeTab = () => {
            if (JSON.stringify(currentMain.value) === '{}' || !currentMain.value) {
                return Message.warning('请选择一条价格表数据再清空');
            }
            const { contractCode, contractChangeCode } = props.detailData;
            const params = {
                priceCode: currentMain.value.priceCode,
                contractCode: currentMain.value.contractCode,
                originCode: isOtherChange.value ? contractChangeCode : contractCode
            };
            clearLoading.value = true;
            priceClear(params).then(res => {
                if (+res.code === 0) {
                    Message.success('清空成功');
                    getDataList();
                }
            }).finally(() => {
                clearLoading.value = false;
            });
        };
        const clearAll = () => {
            subTabTable.totalData.value = [];
            columns.value = [];
            currentMain.value = null;
        };
        const handleSearch = async ($event) => {
            subTabTable.searchList({ ...$event });
        };
        const elementChange = () => {
            setupContext.refs.searchBox.submitSearch();
        };
        const changeVisible = () => {
            document.getElementById('closeVisible').click(); // doClose失效 ,Visible 绑定无法更新到视图 用于关闭其他el-popover弹窗
        };

        const getAdvanceValue = (data, item) => {
            Vue.set(state.searchHeaderParams, item.prop, data[item.prop]);
            subTabTable.getList(state.searchHeaderParams);
        };
        const getheaderParamList = (type) => {
            subTabTable.getList(state.searchHeaderParams);
        };
        const orderBy = ref('');
        const orderByType = ref('');
        // 排序
        const handleSort = ({ prop, order }) => {
            orderBy.value = prop.replace(/([A-Z])/g, '_$1').toLowerCase();
            orderByType.value = order === 'ascending' ? 'asc' : 'desc';
            subTabTable.getList(state.searchHeaderParams);
        };
        const errlist = [undefined, null, ''];
        const changeIconActive = (row, prop) => {
            return errlist.includes(row) ? '' : 'icon-active';
        };
        return {
            ...toRefs(state),
            reqHeaders,
            config,
            columns,
            authorityCode,
            subTabTable,
            getDataList,
            filesForm,
            addDialog,
            fileList,
            handleError,
            importDetailsLoadingPre,
            importDetailsLoadingPreS,
            importDetails,
            downLoad,
            closeLog,
            onChange,
            importLoading,
            incrementImport,
            getUploadFileUrl,
            beforeUpload,
            handleExport,
            closeTab,
            clearLoading,
            clearAll,
            empty,
            detailExportLoading,
            isOtherChange,
            isSpecialContract,
            searchConfig,
            handleSearch,
            currentMain,
            downloadLoading,
            ableEdit,
            STATE_REPLENISH,
            STATE_STOP,
            STATE_OTHER_CHANGE,
            elementChange,
            rendertable,
            reference,
            changeVisible,
            getAdvanceValue,
            getheaderParamList,
            handleSort,
            changeIconActive,
            isDetailShow
        };
    }
};
</script>
<style lang="less">
@import url('./index.less');
</style>
