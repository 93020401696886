<template>
    <div class="price-detail-subtab">
        <div class="file-detail-subtab" v-if="!readonly">
            <div class="file-detail-btn">
                <lots-button
                    v-if="priceEdit"
                    type="text"
                    size="medium"
                    :loading="priceChangeLoading"
                    @click="handlePrice"
                    >
                    维护价格
                </lots-button>
                <report-export v-if="isDetailShow" v-has="authorityCode.exportPlacePriceMain" #default="{ submit }" reportCode="REPORT_LCP_DC_PLACE">
                    <lots-button type="text" size="medium" @click="handleExport(submit)" :disabled="!ableEdit">导出行政规划</lots-button>
                </report-export>
                <!-- <lots-button
                    v-has="authorityCode.openPriceDetailS"
                    v-if="[2, 5, 7, 8].includes(detailDataLocal.contractStatus)"
                    type="text"
                    size="medium"
                    :loading="priceEnableLoading"
                    @click="priceEnable">
                    特权启用
                </lots-button> -->
                <lots-button
                    v-has="authorityCode.subPushPrice"
                    v-if="detailPageState === 'otherChange' ? detailData.contractChangeCode : detailData.contractCode"
                    type="text"
                    size="medium"
                    :loading="pushLoading"
                    @click="handlePushPrice"
                    :disabled="!ableEdit">
                    推送合同价格
                </lots-button>
                <div v-if="!ableEdit" class="tips">提示：若需维护价格表请到主合同价格表或主合同-其他变更-价格信息维护</div>
            </div>
        </div>
        <div class="price-content-box">
            <!-- 价格表 -->
            <div class="price-content-sub price-main-box">
                <el-tabs v-model="priceActiveName" @tab-click="handleClickTab">
                    <el-tab-pane
                        label="计费模型"
                        name="jiage">
                        <price-mains
                            ref="priceMainRef"
                            :detailData="detailData"
                            :detailPageState="detailPageState"
                            :activeName="priceActiveName"
                            :tableHeight="mainHeight"
                            :readonly="readonly"
                            @priceDetailList="priceDetailList"
                            @handleCurrentMain="handleCurrentMain">
                        </price-mains>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="price-content-sub midle-line"></div>
            <!-- 价格明细： -->
            <div class="price-content-sub price-detail-box">
                <price-details
                    ref="priceDetailRef"
                    :detailData="detailData"
                    :activeName="priceActiveName"
                    :contractCode="detailData.contractCode"
                    :detailPageState="detailPageState"
                    :tableHeight="detailHeight"
                    :readonly="readonly"
                    @pollingImportLock="handlePollingImportLock">
                </price-details>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch, computed } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import priceMains from './mains/Index.vue';
import priceDetails from './details/Index.vue';
import reportExport from '@/components/lots/reportExportPlugin';
import { otherChangeApplyApi } from '@mdm/api/contractManage/detailMainApi.js';
// import { priceEnableApi } from '@mdm/api/contractManage/priceApi.js';
import { STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE, CONTRACT_CHARACTER, CONTRACT_STATUS, STATE_MAIN } from '@/modules/mdm/constant/contractManage.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import _ from 'lodash';
import utils from '@/components/utils/common.js';
// import { Message } from 'element-ui';
import bigDataLog from '@/utils/bigDataLog.js';
import { priceRatePushApi } from '@mdm/api/contractManage/priceApi.js';
import { Message } from 'element-ui';

export default {
    name: '',
    components: {
        lotsButton,
        priceMains,
        priceDetails,
        reportExport
    },
    emits: ['priceDetail'],
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        activeName: {
            type: String,
            default: ''
        },
        mainHeight: { // 价格表格高度
            type: Number,
            default: 600
        },
        detailHeight: { // 价格明细表格高度
            type: Number,
            default: 600
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup (props, setupContext) {
        const emit = setupContext.emit;
        const authorityCode = reactive(btnAuthority.contractManage);
        const detailDataLocal = _.cloneDeep(props.detailData);
        const priceActiveName = ref('jiage');
        const priceChangeLoading = ref(false);
        const currentRowData = ref({});
        const handleClickTab = () => {};
        const ableEdit = computed(() => {
            return props.detailPageState === STATE_OTHER_CHANGE ? +props.detailData.changeType === 1 // 价格变更才可编辑
                : ![STATE_REPLENISH, STATE_STOP].includes(props.detailPageState) && props.detailData.contractCode;
        });
        const priceEdit = computed(() => {
            // 仅合同性质=”新签/续签/预签“
            const contractCharacterStatus = [+CONTRACT_CHARACTER.NEW, +CONTRACT_CHARACTER.RENEW, +CONTRACT_CHARACTER.ADVANCE].includes(props.detailData.contractCharacter);
            // 且合同状态=”草稿/新增/审批中/审批通过/有效/失效“时展示
            const contractStatus = [+CONTRACT_STATUS.DRAFT, +CONTRACT_STATUS.APPEND, +CONTRACT_STATUS.EFFECT, +CONTRACT_STATUS.FAILURE,
                +CONTRACT_STATUS.PASS, +CONTRACT_STATUS.APPROVING].includes(props.detailData.contractStatus);
            return contractCharacterStatus && contractStatus && props.detailPageState !== STATE_OTHER_CHANGE;
        });
        const isDetailShow = computed(() => {
            // 合同详情不展示 主合同、终止协议 补充协议
            if ([STATE_MAIN, STATE_REPLENISH, STATE_STOP].includes(props.detailPageState)) {
                return false;
            }
            return true;
        });
        const handleExport = (submit) => {
            const params = {
                endTime: utils.formatDateTime(new Date()) || '2035-01-01 00:00:00'
            };
            submit(params);
        };
        // 价格表选中回调
        const handleCurrentMain = (current) => {
            if (current && JSON.stringify(current) !== '{}') {
                // 查询价格明细表
                currentRowData.value = current;
                setupContext.refs.priceDetailRef.getDataList(current);
            } else {
                // 清空价格明细
                setupContext.refs.priceDetailRef.clearAll();
                currentRowData.value = {};
            }
        };
        const priceDetailList = () => {
            currentRowData.value?.templateCode && setupContext.refs.priceDetailRef.getDataList(currentRowData.value);
        };
        const handlePollingImportLock = () => {
            setupContext.refs.priceDetailRef && setupContext.refs.priceMainRef.pollingImportLock();
        };
        watch(
            () => [props.activeName, props.detailData.contractCode],
            (newVal) => {
                if (newVal[0] === 'priceDetail') {
                    if (props.detailPageState === STATE_OTHER_CHANGE ? props.detailData.contractChangeCode : newVal[1]) {
                        bigDataLog.sendDetailMessageByKey('bigData-page-detailPage-priceDetail');
                        if (['3', '5'].includes(String(props.detailData.contractCharacter)) ? props.detailData.mainContract : newVal[1]) {
                            setupContext.refs.priceMainRef && setupContext.refs.priceMainRef.getDataList();
                        }
                        // 切换tab需要校验是否在导入价格明细并提示
                        handlePollingImportLock();
                    } else {
                        setupContext.refs.priceMainRef.subTabTable.totalData = [];
                        setupContext.refs.priceMainRef.subTabTable.pageTotal = 0;
                        setupContext.refs.priceDetailRef.clearAll();
                    }
                }
            },
            { immediate: true }
        );
        const pushLoading = ref(false);
        const handlePushPrice = () => {
            const { contractCode, mainContract } = props.detailData;
            const code = ['3', '5'].includes(String(props.detailData.contractCharacter)) ? mainContract : contractCode;
            pushLoading.value = true;
            priceRatePushApi({ contractCode: code }).then(res => {
                if (+res.code === 0) {
                    Message.success('推送成功');
                }
            }).finally(() => { pushLoading.value = false; });
        };
        const handlePrice = async () => {
            priceChangeLoading.value = true;
            const { contractCode } = props.detailData;
            const res = await otherChangeApplyApi({ contractCode, changeType: 1 }); // 校验合同是否可以变更
            priceChangeLoading.value = false;
            if (+res.code === 0) {
                emit('changeApply', 'price');
            }
        };
        return {
            authorityCode,
            priceActiveName,
            handleClickTab,
            detailDataLocal,
            handleExport,
            // priceEnableLoading,
            // priceEnable,
            handleCurrentMain,
            handlePushPrice,
            pushLoading,
            ableEdit,
            priceEdit,
            handlePrice,
            priceChangeLoading,
            isDetailShow,
            handlePollingImportLock,
            priceDetailList
        };
    }
};
</script>
<style lang="less">
.price-detail-subtab {
    .tips {
        line-height: 32px;
        margin: 0 10px;
        color: #888;
        float: right;
    }
    .price-content-box {
        width: 100%;
        display: flex;
        .price-content-sub {
            &.price-main-box {
                width: 40%;
            }
            &.midle-line {
                width: 1px;
                background-color: #ccc;
                margin: 10px 8px;
            }
            &.price-detail-box {
                // flex: 1;
                width: calc(60% - 1px - 16px);
            }
            // &.price-detail-box {
            //     margin-top: 4px;
            // }
        }
    }
}
</style>
