export const tableConfig = {
    selection: true,
    height: 325,
    columnsReadonly: [ // 只读表格配置
        {
            label: '商机号',
            prop: 'opportunityCode',
            type: 'custom',
            width: 150
        },
        {
            label: '商机名称',
            prop: 'opportunityName'
        },
        {
            label: '实际中标规模(万)',
            prop: 'totalScale'
        },
        {
            label: '目前规模(万)',
            prop: 'businessScale'
        },
        {
            label: '签单规模(万)',
            prop: 'cdExpectBiddingSize'
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 180
        },
        {
            label: '服务平台',
            prop: 'siteNames',
            width: 400
        },
        {
            label: '是否纯仓',
            prop: 'isPureWarehouse',
            // type: 'select',
            type: 'custom',
            width: 105,
            optionsKey: 'SYS_CRM_YES_NO'
        },
        {
            label: '仓库类型',
            prop: 'warehouseType',
            type: 'select',
            optionsKey: 'NEOCRM_WH_TYPE',
            width: 100
        }
    ],
    columnsGroupReadonly: [
        {
            label: '分公司',
            prop: 'companyName',
            width: 180
        },
        {
            label: '服务平台',
            prop: 'siteNames'
            // width: 500
        },
        {
            label: '是否纯仓',
            prop: 'isPureWarehouse',
            // type: 'select',
            type: 'custom',
            width: 105,
            optionsKey: 'SYS_CRM_YES_NO'
        }
    ],
    columns: [ // 新增合同时编辑表格配置
        {
            label: '商机号',
            prop: 'opportunityCode',
            width: 130
        },
        {
            label: '商机名称',
            prop: 'opportunityName',
            width: 130
        },
        {
            label: '实际中标规模(万)',
            prop: 'totalScale',
            width: 120
        },
        {
            label: '目前规模(万)',
            prop: 'businessScale',
            width: 100
        },
        {
            label: '签单规模(万)',
            prop: 'cdExpectBiddingSize',
            width: 100
        },
        {
            label: '分公司',
            prop: 'companyName',
            type: 'advance',
            rule: [
                { required: true, message: '分公司不能为空', trigger: 'blur' }
            ],
            name: '添加分公司',
            value: 'companyName',
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称' }
            ],
            highlightCurrentRow: true,
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode#companyCode', 'companyNameCn#companyName'],
            mustFill: true,
            hideTooltip: true,
            width: 200
        },
        {
            label: '服务平台',
            type: 'slot',
            prop: 'site',
            width: 450,
            value: 'site',
            slot: true,
            editSlot: true,
            mustFill: false,
            disabled: false,
            placeholder: '',
            // siteAdvanceParams: {
            name: '服务平台',
            advanceLabel: '维护服务平台',
            advanceConfig: [
                { 'prop': 'siteNameCn', 'value': '服务平台名称' },
                { 'prop': 'siteCode', 'value': '服务平台编码' }
            ],
            tableConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'displayName': 'siteNameCn', 'prop': 'siteNameCn', 'value': '服务平台名称' }
            ],
            prefix: '/api-mdm',
            multiple: true, // 多选
            /* eslint-disable no-magic-numbers */
            pageSizes: [50, 100, 250, 500, 1000],
            advanceUrl: `/esCompany/popSite`,
            cbParams: ['siteCode', 'siteNameCn#siteName'],
            advanceCascade: 'companyCode#companyCode',
            hideTooltip: true
            // }
        },
        {
            label: '是否纯仓',
            prop: 'isPureWarehouse',
            type: 'select',
            mustFill: false,
            optionsKey: 'SYS_CRM_YES_NO',
            width: 105
        },
        {
            label: '仓库类型',
            prop: 'warehouseType',
            type: 'select',
            optionsKey: 'NEOCRM_WH_TYPE',
            width: 100
        }
    ],
    companyAdvanceParams: {
        advanceLabel: '批量添加分公司',
        advanceConfig: [
            { 'prop': 'companyName', 'value': '分公司名称' },
            { 'prop': 'companyCode', 'value': '分公司编码' }
        ],
        tableConfig: [
            { 'prop': 'companyCode', 'value': '分公司编码' },
            { 'displayName': 'companyNameCn', 'prop': 'companyNameCn', 'value': '分公司名称' }
        ],
        // noMinNumLimit: true, // 不限制是否已选数据
        multiple: true, // 多选
        /* eslint-disable no-magic-numbers */
        pageSizes: [50, 100, 250, 500, 1000],
        prefix: '/api-lcrm',
        method: 'post',
        initToggletProp: 'companyCode', // 重新更新toggleProp的当前数据
        advanceUrl: `/contract/company/base/page`,
        cbParams: ['companyCode#companyCode', 'companyNameCn#companyName'],
        hideTooltip: true
    },
    siteAdvanceParams: { // 单条分公司添加服务平台
        advanceLabel: '维护服务平台',
        advanceConfig: [
            { 'prop': 'siteName', 'value': '服务平台名称' },
            { 'prop': 'siteCode', 'value': '服务平台编码' }
        ],
        tableConfig: [
            { 'prop': 'siteCode', 'value': '服务平台编码' },
            { 'displayName': 'siteNameCn', 'prop': 'siteNameCn', 'value': '服务平台名称' }
        ],
        method: 'post',
        prefix: '/api-lcrm',
        multiple: true, // 多选
        noMinNumLimit: true, // 不限制是否已选数据
        /* eslint-disable no-magic-numbers */
        pageSizes: [50, 100, 250, 500, 1000],
        initToggletProp: 'siteCode', // 重新更新toggleProp的当前数据
        advanceUrl: `/querySiteByCompanyCodes`,
        cbParams: ['siteCode', 'siteNameCn#siteName', 'companyCode', 'companyNameCn#companyName'],
        advanceCascade: 'companyCode#companyCode',
        hideTooltip: true
    },
    siteAdvancePrivilegeParams: { // 批量添加服务平台
        advanceLabel: '批量维护服务平台',
        advanceConfig: [
            { 'prop': 'siteName', 'value': '服务平台名称' },
            { 'prop': 'siteCode', 'value': '服务平台编码' },
            { 'prop': 'companyCode', 'value': '分公司编码' },
            { 'prop': 'companyName', 'value': '分公司名称' }
        ],
        tableConfig: [
            { 'prop': 'siteCode', 'value': '服务平台编码' },
            { 'displayName': 'siteNameCn', 'prop': 'siteNameCn', 'value': '服务平台名称' },
            { 'prop': 'companyCode', 'value': '分公司编码' },
            { 'prop': 'companyNameCn', 'value': '分公司名称' }
        ],
        /* eslint-disable no-magic-numbers */
        pageSizes: [50, 100, 250, 500, 1000],
        multiple: true, // 多选
        noMinNumLimit: true,
        initToggletProp: 'siteCode', // 重新更新toggleProp的当前数据
        method: 'post',
        prefix: '/api-lcrm',
        advanceUrl: `/querySiteByCompanyCodes`,
        cbParams: ['siteCode', 'siteNameCn', 'companyCode', 'companyNameCn'],
        advanceCascade: 'companyCode#companyCode',
        hideTooltip: true
    },
    opportunityAdvance: {
        name: '商机',
        value: 'opportunityCode',
        advanceLabel: '选择商机',
        advanceConfig: [
            { prop: 'opportunityCode', value: '商机编码' },
            { prop: 'opportunityName', value: '商机名称' }
        ],
        tableConfig: [
            {
                value: '商机编码',
                prop: 'opportunityCode',
                width: 130
            },
            {
                value: '商机名称',
                prop: 'opportunityName',
                width: 130
            },
            {
                value: '项目编码',
                prop: 'projectCode',
                width: 160
            },
            {
                value: '分公司',
                prop: 'companyName',
                width: 140
            },
            {
                value: '客户名称',
                prop: 'opportunityCustomerName',
                width: 130
            },
            {
                value: '实际中标规模(万)',
                prop: 'totalScale',
                width: 120
            },
            {
                value: '目前规模(万)',
                prop: 'businessScale',
                width: 100
            },
            {
                value: '签单规模(万)',
                prop: 'cdExpectBiddingSize',
                width: 100
            },
            {
                value: '销售阶段',
                prop: 'saleStage',
                type: 'select',
                optionsKey: 'NEOCRM_SALE_STAGE'
            },
            {
                value: '输单阶段',
                prop: 'lostStage',
                type: 'select',
                optionsKey: 'NEOCRM_SALE_STAGE'
            },
            {
                value: '报签确认',
                prop: 'isSign',
                type: 'select',
                optionsKey: 'NEOCRM_YES_NO'
            },
            {
                value: '中标日期',
                prop: 'winningDate',
                width: 160
            },
            {
                value: '仓库类型',
                prop: 'warehouseType',
                type: 'select',
                optionsKey: 'NEOCRM_WH_TYPE',
                width: 80
            }
        ],
        method: 'post',
        prefix: '/api-lcrm',
        advanceUrl: '/opportunity/info/page',
        cbParams: ['opportunityCode', 'opportunityName'],
        advanceCascade: 'companyCode#companyCode'
    }
};
