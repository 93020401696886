<template>
    <div class="file-detail-subtab">
        <div class="file-detail-table">
            <table-box
                v-loading="subTabTable.tableLoading"
                :selection="config.selection"
                :columns="config.columns"
                :index="config.index"
                :height="tableHeight"
                :rows="subTabTable.totalData"
                :actions="actions"
                @action-click="actionClick"
                @row-dblclick="rowDBclick">
                <template #col-header="score">
                    <span>{{ score.col.label }}</span>
                    <el-popover
                        placement="bottom"
                        title="筛选条件"
                        :min-width="200"
                        >
                            <el-input v-if="score.col.headerType === 'input'" v-model="searchHeaderParams[score.col.propLike]"
                                @blur="getheaderParamList()"  @keyup.enter.native="getheaderParamList()"
                                :placeholder="score.col.placeholder || `请输入${ score.col.label }`"  clearable @clear="getheaderParamList()"></el-input>
                            <lots-select
                                v-else-if="score.col.headerType === 'select'"
                                :optionsKey="score.col.params.params.optionsKey ||'' "
                                :placeholder="score.col.placeholder || `请选择${ score.col.label }`"
                                v-model="searchHeaderParams[score.col.propLike]"
                                @change="getheaderParamList()"
                                clearable
                                style="width: 100%" />
                            <!-- <otpAdvanceMulti
                                v-else-if="score.col.headerType === 'advance'"
                                :params="score.col.params"
                                @getAdvanceValue="(data) => { getMultiInfo(data, score.col); }"
                                :disabled="_.get(score.col, 'disabled', false)"
                                :mValue="searchHeaderParams[score.col.prop]"
                                >
                            </otpAdvanceMulti> -->
                            <lots-md-input
                                v-else-if="score.col.headerType==='advance'"
                                :label="searchHeaderParams[score.col.propLike]"
                                :disabled="_.get(score.col, 'disabled', false)"
                                :config="score.col.params.params"
                                @change="(data) => { getAdvanceValue(data, score.col); }"
                            ></lots-md-input>
                            <el-date-picker
                                v-else-if="score.col.headerType==='date'"
                                v-model="searchHeaderParams[score.col.propLike]"
                                :type="score.col.dateType || 'daterange'"
                                :format="score.col.format"
                                :value-format="score.col.valueFormat"
                                :range-separator="score.col.separator|| '至'"
                                :start-placeholder="score.col.startPlaceholder||'开始日期'"
                                :end-placeholder="score.col.endPlaceholder||'结束日期'"
                                :default-time="score.col.defaultTime || ['00:00:00', '23:59:59']"
                                @change="getheaderParamList()"
                                >
                            </el-date-picker>
                            <i slot="reference" v-if="score.col.headerType"  @click.stop="changeVisible()"
                            :class="[changeIconActive(searchHeaderParams[score.col.propLike]), 'price-icon iconfont icon-ad-search']"></i>
                    </el-popover>
                </template>
            </table-box>
            <div class="footer">
                <lots-pagination
                    @size-change="subTabTable.sizeChange"
                    :current-page.sync="subTabTable.pageNo"
                    @current-change="subTabTable.pageChange"
                    size="small"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="subTabTable.pageTotal"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch, onActivated, toRefs } from '@vue/composition-api';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import {
    contractChangeList,
    contractChangeDelete,
    contractChangeDetailApi
} from '@mdm/api/contractManage/contractFootPageApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import store from '@/store/index';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import lotsMdInput from '@/components/lots/lotsMdInput';
import _ from 'lodash';
export default {
    name: '',
    components: {
        lotsPagination,
        tableBox,
        lotsSelect,
        lotsMdInput
    },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        activeName: {
            type: String,
            default: () => ''
        },
        contractCode: {
            type: String,
            default() {
                return '';
            }
        },
        tableHeight: {
            type: Number,
            default: 357
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const btnPermissionsArr = store.getters.btnPermissions;
        const config = reactive(tableConfig);
        const state = reactive({
            searchHeaderParams: {},
            _
        });
        const actions = reactive({
            fixedWidth: 160,
            list: [
                {
                    label: '删除',
                    event: 'del',
                    displayRule: (row) => {
                        return +row.contractChangeStatus === 1 && btnPermissionsArr.includes(authorityCode.subDeleteOtherChange) && !props.readonly;
                    }
                }
            ]
        });
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.contractCode;
                condition = Object.keys(condition).reduce((acc, key) => {
                    if (condition[key] !== '' && condition[key] !== null) {
                        acc[key] = condition[key];
                    }
                    return acc;
                }, {});
                return condition;
            };
        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: contractChangeList,
                deleteApi: contractChangeDelete
            }
        });

        const btnLoading = ref(false);
        const actionClick = (event, data) => {
            switch (event) {
            case 'del':
                subTabTable.deleteRow(data, false);
                break;
            default:
                break;
            }
        };
        const rowDBclick = async (row) => {
            const { id } = row;
            // console.log('双击变更单');
            const res = await contractChangeDetailApi({ id });
            if (res.code && +res.code === 0) {
                setupContext.emit('otherChangeDetail', res.data);
            }
        };
        watch(
            () => props.activeName,
            (newVal, oldVal) => {
                if (newVal === 'otherChangeDetail' && props.contractCode) {
                    subTabTable.getList();
                }
            },
            { immediate: true }
        );
        onActivated(() => {
            getDataFn();
        });
        const getDataFn = () => {
            props.activeName === 'otherChangeDetail' && subTabTable.getList();
        };
        const getheaderParamList = () => {
            const searchHeaderParams = _.clone(state.searchHeaderParams);
            if (searchHeaderParams.createTimeLike) {
                searchHeaderParams.startTime = searchHeaderParams.createTimeLike?.[0] || null;
                searchHeaderParams.endTime = searchHeaderParams.createTimeLike?.[1] || null;
                Reflect.deleteProperty(searchHeaderParams, 'createTimeLike');
            }
            if (searchHeaderParams.updateTimeLike) {
                searchHeaderParams.startTimeUpdate = searchHeaderParams.updateTimeLike?.[0] || null;
                searchHeaderParams.endTimeUpdate = searchHeaderParams.updateTimeLike?.[1] || null;
                Reflect.deleteProperty(searchHeaderParams, 'updateTimeLike');
            }
            subTabTable.getList(searchHeaderParams);
            // changeVisible();
        };
        const changeVisible = () => {
            document.getElementById('closeMainVisible').click(); // doClose失效 ,Visible 绑定无法更新到视图 用于关闭其他el-popover弹窗
        };
        const getAdvanceValue = (data, item) => {
            state.searchHeaderParams[item.prop] = data[item.prop];
            subTabTable.getList(state.searchHeaderParams);
        };
        const errlist = [undefined, null, ''];
        const changeIconActive = (row) => {
            return errlist.includes(row) ? '' : 'icon-active';
        };
        return {
            ...toRefs(state),
            config,
            actions,
            subTabTable,
            btnLoading,
            actionClick,
            rowDBclick,
            getDataFn,
            changeIconActive,
            getAdvanceValue,
            changeVisible,
            getheaderParamList
        };
    }
};
</script>
