export const tableConfig = {
    name: 'priceDetail',
    selection: false,
    height: 600,
    columns: [
        // {
        //     prop: 'contractCode',
        //     label: '合同编号'
        // },
        {
            prop: 'priceCode',
            label: '价格表编码',
            width: 160
        },
        {
            prop: 'templateCode',
            label: '计费模板编码',
            width: 140,
            headerType: 'input',
            sort: true
        },
        {
            prop: 'templateName',
            label: '计费模板名称',
            width: 160,
            headerType: 'input',
            sort: true
        },
        {
            prop: 'feeCode',
            label: '费用名称',
            width: 120,
            type: 'select',
            optionsKey: 'OTP_FEE_NAME',
            headerType: 'select',
            params: {
                params: {
                    optionsKey: 'OTP_FEE_NAME'

                }
            },
            sort: true
        },
        {
            prop: 'collectUnitName',
            label: '汇总条件',
            width: 80
        },
        {
            prop: 'orderTypeName',
            label: '单据类型',
            width: 80
        },
        {
            prop: 'businessTypeName',
            label: '业务类型',
            width: 80
        },
        {
            prop: 'status',
            label: '状态',
            type: 'select',
            optionsKey: 'SYS_CRM_CONTRACT_PRICE_STATUS'
        },
        {
            prop: 'changeType', // 0新增,1删除,2修改
            label: '变更类型',
            type: 'select',
            optionsKey: 'CRM_PRICE_CHANGE_TYPE'
        },
        {
            prop: 'originCode',
            label: '变更来源',
            width: 160
        }
    ]
};
// 价格表-合同
export const priceConfig = {
    name: 'priceTemplateSearach',
    searchFields: [
        {
            name: '单据编号',
            value: 'contractCode',
            isFixed: true,
            type: 'input',
            span: 5
        },
        {
            name: '合同名称',
            value: 'contractName',
            isFixed: true,
            type: 'input',
            span: 5
        },
        {
            name: '合同状态',
            value: 'contractStatuss',
            isFixed: true,
            optionNum: 'SYS_CR_CONTRACT_STATUS',
            type: 'select',
            multiple: true,
            span: 7
        }
    ],
    height: 285,
    columns: [
        {
            label: '单据编号',
            minWidth: 160,
            prop: 'contractCode',
            sortable: true
        },
        {
            label: '合同名称',
            prop: 'contractName',
            minWidth: 160
        },
        {
            label: '合同状态',
            type: 'select',
            optionsKey: 'SYS_CR_CONTRACT_STATUS',
            prop: 'contractStatus'
        },
        {
            label: '生效日期',
            prop: 'validTime',
            sortable: true,
            minWidth: 154
        },
        {
            label: '失效日期',
            prop: 'failTime',
            sortable: true,
            minWidth: 154
        }
    ]
};
// 价格表-计费模版
export const copyPriceConfig = {
    name: 'copyPriceTemplateSearach',
    selection: true,
    searchFields: [
        {
            name: '业务类型',
            value: 'businessTypeList',
            isFixed: true,
            type: 'select',
            optionNum: 'SYS_BMS_BUSI_TYPE',
            multiple: true,
            span: 8
        }
    ],
    columns: [
        {
            prop: 'priceCode',
            label: '价格表编码',
            width: 160
        },
        {
            prop: 'templateCode',
            label: '计费模板编码',
            width: 140
        },
        {
            prop: 'templateName',
            label: '计费模板名称',
            width: 160
        },
        {
            prop: 'businessTypeName',
            label: '业务类型',
            width: 160
        },
        {
            prop: 'feeCode',
            label: '费用名称',
            width: 120,
            type: 'select'
        },
        {
            prop: 'collectUnitName',
            label: '汇总条件',
            width: 80
        },
        {
            prop: 'orderTypeName',
            label: '单据类型',
            width: 80
        }
    ]
};
