<template>
    <div class="contract-manage-iflow-table">
        <table-box v-loading="subTabTable.tableLoading" :selection="TABLECONFIG.selection"
            :columns="TABLECONFIG.columns" :height="tableHeight" :actions="TABLECONFIG.actions"
            :rows="subTabTable.totalData" @action-click="actionClick">
            <template v-slot:col-append="{ col, row }">
                <div v-if="col.prop === 'mipUrl'">
                    <span size="mini" type="text" class="mip-page-link no-wrap" :title="row.mipUrl"
                        @click="handleLink('mipUrl', row)">{{ row.mipUrl || '' }}</span>
                </div>
                <div v-if="col.prop === 'legalMipUrl'">
                    <span size="mini" type="text" class="mip-page-link" @click="handleLink('legalMipUrl', row)">{{
                        row.legalMipUrl || '' }}</span>
                </div>
            </template>
        </table-box>
        <div class="footer">
            <lots-pagination @size-change='subTabTable.sizeChange' :current-page.sync='subTabTable.pageNo'
                @current-change='subTabTable.pageChange' size="small" layout='total, sizes, prev, pager, next, jumper'
                :total='subTabTable.pageTotal' />
        </div>
    </div>
</template>

<script>
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { contractWorkflowList, contractFlowAbandom } from '@mdm/api/contractManage/contractFootPageApi.js';
import { ref, watch, getCurrentInstance, computed, reactive, onActivated } from '@vue/composition-api';
import { STATE_OTHER_CHANGE } from '@/modules/mdm/constant/contractManage.js';
import { tableConfig } from './config.js';
import tableBox from '@/components/lots/tableBox/Index';
import utils from '@/components/utils/common.js';
const { MAGIC_NUMBER } = utils;
export default {
    components: {
        lotsPagination, tableBox
    },
    emits: ['getNewIflowList'],
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 357
        },
        actions: { // 表格操作信息，不传默认为空
            type: Object,
            default() {
                return {
                    list: []
                };
            }
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        // eslint-disable-next-line complexity
        const TABLECONFIG = computed(() => {
            const newColumns = [];
            for (let i = 0; i < config.columns.length; i++) {
                if (!['mipUrl', 'legalMipUrl'].includes(config.columns[i].prop)) {
                    newColumns.push(config.columns[i]);
                } else if (config.columns[i].prop === 'mipUrl') {
                    if (props.detailData.contractGroupCode) { // 合同组合同，不break（同时展示mipUrl，legalMipUrl）
                        newColumns.push(config.columns[i]);
                    } else if (isFW.value !== '1') { // 非合同组合同
                        newColumns.push(config.columns[i]);
                        break;
                    } else if (props.detailPageState === STATE_OTHER_CHANGE && subTabTable.totalData.value.length) {
                        // eslint-disable-next-line max-depth
                        if (['1', '00', '5', '6', '30', '80'].includes(subTabTable.totalData.value[0].mipStatus + '')) { // 日期变更法务审批为草稿时，展示mipUrl，不展示legalMipUrl
                            newColumns.push(config.columns[i]);
                            break;
                        }
                    }
                } else if (config.columns[i].prop === 'legalMipUrl') {
                    if (props.detailData.contractGroupCode || isFW.value === '1') {
                        newColumns.push(config.columns[i]);
                    }
                }
            };
            const newConfig = { ...config, actions: props.actions, columns: newColumns };
            return newConfig;
        });
        class IflowTalbeClass extends AdvanceListClass {
            beforeGetList(condition) {
                const code = props.detailPageState === STATE_OTHER_CHANGE ? props.detailData.contractChangeCode : props.detailData.contractCode;
                condition.contractCode = code;
                condition.contractGroupCode = props.detailData.contractGroupCode || null;
                return condition;
            }
            afterGetList(res) {
                if (+res.code === 0 && res.data && res.data.list) {
                    res.data.list.forEach(row => {
                        row.enableFlagCN = row.enableFlag === 1 ? '启用' : '停用';
                        row.legalMipUrl && props.detailPageState !== STATE_OTHER_CHANGE && (row.mipUrl = '');
                    });
                    setupContext.emit('getNewIflowList', res.data.list);
                }
                super.afterGetList(res);
            }
        };
        const subTabTable = new IflowTalbeClass({
            Api: {
                listApi: contractWorkflowList,
                deleteApi: contractFlowAbandom
            }
        });
        const isFW = computed(() => {
            return props.detailPageState === STATE_OTHER_CHANGE ? (+props.detailData.changeType === 2 ? '1' : '')
                : (String(props.detailData.contractCharacter) !== '4' ? '1' : '');
        });
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const handleLink = (type, row) => {
            if (type === 'legalMipUrl') {
                window.open(row.legalMipUrl, '_blank');
            } else {
                SwitchHttpsProtocol();
                const { contractCode, contractName, contractCharacter, contractChangeCode, contractGroupCode } = props.detailData;
                const code = props.detailPageState === STATE_OTHER_CHANGE ? contractChangeCode : contractCode;
                const fdId = row.mipId;
                const fdTemplateKey = isFW.value === '1' ? '' : (props.detailPageState === STATE_OTHER_CHANGE ? 'CRM_contract_price' : 'LMDM_customer_contract');
                let query = null;
                if (props.detailPageState === STATE_OTHER_CHANGE) {
                    query = { fdId, fdTemplateKey, contractCode: code, changeType: props.detailData.changeType };
                } else if (+row.mipType === MAGIC_NUMBER.TWENTY_THREE) { // 合同组
                    query = { fdId, fdTemplateKey: 'CRM_contract_group', contractCode: contractGroupCode, group: 1 };
                } else {
                    query = { fdId, fdTemplateKey, contractCode: code, contractName, contractCharacter };
                }
                const routeData = ctx.$router.resolve({ name: 'mipIframe', query });
                window.open(routeData.href, '_blank');
            }
        };
        const SwitchHttpsProtocol = () => { // 1 判断进入页面的协议是否是http,如果是http将协议转换成https
            if (location.protocol === 'http:') {
                setTimeout(() => {
                    window.location.replace(
                        location.href.replace('http', 'https')
                    );
                }, 0);
            }
        };
        const dictData = ref({});
        watch(
            () => [props.activeName, props.detailData.contractCode, props.detailData.contractChangeCode],
            (newVal, oldVal) => {
                if (newVal[0] === 'iflowDetail' && (props.detailPageState === STATE_OTHER_CHANGE ? newVal[2] : newVal[1])) {
                    subTabTable.getList();
                } else if (!newVal[1]) {
                    subTabTable.totalData.value = [];
                    subTabTable.pageTotal.value = 0;
                }
            }
        );
        onActivated(() => {
            getDataFn();
        });
        const getDataFn = () => {
            props.activeName === 'iflowDetail' && (props.detailPageState === STATE_OTHER_CHANGE ? props.detailData.contractChangeCode : props.detailData.contractCode)
                && subTabTable.getList();
        };
        const actionClick = (event, row) => {
            if (event === 'abandom') {
                subTabTable.deleteRow({ mipId: row.mipId }, {
                    msg: '请确认是否特权废弃该流程？该操作不可恢复',
                    successMsg: '特权废弃成功！'
                });
            }
        };
        return {
            config,
            TABLECONFIG,
            dictData,
            subTabTable,
            handleLink,
            isFW,
            getDataFn,
            actionClick
        };
    }
};
</script>
<style lang='less'>
.contract-manage-iflow-table {
    // margin-top: 15px;
    .footer {
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .mip-page-link {
        color: #4285f5;
        cursor: pointer;
        &.no-wrap {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
