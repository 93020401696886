export const isOtherChangeShow = [ // 其他变更独有的一些字段
    'contractChangeCode',
    'contractChangeStatus',
    'changeDetail',
    'changeReason',
    'changeType'
];

export const isOtherChangeHide = [ // 其他变更时隐藏一些字段
    'contractName', 'serviceManagerName',
    'priceType', 'stampMode', 'sealCategory', 'sealAdminAccount',
    'ifWatermarking', 'noWatermarkingReason', 'contractSource'
];

export const isOtherPriceChangeShow = [ // 其他变更-价格变更时只展示的字段
    'contractChangeCode', 'contractChangeStatus', 'contractCode', 'contractCharacter', 'mainContract', 'changeType', 'changeReason', 'changeDetail', 'businessType'
];
export const isOtherPriceChangeEdit = [ // 其他变更-价格变更时只编辑的字段
    'changeReason'
];
export const isOtherDateChangeEdit = [ // 其他变更-日期变更时只编辑的字段
    'changeReason', 'validTime', 'failTime'
];
