export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        // {
        //     label: '合同编号',
        //     prop: 'contractCode'
        // },
        {
            label: '关联省份',
            prop: 'reletiveProvinceName',
            type: 'advance',
            advanceLabel: '关联省份',
            advanceConfig: [
                { prop: 'ebplNameCn', value: '省份名称' },
                { prop: 'ebplCode', value: '省份编码' }
            ],
            tableConfig: [
                { prop: 'ebplCode', value: '省份编码' },
                { prop: 'ebplNameCn', value: '省份名称' }
            ],
            mustFill: true,
            prefix: '/api-mdm',
            advanceUrl: '/searchEbPlace',
            cbParams: ['ebplCode#reletiveProvinceCode', 'ebplNameCn#reletiveProvinceName'],
            beforeRequest: (params) => {
                params.ebplType = 'PLACE_PROVINCE';
                params.ebplIsAble = 'ENABLE';
                params.pageNo = 1;
                params.ebplParentPmCode = 100000;
                return params;
            },
            afterResponse: (res) => {
                const { code, msg, errMsg, timestamp, data } = res;
                const newRes = {
                    code,
                    msg,
                    errMsg,
                    timestamp,
                    data: {
                        list: data,
                        totalCount: data.length
                    }
                };
                return newRes;
            },
            width: 85
        },
        // {
        //     label: '关联省份',
        //     prop: 'reletiveProvinceCode',
        //     type: 'addressSelect',
        //     labelCn: 'reletiveProvinceName',
        //     filterable: true,
        //     unfold: true,
        //     minLayers: 0,
        //     config: {
        //         checkStrictly: false
        //     },
        //     mustFill: true
        // },
        {
            label: '合同基础油价',
            prop: 'baseOilPrice',
            type: 'input',
            mustFill: true,
            width: 110
        },
        {
            label: '油价单位',
            prop: 'oilPriceUnit',
            type: 'select',
            optionsKey: 'SYS_CR_OIL_PRICE_UNIT',
            mustFill: true,
            width: 85
        },
        {
            label: '当前执行新油价',
            prop: 'newOilPrice',
            width: 110
        },
        {
            label: '已触发油价联动次数',
            prop: 'triggeredOilPrice',
            defaultValue: 0,
            width: 140
        },
        // {
        //     label: '生效日期',
        //     prop: 'validTime'
        // },
        // {
        //     label: '失效日期',
        //     prop: 'failTime'
        // },
        {
            label: '创建人',
            prop: 'createUserName'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        },
        {
            label: '修改人',
            prop: 'updateUserName'
        },
        {
            label: '修改时间',
            prop: 'updateTime'
        }
    ]
};
