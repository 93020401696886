<template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button type="text" icon="el-icon-circle-plus-outline" size="medium" @click="handleAddRow" :disabled="isDisableBtn" v-has="authorityCode.subAddNewRate">新增</lots-button>
            <lots-button type="text" icon="el-icon-circle-check" size="medium"
                @click="handleSaveRows" :disabled="isDisableBtn" :loading="saveLoading" v-has="authorityCode.subSaveRate">保存</lots-button>
            <lots-button type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows" :disabled="isDisableBtn"
                v-has="authorityCode.subDeleteRate">删除</lots-button>
            <lots-button type="text" icon="el-icon-circle-plus-outline"
                size="medium"
                v-has="authorityCode.rateAddPrivilege"
                :disabled="detailPageState === 'new' || !detailData.contractCode"
                @click="handleAddRow">特权新增</lots-button>
            <lots-button type="text" icon="el-icon-circle-check" size="medium"
                v-has="authorityCode.rateSavePrivilege"
                :disabled="detailPageState === 'new' || !detailData.contractCode"
                @click="handleSaveRows"
                :loading="saveLoading">特权保存</lots-button>
            <lots-button type="text" icon="el-icon-delete" size="medium"
                v-has="authorityCode.rateDeletePrivilege"
                :disabled="detailPageState === 'new' || !detailData.contractCode"
                @click="handleDeleteRows">特权删除</lots-button>
        </div>
        <div class="file-detail-table">
                <edit-table-box
                    class="prescreen"
                    :control="true"
                    v-loading="subTabTable.tableLoading"
                    ref="editTableBox"
                    :selection="config.selection"
                    :columns="config.columns"
                    :height="tableHeight"
                    :tableData="subTabTable.totalData"
                    :actions="config.actions"
                    @selection-change="handleSelectedRow"
                    @element-change="handleChangeRow"
                ></edit-table-box>
            <div class="footer">
                <lots-pagination
                    @size-change='subTabTable.sizeChange'
                    :current-page.sync='subTabTable.pageNo'
                    @current-change='subTabTable.pageChange'
                    size="small" layout='total, sizes, prev, pager, next, jumper'
                    :total='subTabTable.pageTotal'/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch, onActivated } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { Message } from 'element-ui';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    rateInfoList,
    rateInfoDelete,
    rateInfoSave
} from '@mdm/api/contractManage/contractFootPageApi.js';

export default {
    name: '',
    components: { lotsPagination, editTableBox, lotsButton }, // tableBox, lotsSelect, otpAdvance, DialogImportFile, lotsButton,
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        const authorityCode = reactive(btnAuthority.contractManage);
        const isDisableBtn = ref(false);
        const saveLoading = ref(false);
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.detailData.contractCode;
                return condition;
            };
        };
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: rateInfoList,
                deleteApi: rateInfoDelete
            }
        });

        const btnLoading = ref(false);
        const handleAddRow = () => {
            setupContext.refs.editTableBox.addRow();
            subTabTable.totalData.value[0].disableProps = ['costTypeName']; // 新增时业务类型为空，禁用费用名称
        };
        const selectedRows = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selectedRows.value = selection;
        };
        const handleChangeRow = ({ prop, val, type, row, index }) => {
            if (prop === 'businessType') { // 操作业务类型,置空其他值
                row['costTypeCode'] = null;
                row['costTypeName'] = null;
                row['taxRateCode'] = null;
                row['rate'] = null;
                row.disableProps = val ? [] : ['costTypeName']; // 为空，禁用费用名称
            }
            if (type === 'advance' && val['costTypeName'] !== undefined) { // 费用弹框清空
                row['taxRateCode'] = null;
                row['rate'] = null;
            }
        };
        const handleSaveRows = async () => {
            if (!subTabTable.totalData.value.length) {
                return Message.warning('目前无数据无法保存！');
            }
            try {
                subTabTable.totalData.value.forEach(v => {
                    if (setupContext.refs.editTableBox.saveItemData(v) === false) {
                        throw Error();
                    }
                });
            } catch (err) { return; }
            subTabTable.totalData.value.forEach(e => {
                e.costTypeName = e.costTypeName || null;
                e.costTypeCode = e.costTypeCode || null;
                e.contractCode = props.detailData.contractCode === '0000' ? null : props.detailData.contractCode;
            });
            saveLoading.value = true;
            const res = await rateInfoSave(subTabTable.totalData.value);
            saveLoading.value = false;
            if (res && +res.code === 0) {
                Message({
                    message: '成功保存' + res.data + '条数据',
                    type: 'success'
                });
                subTabTable.getList(); // 刷新列表
            }
        };
        const handleDeleteRows = async () => { // 调批量删除接口
            if (selectedRows.value.length === 0) {
                Message.error('请选择需要删除的条目');
                return;
            }
            let passFlag = true;
            selectedRows.value.forEach(item => {
                if (!item.id) {
                    passFlag = false;
                }
            });
            if (!passFlag) {
                return Message.warning('存在新增未保存的数据，请先保存或取消编辑');
            }
            const ids = JSON.parse(JSON.stringify(selectedRows.value)).map((v) => v.id);
            subTabTable.deleteRow({ ids }, false);
        };
        watch(
            () => [props.detailPageState, props.detailData.contractStatus, props.activeName, props.detailData.contractCode],
            (newVal) => {
                if (newVal[2] === 'rateDetail') { // props.activeName
                    if (newVal[0] === STATE_NEW) {
                        isDisableBtn.value = false;
                    } else {
                        isDisableBtn.value = !(newVal[1] + '' === '0' || newVal[1] + '' === '1');
                    }
                    if (newVal[3]) { // contractCode
                        subTabTable.getList();
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout();
        });
        return {
            isDisableBtn,
            config,
            subTabTable,
            btnLoading,
            handleAddRow,
            handleSelectedRow,
            handleChangeRow,
            handleSaveRows,
            handleDeleteRows,
            authorityCode,
            saveLoading
        };
    }
};
</script>
