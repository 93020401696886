export const tableConfig = {
    selection: false,
    height: 357,
    columns: [
        {
            label: '流程类型',
            prop: 'mipType',
            type: 'select',
            optionsKey: 'MIP_TYPE',
            minWidth: 90
        },
        {
            label: '审批状态',
            prop: 'mipStatus',
            type: 'select',
            optionsKey: 'SYS_SU_MIP_AUDIT_STATUS',
            minWidth: 80
        },
        {
            label: 'mip审批流编码',
            prop: 'mipId',
            minWidth: 160
        },
        {
            label: '创建人',
            prop: 'createUserCode',
            minWidth: 100
        },
        {
            label: '创建时间',
            prop: 'createTime',
            minWidth: 160
        },
        {
            label: '审批流',
            prop: 'mipUrl',
            showTooltip: false,
            type: 'slot',
            minWidth: 300
        },
        {
            label: '法务审批流',
            prop: 'legalMipUrl',
            type: 'slot',
            minWidth: 300
        }
    ]
};
