<template>
    <div class="add-new-main-dialog">
        <el-dialog
            :visible="true"
            title="新增商机"
            width="1000px"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <table-box
                class="add-main-dialog-table"
                v-loading="neoCrmOpportunityList.tableLoading"
                ref="tableBox"
                :selection="detailPageState === 'new'"
                :height="config.height"
                :columns="config.columns"
                :rows="neoCrmOpportunityList.totalData"
                :highlight-current-row="detailPageState !== 'new'"
                :selectable="detailPageState === 'new' ? config.selectable : null"
                @selection-change="handleSelectedChange"
                @row-click="handleRowClick"
                @row-dblclick="handleDbclick"
            >
            </table-box>
            <lots-pagination
                @size-change="neoCrmOpportunityList.sizeChange"
                :current-page.sync="neoCrmOpportunityList.pageNo"
                @current-change="neoCrmOpportunityList.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="neoCrmOpportunityList.pageTotal"
                :pageSize="neoCrmOpportunityList.pageSize"
            ></lots-pagination>
            <span slot="footer">
                <el-button type="primary" size="mini" @click="confirm">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { ListClass } from '@/modules/mdm/composition/dc/common/list/ListClass';
import { tableConfig } from './config.js';
import { searchNeoCrmOpportunityApi } from '@mdm/api/contractManage/detailMainApi.js';
import { Message } from 'element-ui';
import store from '@/store';
import { STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import utils from '@/components/utils/common.js';
const { MAGIC_NUMBER } = utils;
export default {
    components: { tableBox, lotsPagination },
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        const emit = setupContext.emit;
        class DataDetailClass extends ListClass {
            beforeGetList(condition) {
                if (!condition.projectCode) {
                    condition.projectCode = addNewMainData.value['projectCode'];
                    condition.userCode = store.getters.user.userCode;
                }
                neoCrmOpportunityList.pageSize.value = condition.pageSize || MAGIC_NUMBER.FIFTY;
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0) {
                    if (+res.code === 0) {
                        neoCrmOpportunityList.totalData.value = res.data.list.map(item => {
                            item = disabledItemFn(item);
                            return item;
                        });
                        neoCrmOpportunityList.pageTotal.value = res.data.totalCount;
                    }
                }
            }
        };
        const disabledItemFn = (item) => {
            if (item.saleStageId !== '中标确认' && item.saleStageId !== '合同签订') {
                item.disabledBid = true;
            } else {
                const { bidDate, contractSearchDate } = item;
                const bidDateTime = bidDate ? new Date(bidDate).getTime() : null;
                const SearchDateTime = contractSearchDate ? new Date(contractSearchDate).getTime() : null;
                const nowTime = new Date().getTime();
                if (!bidDateTime && !SearchDateTime) {
                    item.disabled30 = true;
                }
                if (bidDateTime) {
                    const flag1 = Math.abs(nowTime - bidDateTime) > (60 * 60 * 24 * MAGIC_NUMBER.NINETY * 1000); // 大于当前3个月（90天）时间范围
                    if (!SearchDateTime) {
                        item.disabled30 = flag1;
                    } else {
                        const flag2 = Math.abs(nowTime - SearchDateTime) > (60 * 60 * 24 * MAGIC_NUMBER.NINETY * 1000); // 大于当前30天时间范围
                        item.disabled30 = flag1 && flag2;
                    }
                }
            }
            return item;
        };
        const neoCrmOpportunityList = new DataDetailClass({
            Api: {
                listApi: searchNeoCrmOpportunityApi
            }
        });
        const addNewMainData = ref({
            oilIsGroupCustomer: '',
            contractCharacter: '',
            mainContract: '',
            projectCode: '',
            projectStatus: ''
        });
        const FIELDS = computed(() => {
            config.fields.forEach(item => {
                if (item.prop === 'mainContract') {
                    item.visible = addNewMainData.value.contractCharacter === '2';
                }
                if (item.prop === 'projectCode') {
                    item.visible = addNewMainData.value.oilIsGroupCustomer === '1';
                }
            });
            return config.fields;
        });
        onMounted(() => {
            neoCrmOpportunityList.getList({
                pageSize: 50,
                projectCode: props.detailData.projectCode,
                userCode: store.getters.user.userCode
            });
        });
        const selectedRows = ref([]);
        const handleSelectedChange = (rows) => {
            selectedRows.value = rows;
        };
        const handleRowClick = (row) => {
            if (row.disabledBid) {
                Message.warning('商机未中标,不允许关联');
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row, false);
                return false;
            } else if (row.disabled30) {
                Message.warning('商机中标日期超出3个月，请与销售管理沟通后进行关联！');
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row, false);
                return false;
            } else {
                if (props.detailPageState === STATE_NEW) { // 新增状态-多选，可切换选中状态
                    setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row); // 不传true, 用于切换选中状态
                } else { // 编辑状态-单选，单机固定选中该行
                    selectedRows.value = [row];
                    return true;
                }
            }
        };
        const handleDbclick = (row) => {
            if (props.detailPageState === STATE_NEW) {
                return false;
            } else if (handleRowClick(row)) {
                const data = {
                    opportunityList: [row]
                };
                emit('confirm', data);
            }
        };
        const confirm = () => {
            const data = { opportunityList: [] };
            selectedRows.value.length && (data.opportunityList = selectedRows.value.map((item) => {
                return { ...item, isPureWarehouse: '' };
            }));
            if (data.opportunityList.length === 0) {
                return Message.warning('请至少关联一条中标商机');
            }
            emit('confirm', data);
        };
        return {
            emit,
            config,
            neoCrmOpportunityList,
            selectedRows,
            handleSelectedChange,
            handleRowClick,
            confirm,
            addNewMainData,
            FIELDS,
            handleDbclick
        };
    }
};
</script>
<style lang="less">
.add-new-main-dialog {
    .add-main-form-box {
        width: 100%;
        padding: 0 40px 0 0;
        box-sizing: border-box;
        .el-select {
            width: 100%;
        }
    }
    .add-main-dialog-table {
        margin: 10px 0 5px 0;
    }
    .el-table__body tr.current-row>td {
        background-color: #0072ff24;
    }
}
</style>
