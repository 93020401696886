
export const initAllDialogParam = {
    esstFromProvinceCode: {
        type: 'advance',
        params: {

            label: '省份名称',
            name: '省份名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '省份名称' },
                { 'prop': 'code', 'value': '省份编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '省份编码' },
                { 'prop': 'nameCn', 'value': '省份名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstFromProvinceCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_PROVINCE';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstFromCityCode: {
        type: 'advance',
        params: {
            label: '城市名称',
            name: '城市名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '城市名称' },
                { 'prop': 'code', 'value': '城市编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '城市编码' },
                { 'prop': 'nameCn', 'value': '城市名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstFromCityCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_CITY';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstFromDistrictCode: {
        type: 'advance',
        params: {
            label: '区县名称',
            name: '区县名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '区县名称' },
                { 'prop': 'code', 'value': '区县编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '区县编码' },
                { 'prop': 'nameCn', 'value': '区县名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstFromDistrictCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_DISTRICT';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstFromStreetCode: {
        type: 'advance',
        params: {
            label: '街道名称',
            name: '街道名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '街道名称' },
                { 'prop': 'code', 'value': '街道编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '街道编码' },
                { 'prop': 'nameCn', 'value': '街道名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstFromStreetCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_STREET';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstToProvinceCode: {
        type: 'advance',
        params: {
            label: '省份名称',
            name: '省份名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '省份名称' },
                { 'prop': 'code', 'value': '省份编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '省份编码' },
                { 'prop': 'nameCn', 'value': '省份名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstToProvinceCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_PROVINCE';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstToCityCode: {
        type: 'advance',
        params: {
            label: '城市名称',
            name: '城市名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '城市名称' },
                { 'prop': 'code', 'value': '城市编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '城市编码' },
                { 'prop': 'nameCn', 'value': '城市名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstToCityCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_CITY';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstToDistrictCode: {
        type: 'advance',
        params: {
            label: '区县编码',
            name: '区县编码',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '区县名称' },
                { 'prop': 'code', 'value': '区县编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '区县编码' },
                { 'prop': 'nameCn', 'value': '区县名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstToDistrictCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_DISTRICT';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    esstToStreetCode: {
        type: 'advance',
        params: {
            label: '街道名称',
            name: '街道名称',
            prop: 'nameCn',
            value: 'nameCn',
            // type: 'advance',
            type: 'custom',
            visible: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '街道名称' },
                { 'prop': 'code', 'value': '街道编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '街道编码' },
                { 'prop': 'nameCn', 'value': '街道名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code#esstToStreetCode', 'nameCn#nameCn'],
            beforeRequest: (params) => {
                params.type = 'PLACE_STREET';
                params.countryCode = 100000;
                return params;
            },
            disabled: true
        }
    },
    status: {
        type: 'select',
        params: {
            optionsKey: 'SYS_CRM_CONTRACT_PRICE_STATUS'
        }
    },
    changeType: {
        type: 'select',
        params: {
            optionsKey: 'CRM_PRICE_CHANGE_TYPE'
        }
    },
    orderType: {
        type: 'select',
        params: {
            optionsKey: 'ORDER_TYPE'
        }
    },
    ordsizeIdentificationerType: {
        type: 'select',
        params: {
            optionsKey: 'OTP_PIECE_TYPE'
        }
    }
    // mainContract: {
    //     label: '主合同编号',
    //     name: '主合同编号',
    //     prop: 'mainContract',
    //     value: 'mainContract',
    //     // type: 'advance',
    //     type: 'custom',
    //     visible: true,
    //     rule: [
    //         { required: false, message: '主合同编号不能为空', trigger: ['change', 'blur'] }
    //     ],
    //     advanceConfig: [
    //         { 'prop': 'contractCode', 'value': '主合同编码' },
    //         { 'prop': 'contractName', 'value': '主合同名称' }
    //     ],
    //     tableConfig: [
    //         { 'prop': 'contractCode', 'value': '主合同编码' },
    //         { 'prop': 'contractName', 'value': '主合同名称', displayName: true },
    //         { 'prop': 'validTime', 'value': '生效日期' },
    //         { 'prop': 'failTime', 'value': '失效日期' }
    //     ],
    //     prefix: '/api-lcrm',
    //     method: 'post',
    //     advanceUrl: `/contract/info/page`,
    //     cbParams: ['contractCode#mainContract', 'contractName#mainContractName'],
    //     beforeRequest: (params) => {
    //         params.contractCode = params.contractCode ? params.contractCode : null;
    //         params.contractName = params.contractName ? params.contractName : null;
    //         params.contractCharacter = '1'; // 合同性质为新签
    //         return params;
    //     },
    //     disabled: true
    // },
    // companyCode: {
    //     name: '分公司',
    //     prop: 'companyCode',
    //     value: 'companyCode',
    //     type: 'custom',
    //     // maxNum: 10, // 多选条数限制
    //     isFixed: true,
    //     span: 4,
    //     advanceConfig: [
    //         { 'prop': 'companyNameCn', 'value': '分公司名称' },
    //         { 'prop': 'companyCode', 'value': '分公司编码' }
    //     ],
    //     tableConfig: [
    //         { 'prop': 'companyCode', 'value': '分公司编码' },
    //         { 'prop': 'companyNameCn', 'value': '分公司名称', 'displayName': 'companyNameCn' }
    //     ],
    //     prefix: '/api-mdm',
    //     advanceUrl: `/esCompany/popCompany`,
    //     cbParams: ['companyCode', 'companyNameCn#companyName']
    // },
    // siteName: {
    //     value: 'siteName',
    //     type: 'advance',
    //     name: '服务平台',
    //     advanceConfig: [
    //         { 'prop': 'siteNameCn', 'value': '服务平台名称' },
    //         { 'prop': 'siteCode', 'value': '服务平台编码' }
    //     ],
    //     tableConfig: [
    //         { 'prop': 'siteCode', 'value': '服务平台编码' },
    //         { 'prop': 'siteNameCn', 'value': '服务平台名称' }
    //     ],
    //     prefix: '/api-mdm',
    //     advanceUrl: `/esCompany/popSite`,
    //     cbParams: ['siteCode#siteCode', 'siteNameCn#siteName']
    // },
    // customerName: {
    //     label: '客户名称',
    //     prop: 'customerName',
    //     type: 'advance',
    //     name: '客户名称',
    //     mustFill: true,
    //     advanceConfig: [
    //         { prop: 'customerName', value: '客户名称' },
    //         { prop: 'customerCode', value: '客户编码' }
    //     ],
    //     tableConfig: [
    //         { prop: 'accountName', value: '客户名称' },
    //         { prop: 'customItem232__c', value: '客户编码' },
    //         { prop: 'customItem169__c', value: '客户简称' },
    //         { prop: 'accountId', value: '账号ID' }
    //     ],
    //     method: 'post',
    //     prefix: '/api-lcrm',
    //     advanceUrl: '/contract/customer/account/relation',
    //     cbParams: [
    //         'customItem232__c#customerCode',
    //         'accountName#customerName'
    //     ],
    //     beforeRequest: (params) => {
    //         return params;
    //     }
    // }
};
