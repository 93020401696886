<!-- eslint-disable max-lines -->
<template>
    <div class="file-detail-subtab customer-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button
                v-has="authorityCode.subAddNewCustomer"
                type="text"
                icon="el-icon-circle-plus-outline"
                size="medium"
                @click="handleAddRow"
                :disabled="isDisableBtn"
                >新增</lots-button>
            <lots-button
                v-has="authorityCode.subSaveCustomer"
                type="text"
                icon="el-icon-circle-check"
                size="medium"
                :disabled="isDisableBtn"
                :loading="saveLoading"
                @click="handleSaveRows">保存</lots-button>
            <lots-button
                v-has="authorityCode.subDeleteCustomer" type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows()" :disabled="isDisableBtn">删除</lots-button>
            <lots-button
                v-has="authorityCode.subImportCustomer" type="text" icon="el-icon-upload2" size="medium"
                @click="handleImport()" :disabled="isDisableBtn" class="bigData-btn-detailPageCustomerTabImport">导入</lots-button>
            <report-export
                #default="{ submit }"
                reportCode="REPORT_CRM_CONTRACT_CUSTOMER">
                <lots-button v-has="authorityCode.subExportCustomer" type="text" @click="handleExport(submit)" size="medium" icon="el-icon-download" >导出
                </lots-button>
            </report-export>
            <div class="sub-tab-search-box">
                <search-box
                    ref="searchBox"
                    :search-key="searchConfig.name"
                    :fields="searchConfig.searchFields"
                    @search-change="handleSearch"
                />
            </div>
        </div>
        <div class="file-detail-table">
            <edit-table-box
                class="prescreen"
                v-loading="subTabTable.tableLoading"
                ref="editTableBox"
                :key="columnKey"
                :selection="config.selection"
                :control="true"
                :columns="newColumns"
                :height="tableHeight"
                :tableData="subTabTable.totalData"
                :actions="config.actions"
                :editDisableRule="() => isDisableBtn"
                @sort-change="handleSort"
                @selection-change="handleSelectedRow"
                @element-change="handleChangeRow">
                <template #edit-col-append="{ item, row }">
                    <lots-select
                        :key="sealAdminKey"
                        v-if="item.prop === 'sealAdminName'"
                        :options="row.sealAdminDict || {}"
                        :clearable="true"
                        :disabled="!+row.stampSubject === 1 || detailPageState === STATE_OTHER_CHANGE"
                        v-model="row['sealAdminAccount']"
                        @change="(val) => handleChangeRow({ prop: 'sealAdminName', val, row })">
                    </lots-select>
                </template>
            </edit-table-box>
            <div class="footer">
                <lots-pagination
                @size-change="subTabTable.sizeChange"
                :current-page.sync="subTabTable.pageNo"
                @current-change="subTabTable.pageChange"
                size="small"
                layout="total, sizes, prev, pager, next, jumper"
                :total="subTabTable.pageTotal" />
            </div>
            <el-dialog
                @close="closeLog"
                v-loading="btnLoading"
                title="附件新增"
                :visible.sync="addDialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                width="470px">
                <el-form
                    :model="filesForm"
                    label-position="right"
                    class="form-dialog"
                    label-width="80px"
                    ref="filesFormDialog">
                    <div class="upload-btn">
                        <lots-button size="mini" type="primary" @click="downLoad()">下载模板</lots-button>
                        <el-upload
                            class="upload-demo"
                            ref="upload"
                            :headers="reqHeaders"
                            :action="staticConfig.dialogImportConfig.ImportConfig.action"
                            :data="{ inOutType: 'OUT' }"
                            :auto-upload="staticConfig.dialogImportConfig.ImportConfig.manualUpload"
                            :multiple="staticConfig.dialogImportConfig.ImportConfig.multiple"
                            :show-file-list="staticConfig.dialogImportConfig.ImportConfig.showFileList"
                            :accept="staticConfig.dialogImportConfig.ImportConfig.accept"
                            :on-change="onChange"
                            :limit="2"
                            :with-credentials="true"
                            :file-list="fileList">
                            <lots-button size="mini" type="primary">上传附件</lots-button>
                        </el-upload>
                    </div>
                    <el-form-item label="附件名称" prop="accessoryName">
                        <el-input v-model="filesForm.accessoryName" size="mini"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <lots-button size="mini" @click="closeLog">取 消</lots-button>
                    <lots-button size="mini" type="primary" @click="uploadFileCheck">数据校验</lots-button>
                    <lots-button size="mini" type="primary" @click="uploadFileImport" :disabled="disableToImport">覆盖导入</lots-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, watch } from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsButton from '@/components/lots/lotsButton';
import searchBox from '@/components/lots/searchBox';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    contractCustomerList,
    contractCustomerDelete,
    contractCustomerSave,
    contractCustomerImport,
    contractCustomerImportSave,
    contractOtherChangeCustomerList,
    contractOtherChangeCustomerDelete,
    contractOtherChangeCustomerSave,
    contractCustomerChangeImport,
    contractCustomerChangeImportSave
} from '@mdm/api/contractManage/contractFootPageApi.js';
import { getSealAdminList, getSrmCompanyInfosApi } from '@mdm/api/contractManage/detailMainApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { importConfig, tableConfig, searchBoxConfig, tableJDSKConfig } from './config.js';
import { Message } from 'element-ui';
import { STATE_OTHER_CHANGE, STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import store from '@/store';
import _ from 'lodash';
import reportExport from '@/components/lots/reportExportPlugin';
export default {
    name: '',
    components: {
        lotsPagination,
        lotsButton,
        editTableBox,
        searchBox,
        lotsSelect,
        reportExport
    },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const reqHeaders = ref({ tenantCode: store.getters.currentTenant.tenantCode });
        const refs = setupContext.refs;
        const config = reactive(_.cloneDeep(tableConfig));
        const isDisableBtn = ref(false);
        const staticConfig = reactive(importConfig);
        const searchConfig = reactive(searchBoxConfig);
        const orderBy = ref('');
        const orderByType = ref('');
        const orderLike = {
            descending: 'desc',
            ascending: 'asc'
        };
        const { user } = setupContext.root.$store.getters;
        const saveLoading = ref(false);
        let listApi = reactive({});
        let deleteApi = reactive({});
        if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
            listApi = contractOtherChangeCustomerList;
            deleteApi = contractOtherChangeCustomerDelete;
        } else {
            listApi = contractCustomerList;
            deleteApi = contractCustomerDelete;
        }
        class FilesDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.detailData.contractCode;
                condition.orderBy = orderBy.value;
                condition.orderByType = orderByType.value;
                if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
                    condition.contractChangeCode = props.detailData.contractChangeCode || null;
                }
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0) {
                    res.data.list.forEach(item => {
                        item.edit = -3;
                        item.stampSubject = item.stampSubject?.toString();
                        item.templateCustomerRole = item.templateCustomerRole?.toString();
                        getSealAdminDict(item);
                    });
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const subTabTable = new FilesDetailClass({
            Api: {
                listApi,
                deleteApi
            }
        });
        const addDialog = ref(false);
        const hasFilesType = computed(() => !!filesForm.value.fileType);
        const uploadOrHangdleForm = computed(
            () => !!(hasFilesType && filesForm.value.templateName)
        );
        const newColumns = computed(() => {
            if (+props.detailData.contractSource === 6) { // 京东数科
                return _.cloneDeep(tableJDSKConfig.columns);
            } else if (+props.detailData.stampMode === 2) { // 盖章方式=2纸质
                config.columns = _.cloneDeep(tableConfig.columns);
                return config.columns;
            } else {
                return config.columns.filter((item) => item.prop !== 'sealAdminName');
            }
        });
        const columnKey = computed(() => {
            const { contractSource } = props.detailData;
            return contractSource + new Date().getTime();
        });
        const filesForm = ref({
            accessoryName: '',
            fileType: '',
            templateName: '',
            draftModel: ''
        });

        const btnLoading = ref(false);
        const fileList = ref([]);
        const filesBasicParams = reactive({
            contractCode: '',
            userCode: '',
            contractCharacter: ''
        });
        const handleAddRow = () => {
            // refs.editTableBox.addRow();
            const newItem = {
                edit: 2,
                sealAdminName: undefined
            };
            subTabTable.totalData.value.unshift(newItem);
        };
        const handleImport = () => { addDialog.value = true; }; // 导入打开弹窗进行附件上传
        const upload = (formName) => {
            refs[formName].validate((valid) => {
                if (valid) {
                    const { contractCode } = filesBasicParams;
                    const { fileType, accessoryName } = filesForm.value;// tenantCode
                    let urlObject = {
                        contractCode,
                        fileType,
                        accessoryName
                    };
                    if (props.detailPageState === STATE_OTHER_CHANGE) {
                        urlObject = { ...urlObject, contractChangeCode: props.detailData.contractChangeCode || '001' };
                    }
                    const urlParams = Object.keys(urlObject).reduce((pre, cur) => {
                        if (urlObject[cur]) {
                            if (pre.length !== 1) {
                                pre = `${pre}&${cur}=${urlObject[cur]}`;
                            } else {
                                pre = `${pre}${cur}=${urlObject[cur]}`;
                            }
                        }
                        return pre;
                    }, '?');
                    let baseUrl = staticConfig.dialogImportConfig.ImportConfig.baseUrl;
                    if (props.detailPageState === STATE_OTHER_CHANGE) {
                        baseUrl = baseUrl.replace('contractCustomerInfo', 'contractCustomerChange');
                    }
                    staticConfig.dialogImportConfig.ImportConfig.action = baseUrl.concat(urlParams);
                    refs.Import.orderImportC();
                }
            });
        };
        const getAdvanceValue = (val) => {
            filesForm.value.templateId = val.templateId;
            filesForm.value.templateName = val.templateName;
            filesForm.value.attachmentId = val.attachmentId;
            filesForm.value.draftModel = val.draftModel;
        };
        // 下载模板
        const downLoad = () => {
            window.open('https://oss-cn-foshan.midea.com/oss/auth/userDownload/y4h62Ebn9wN4Qn3N9LDabePW/lcs/prod/default/20230412/%E5%AE%A2%E6%88%B7%E6%98%8E%E7%BB%86%E5%AF%BC%E5%85%A5_1681281233275.xlsx', '_blank');
        };
        // 选择文件的时候做一些操作
        const onChange = (file) => {
            fileList.value = [];
            filesForm.value.accessoryName = file.name;
            fileList.value.push(file);
        };
        const passData = ref([]); // 通过检验成功后，后端返回的数据
        const disableToImport = ref(true);
        // 数据校验
        // eslint-disable-next-line complexity
        const uploadFileCheck = async () => {
            if (!fileList.value.length || !filesForm.value.accessoryName) {
                return Message.warning('请先上传附件');
            }
            const params = {
                contractCode: props.detailData.contractCode,
                filename: filesForm.value.accessoryName
            };
            if (props.detailPageState === STATE_OTHER_CHANGE) {
                params.contractChangeCode = props.detailData.contractChangeCode || null;
            }
            // 处理URL后面的参数拼接
            const urlParams = Object.keys(params).reduce((pre, cur) => {
                if (params[cur] || params[cur] === 0) {
                    if (pre.length !== 1) {
                        pre = `${pre}&${cur}=${params[cur]}`;
                    } else {
                        pre = `${pre}${cur}=${params[cur]}`;
                    }
                }
                return pre;
            }, '?');
            const formData = new FormData();
            // 把接口需要的参数带进去
            formData.append('file', fileList.value[0].raw);
            btnLoading.value = true;
            let res = null;
            try {
                if (props.detailPageState === STATE_OTHER_CHANGE) {
                    res = await contractCustomerChangeImport(formData, urlParams);
                } else {
                    res = await contractCustomerImport(formData, urlParams);
                }
                btnLoading.value = false;
            } catch (error) {
                btnLoading.value = false;
            }
            if (+res.code === 0) {
                Message.success('校验通过');
                if (res.data && res.data.length) {
                    res.data.map(item => {
                        item.stampSubject = item.stampSubject ? String(item.stampSubject) : '0';
                        return item;
                    });
                    passData.value = res.data;
                    disableToImport.value = false;
                }
            } else {
                // 校验不通过，清空文件
                refs.upload.fileList[0] = {};
                filesForm.value.accessoryName = null;
            }
        };
        const uploadFileImport = async() => {
            btnLoading.value = true;
            let res = null;
            if (props.detailPageState === STATE_OTHER_CHANGE) {
                res = await contractCustomerChangeImportSave(passData.value);
            } else {
                res = await contractCustomerImportSave(passData.value);
            }
            btnLoading.value = false;
            if (res.code === '0') {
                Message.success('导入成功');
                disableToImport.value = true;
                passData.value = [];
                closeLog();
                subTabTable.getList();
            }
        };
        // 清空文件类型时初始化联动表单模板数据
        const filesTypeClear = (data) => {
            filesForm.value.templateId = '';
            filesForm.value.templateName = '';
            filesForm.value.attachmentId = '';
            filesForm.value.draftModel = '';
        };
        const fileTypeChange = (data) => {
            if (data !== 'ContractNeedStamp') {
                filesTypeClear();
            }
        };
        // 导入关闭弹窗
        const uploadClose = () => {
            closeLog();
        };
        const closeLog = () => {
            fileList.value = [];
            filesForm.value = {
                accessoryName: '',
                fileType: '',
                templateName: '',
                draftModel: ''
            };
            addDialog.value = false;
            // subTabTable.getList();
        };
        const selections = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selections.value = selection;
        };
        const handleChangeRow = ({ prop, val, type, row, index }) => {
            if (prop === 'proportion') {
                const Reg = /^(?:100|[1-9]?[0-9])$/;
                const strData = val.replace(/\s*/g, '');
                if (!(Reg.test(Number(strData)))) {
                    row[prop] = '';
                    return Message.warning('请输入1~100的正整数');
                }
            } else if (['customerName', 'stampSubject'].includes(prop)) { // 改变客户或签约主体
                row.sealAdminName = undefined;
                row.sealAdminAccount = undefined;
                row.sealAdminDict = undefined;
                // 有值且是签约主体
                if (row.customerName && +row.stampSubject === 1) {
                    getSealAdminDict(row);
                }
            } else if (prop === 'sealAdminName') {
                sealAdminKey.value = !sealAdminKey.value;
            }
        };
        const handleSaveRows = async () => { // 调批量保存接口
            if (!subTabTable.totalData.value.length) {
                return Message.warning('目前无数据无法保存！');
            }
            try {
                subTabTable.totalData.value.forEach(v => {
                    if (refs.editTableBox.saveItemData(v) === false) {
                        throw Error();
                    }
                });
            } catch (err) { return; }
            const params = subTabTable.totalData.value.map(item => {
                const newItem = _.omit(item, ['edit', 'backup', 'sealAdminDict', 'serialNum']);
                return {
                    ...newItem,
                    contractCode: props.detailData.contractCode === '0000' ? undefined : props.detailData.contractCode,
                    contractChangeCode: props.detailData.contractChangeCode || undefined,
                    sealAdminName: item.sealAdminDict ? item.sealAdminDict[item.sealAdminAccount] : undefined
                };
            });
            let res = '';
            saveLoading.value = true;
            if (props.detailPageState === STATE_OTHER_CHANGE) {
                res = await contractOtherChangeCustomerSave(params);
            } else {
                res = await contractCustomerSave(params);
            }
            saveLoading.value = false;

            if (res && +res.code === 0) {
                Message({
                    message: '成功保存' + res.data + '条数据',
                    type: 'success'
                });
                subTabTable.getList(); // 刷新列表
            }
        };
        const handleDeleteRows = async () => { // 调批量删除接口
            if (selections.value.length === 0) {
                Message.error('请选择需要删除的条目');
                return;
            }
            let passFlag = true;
            selections.value.forEach(item => {
                if (!item.id) {
                    passFlag = false;
                }
            });
            if (!passFlag) {
                return Message.warning('存在新增未保存的数据，请先保存或取消编辑');
            }
            const ids = JSON.parse(JSON.stringify(selections.value)).map((v) => v.id);
            subTabTable.deleteRow({ ids, contractStatus: props.detailData.contractStatus }, false);
        };
        const handleSearch = async ($event) => {
            const inputKey = $event.customerName;
            const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
            if (reg.test(inputKey)) {
                subTabTable.searchList({ customerName: inputKey });
            } else {
                subTabTable.searchList({ customerCode: inputKey });
            }
        };
        const sealAdminKey = ref(false);
        // 获取客户关联的印章管理员选项：
        const getSealAdminDict = async (row) => {
            const { customerName, stampSubject } = row;
            if (!customerName || +stampSubject !== 1 || +props.detailData.stampMode !== 2) { return false; }
            // 根据legalEntityName(客户名称) 获取会计主体实体Id legalSysId
            const res = await getSrmCompanyInfosApi({ legalEntityName: customerName });
            if (+res.code === 0 && res.data?.list?.length) {
                const legalSysId = res.data.list[0].legalSysId;
                const inputObj = {
                    type: props.detailData.sealCategory, // 印章类型
                    belongs: legalSysId ? [String(legalSysId)] : [], // ['1722294'] 有数据
                    sealDepartmentNumber: '30003357' // 固定安得部门
                };
                // 获取印章管理员列表
                const resAdmin = await getSealAdminList(inputObj);
                if (+resAdmin?.code === 0 && resAdmin?.data?.length) {
                    const { sealList } = resAdmin.data[0];
                    const sealAdminDict = {};
                    sealList.forEach((item) => {
                        sealAdminDict[item.sealAdminAccount] = item.sealAdminName;
                    });
                    row.sealAdminDict = sealAdminDict;
                }
            }
            sealAdminKey.value = !sealAdminKey.value;
        };
        watch(
            () => [props.detailPageState, props.detailData.contractStatus, props.detailData.contractChangeStatus, props.activeName, props.detailData.contractCode, props.detailData.contractCharacter],
            // eslint-disable-next-line complexity
            (newVal) => {
                if (newVal[3] === 'customerDetail') {
                    const { userCode } = user;
                    filesBasicParams.contractCode = newVal[4];
                    filesBasicParams.userCode = userCode;
                    filesBasicParams.contractCharacter = newVal[5] + '';
                    if (newVal[0] === STATE_NEW) {
                        isDisableBtn.value = false;
                    } else if (newVal[0] === STATE_OTHER_CHANGE) {
                        isDisableBtn.value = (newVal[2] + '' === 'undefined' || newVal[2] + '' !== '1') || ([1, 2].includes(+props.detailData.changeType)); // 价格信息、日期信息变更只读
                    } else {
                        isDisableBtn.value = !(newVal[1] + '' === '0' || newVal[1] + '' === '1'); // contractStatus
                    }
                    if (newVal[4]) {
                        if ((newVal[0] !== STATE_NEW) && (newVal[0] !== STATE_OTHER_CHANGE)) {
                            subTabTable.getList();
                        } else if (newVal[0] === STATE_OTHER_CHANGE && props.detailData.contractChangeCode) {
                            subTabTable.getList();
                        }
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        // 客户明细表导出
        // const detailExportLoading = ref(false);
        const handleExport = async(submit) => {
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const params = {
                contractCode: props.detailData.contractCode
            };
            submit(params);
        };

        // 排序
        const handleSort = ({ prop, order }) => {
            orderBy.value = order ? prop.replace(/([A-Z])/g, '_$1').toLowerCase() : '';
            orderByType.value = orderLike[order] || '';
            subTabTable.getList();
        };
        return {
            config,
            staticConfig,
            subTabTable,
            addDialog,
            hasFilesType,
            uploadOrHangdleForm,
            fileList,
            filesForm,
            btnLoading,
            getAdvanceValue,
            handleAddRow,
            handleImport,
            filesTypeClear,
            fileTypeChange,
            upload,
            uploadClose,
            closeLog,
            handleSelectedRow,
            handleChangeRow,
            handleSaveRows,
            handleDeleteRows,
            isDisableBtn,
            reqHeaders,
            downLoad,
            onChange,
            saveLoading,
            disableToImport,
            uploadFileCheck,
            uploadFileImport,
            searchConfig,
            handleSearch,
            authorityCode,
            STATE_OTHER_CHANGE,
            newColumns,
            columnKey,
            sealAdminKey,
            handleExport,
            handleSort,
            orderByType,
            orderBy
        };
    }
};
</script>

<style lang = "less">
.customer-subtab {
    .file-detail-btn {
        width: 100%;
        display: flex;
        align-items: center;
        .sub-tab-search-box {
            flex: 1;
            height: 28px;
            margin: 0 0 0 30px;
            .search-box {
                &>div>button.search-btn {
                    height: 24px;
                    min-height: 24px;
                    padding: 2px 4px;
                    margin-top: 2px;
                    /* background-color: #4285F5;
                    border-color: #4285F5; */
                }
                .search {
                    .el-input-group__append, .el-input-group__prepend {
                        padding: 0 14px;
                    }
                }
            }
        }
    }
    .upload-btn {
        display: flex;
        padding: 0px 0px 15px 80px;
    }
    .upload-demo {
        margin-left: 10px;
    }
}

</style>
