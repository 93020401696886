import _ from 'lodash';
export const config = {
    name: 'contractManage',
    searchFields: [
        {
            name: '单据编号',
            value: 'contractCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '法务合同编码',
            value: 'mipContractCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '合同状态',
            value: 'contractStatuss',
            isFixed: true,
            optionNum: 'SYS_CR_CONTRACT_STATUS',
            type: 'select',
            multiple: true,
            span: 4
        },
        {
            name: '合同来源',
            value: 'contractSource',
            type: 'select',
            optionNum: 'CONTRACT_SOURCE',
            span: 3
        },
        {
            name: '创建人',
            value: 'createUserCode',
            type: 'advanceUser',
            span: 3
        },
        {
            name: '业务类型',
            value: 'businessTypeList',
            multiple: true,
            optionNum: 'SYS_BMS_BUSI_TYPE',
            type: 'select',
            isFixed: true,
            span: 4
        },
        {
            name: '客户名称',
            value: 'customerName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系名称' }
            ],
            tableConfig: [
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerGroupName', value: '客户系名称' }
            ],
            prefix: '/api-lcrm',
            method: 'post',
            advanceUrl: '/customer/info/page',
            cbParams: ['customerCode', 'customerName#customerName'],
            advanceCode: 'advanceCode',
            beforeRequest: (params) => {
                params.customerStatus = '1';
                return params;
            }
        },
        {
            name: '分公司',
            value: 'companyName',
            type: 'advance',
            span: 4,
            rule: [
                { required: true, message: '分公司不能为空', trigger: 'blur' }
            ],
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode#companyCode', 'companyNameCn#companyName'],
            isFixed: true
        },
        {
            name: '服务平台',
            value: 'siteName',
            type: 'advance',
            span: 4,
            rule: [
                { required: true, message: '服务平台不能为空', trigger: 'blur' }
            ],
            advanceConfig: [
                { 'prop': 'siteNameCn', 'value': '服务平台名称' },
                { 'prop': 'siteCode', 'value': '服务平台编码' }
            ],
            tableConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'prop': 'siteNameCn', 'value': '服务平台名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popSite`,
            cbParams: ['siteCode', 'siteNameCn#siteName'],
            advanceCascade: 'companyCode#companyCode',
            isFixed: true
        },
        {
            name: '合同性质',
            value: 'contractCharacter',
            optionNum: 'SYS_WM_CONTRACT_CHARACTER',
            type: 'select',
            span: 3,
            isFixed: true
        },
        {
            name: '项目编码',
            value: 'projectCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            span: 5
        },
        {
            name: '生效日期',
            value: 'validTime',
            type: 'time',
            startPlaceholder: '生效日期从',
            endPlaceholder: '生效日期到',
            isFixed: true,
            span: 4
        },
        {
            name: '失效日期',
            value: 'failTime',
            type: 'time',
            startPlaceholder: '失效日期从',
            endPlaceholder: '失效日期到',
            span: 4
        },
        {
            name: '业务大类',
            value: 'bigBusinessType',
            type: 'select',
            optionNum: 'SYS_LMDM_BUS_TYPE',
            span: 4
        },
        {
            name: '内外部业务',
            value: 'oilIsGroupCustomer',
            type: 'select',
            optionNum: 'SYS_WM_IN_OR_OUT_CUSTOMER',
            span: 4
        },
        {
            name: '会计主体',
            value: 'accountEntityName',
            type: 'advance',
            span: 4,
            advanceConfig: [
                { 'prop': 'operatingUnitName', 'value': '会计主体名称' },
                { 'prop': 'operatingUnitId', 'value': '会计主体编码' }
            ],
            tableConfig: [
                { 'prop': 'operatingUnitId', 'value': '会计主体编码' },
                { 'prop': 'operatingUnitName', 'value': '会计主体名称' }
            ],
            prefix: '/api-lcrm',
            // method: 'post',
            advanceUrl: `/efOrganRelations`,
            cbParams: ['operatingUnitId#accountEntityId', 'operatingUnitName#accountEntityName'],
            advanceCode: 'advanceCode',
            beforeRequest: (params) => {
                if (params.operatingUnitName) {
                    return params;
                }
                const inputKey = params.operatingUnitId?.trim();
                const reg = new RegExp(/^-?\d+$/); // 数字正则
                if (!reg.test(inputKey)) {
                    params.operatingUnitName = inputKey;
                    params.operatingUnitId = null;
                } else {
                    params.operatingUnitId = inputKey;
                    params.operatingUnitName = null;
                }
                return params;
            },
            afterResponse: (res) => {
                if (res && res.data && Array.isArray(res.data.list)) {
                    res.data.list = res.data.list.filter(item => !_.isNil(item));
                }
                return _.cloneDeep(res);
            }
        },
        {
            name: '开发单位',
            value: 'developmentUnitName',
            type: 'advance',
            span: 4,
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '开发单位名称' },
                { 'prop': 'companyCode', 'value': '开发单位编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '开发单位编码' },
                { 'prop': 'companyNameCn', 'value': '开发单位名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode#developmentUnit', 'companyNameCn#developmentUnitName']
        },
        {
            name: '主要开发人',
            value: 'salesStaffName',
            type: 'advance',
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#salesStaffName', 'esusLoginName#salesStaffCode']
        },
        {
            name: '合同承接人',
            value: 'receiverName',
            type: 'advance',
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#receiverName', 'esusLoginName#receiverCode']
        },
        {
            name: '签单情况',
            value: 'isNew',
            type: 'select',
            optionNum: 'SYS_LMDM_SIGNING_SITUATION',
            span: 4
        },
        {
            name: '是否油价联动',
            value: 'oilLinkedProportion',
            type: 'select',
            optionNum: 'SRM_YES_NO'
        },
        {
            name: '合同名称',
            value: 'contractName',
            type: 'input',
            span: 3
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '单据编号',
                minWidth: 160,
                prop: 'contractCode',
                sortable: true
            },
            {
                label: '法务合同编号',
                prop: 'mipContractCode',
                minWidth: 160,
                sortable: true
            },
            {
                label: '合同名称',
                prop: 'contractName',
                minWidth: 160
            },
            {
                label: '合同状态',
                type: 'select',
                optionsKey: 'SYS_CR_CONTRACT_STATUS',
                prop: 'contractStatus'
            },
            {
                label: '客户编码',
                prop: 'customerCodes',
                minWidth: 150
            },
            {
                label: '客户名称',
                prop: 'customerNames',
                minWidth: 150
            },
            {
                label: '合同性质',
                type: 'select',
                optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
                prop: 'contractCharacter'
            },
            {
                label: '分公司名称',
                prop: 'companyNames',
                minWidth: 150
            },
            {
                label: '服务平台名称',
                prop: 'siteNames',
                minWidth: 120
            },
            {
                label: '业务类型',
                prop: 'businessType',
                type: 'custom',
                minWidth: 100
            },
            {
                label: '业务大类',
                prop: 'bigBusinessType',
                type: 'select',
                optionsKey: 'SYS_LMDM_BUS_TYPE'
            },
            {
                label: '项目编码',
                prop: 'projectCode',
                minWidth: 120
            },
            {
                label: '生效日期',
                prop: 'validTime',
                sortable: true,
                minWidth: 154
            },
            {
                label: '失效日期',
                prop: 'failTime',
                sortable: true,
                minWidth: 154
            },
            {
                label: '内外部业务',
                type: 'select',
                optionsKey: 'SYS_WM_IN_OR_OUT_CUSTOMER',
                prop: 'oilIsGroupCustomer',
                minWidth: 100
            },
            {
                label: '会计主体',
                prop: 'accountEntityName',
                minWidth: 216
            },
            {
                label: '开发单位',
                prop: 'developmentUnitName',
                minWidth: 120
            },
            {
                label: '主要开发人',
                prop: 'salesStaffName',
                minWidth: 100
            },
            {
                label: '服务经理',
                prop: 'serviceManagerName'
            },
            {
                label: '是否油价联动',
                prop: 'oilLinkedProportion',
                type: 'select',
                optionsKey: 'SRM_YES_NO',
                minWidth: 100
            },
            {
                label: '签单情况',
                type: 'select',
                prop: 'isNew',
                optionsKey: 'SYS_LMDM_SIGNING_SITUATION'
            },
            // {
            //     label: '统计时间',
            //     prop: 'statisticalTime'
            // },
            {
                label: '流程提交日期',
                prop: 'processSubmitDate',
                sortable: true,
                minWidth: 154
            },
            {
                label: '审批状态',
                prop: 'mipStatus',
                type: 'select',
                optionsKey: 'SYS_SU_MIP_AUDIT_STATUS'
            },
            {
                label: '合同承接人',
                prop: 'receiverName',
                width: 150
            },
            {
                label: '创建人',
                prop: 'createUserName'
            },
            {
                label: '创建时间',
                prop: 'createTime',
                sortable: true,
                minWidth: 154
            },
            {
                label: '修改人',
                prop: 'updateUserName'
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                sortable: true,
                minWidth: 154
            },
            {
                label: '盖章方式',
                prop: 'stampMode',
                type: 'select',
                optionsKey: 'SYS_CONTRACT_STAMP_MODE'
            },
            // {
            //     // TODO
            //     label: '客户系',
            //     prop: 'adsfadf'
            // },
            // {
            //     // TODO
            //     label: '客户编码状态',
            //     prop: 'dfgdfhd',
            //     minWidth: 100
            // },
            {
                label: '主单据编号',
                prop: 'mainContract',
                minWidth: 100
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6
};
