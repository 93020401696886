import configurl from '@/config/user.env';
export const importConfig = {
    dialogImportConfig: {
        ImportConfig: {
            baseUrl: configurl.apiUrl + '/api-lcrm/contractCustomerInfo/excel',
            action: configurl.apiUrl + '/api-lcrm/contractCustomerInfo/excel',
            multiple: false,
            accept: 'pdf/DOC/DOCX/EXCEL',
            showFileList: true,
            fileType: '只能上传pdf、doc、docx、excel文件。',
            manualUpload: false
        }
    },
    formConfig: {
        rules: {
            accessoryName: [
                { required: true, message: '请输入附件名称', trigger: 'change' }
            ],
            fileType: [
                { required: true, message: '请选择文件类型', trigger: 'change' }
            ]
        },
        templateAdvance: {
            name: '模板名称',
            value: 'templateName',
            advanceLabel: '模板弹出框',
            advanceConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' }
            ],
            tableConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' },
                { prop: 'templateType', value: '模板类型' },
                { prop: 'templateUrl', value: '预览url' }
            ],
            prefix: 'api-lcrm/',
            advanceUrl: 'contractEnclosureInfo/getTemplate',
            cbParams: ['templateCode#templateCode', 'templateName#templateName', 'templateId#templateId', 'attachmentId#attachmentId', 'draftModel#draftModel']
        }
    }
};
export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '费用名称',
            prop: 'feeCode',
            type: 'select',
            optionsKey: 'SYS_CRM_CONTRACT_PRICE_FEE',
            mustFill: true
        },
        {
            label: '服务平台',
            prop: 'siteName',
            type: 'advance',
            value: 'siteName',
            mustFill: true,
            placeholder: '',
            name: '服务平台',
            advanceLabel: '合同关联服务平台',
            advanceConfig: [
                { 'prop': 'siteName', 'value': '服务平台名称' },
                { 'prop': 'siteCode', 'value': '服务平台编码' }
            ],
            tableConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'displayName': 'siteName', 'prop': 'siteName', 'value': '服务平台名称' }
                // { 'prop': 'companyCode', 'value': '分公司编码' },
                // { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
            ],
            prefix: '/api-lcrm',
            // method: 'post',
            multiple: true, // 多选
            advanceUrl: `/contract/site/info/page`,
            cbParams: ['siteCode#siteCode', 'siteName'],
            advanceCascade: 'contractCode#contractCode'
        },
        {
            label: '逻辑仓',
            prop: 'whName',
            mustFill: true,
            type: 'advance',
            name: '逻辑仓',
            value: 'whName',
            width: 200,
            advanceConfig: [
                { prop: 'name', value: '仓库名称' },
                { prop: 'code', value: '仓库编码' }
            ],
            tableConfig: [
                { prop: 'name', value: '仓库名称' },
                { prop: 'code', value: '仓库编码' }
            ],
            prefix: '/api-lcrm/',
            // 请求地址
            advanceUrl: 'warehouse/search', // *请求地址
            cbParams: ['name#whName', 'code#whCode'],
            advanceCascade: 'siteCode#siteCode'
        },
        // {
        //     label: '逻辑仓',
        //     type: 'slot',
        //     prop: 'whCode',
        //     editSlot: true,
        //     mustFill: true
        // },
        {
            label: '数据来源',
            prop: 'calculateType',
            type: 'select',
            optionsKey: 'bcp_storage_charge_way'
            // mustFill: true
        },
        {
            label: '单据类型',
            prop: 'orderTypeName'
        },
        {
            label: '计费模板名称',
            prop: 'templateName',
            mustFill: false,
            type: 'advance',
            name: '计费模板名称',
            value: 'templateName',
            width: 200,
            // advanceConfig配置搜索条件，tableConfig配置弹窗中的表格列信息
            advanceConfig: [
                { prop: 'templateName', value: '模板名称' },
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'feeName', value: '费用名称' }
            ],
            tableConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' },
                { prop: 'feeName', value: '费用名称' },
                { prop: 'description', value: '描述' },
                { prop: 'businessTypeName', value: '业务类型' },
                { prop: 'calculateType', value: '计费模式' },
                { prop: 'collectUnitName', value: '汇总条件' }
            ],
            prefix: '/api-lcrm/',
            method: 'post',
            // 请求地址
            advanceUrl: 'contract/price/column/template', // *请求地址
            cbParams: [
                'templateCode', 'templateName', 'orderTypeName', 'orderTypeCode', 'description'
            ]
        },
        {
            label: '面积',
            prop: 'areaQuantity',
            type: 'input',
            mustFill: true
        },
        {
            label: '单价类型',
            prop: 'calculateUnit',
            type: 'select',
            defaultValue: '0',
            optionsKey: 'SYS_CRM_CONTRACT_PRICE_UNIT'
            // mustFill: true,
            // disabled: true
        },
        {
            label: '单价',
            prop: 'price',
            type: 'input'
            // mustFill: true,
            // disabled: true
        },
        {
            label: '仓库生效日期',
            prop: 'whEffectiveDate',
            type: 'date',
            mustFill: true
        },
        {
            label: '仓库失效日期',
            prop: 'whExpirationDate',
            type: 'date',
            mustFill: true
        }
    ]
};
