<!-- eslint-disable max-lines -->
<template>
    <div class="contract-detail contract-view"
        :class="{'otherChange-detail': detailPageState === 'otherChange' && [1, 2].includes(+detailDataLocal.changeType)}"
        v-loading="detailLoading">
        <!-- 按钮区域 -->
        <div class="detail-toolBar">
            <el-tooltip
                class="item-flow-right"
                effect="dark"
                content="刷新"
                placement="bottom">
                <lots-button
                size="mini"
                icon="el-icon-refresh"
                v-show="code !== '0000'"
                @click="refleshPage"
                :loading="detailLoading"></lots-button>
            </el-tooltip>
            <lots-button
                v-if="detailPageState === 'otherChange' ?
                (['2', '3', '4', '5'].includes(String(detailDataLocal.contractChangeStatus)) && +detailDataLocal.changeType !== 0)
                : (detailDataLocal.contractStatus && +detailDataLocal.contractStatus !== 0)"
                class="item-flow-right view-flow"
                size="mini"
                type='primary'
                :loading="viewFlowLoading"
                @click="handleViewFlow">流程信息</lots-button>
        </div>
        <!-- 表单信息组件 -->
        <collapse-infos
            ref="upholdHealder"
            :contractCode="detailDataLocal.contractCode"
            :detailData="detailDataLocal"
            :collapse="COLLAPSE"
            :detailPageState="detailPageState"
            @change="handleInfosChange">
        </collapse-infos>
        <!-- 子页签组件 -->
        <el-collapse
            class="contract-collapse detail-more-collapse"
            value="moreInfo"
            @change="handleClickMoreInfo">
            <el-collapse-item title="更多信息" name="moreInfo" ref="tabs">
                <el-tabs v-model="activeName" @tab-click="handleClickTab">
                    <el-tab-pane label="客户明细" name="customerDetail" v-if="subTabShow.coustomer">
                        <customer-detail
                            ref="customerDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal"
                            :readonly="true">
                        </customer-detail>
                    </el-tab-pane>
                    <el-tab-pane label="分公司明细" name="companyDetail" v-if="subTabShow.company">
                        <company-detail
                            ref="companyDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :readonly="true">
                        </company-detail>
                    </el-tab-pane>
                    <el-tab-pane label="油价信息" name="oilDetail" v-if="subTabShow.oilDetail">
                        <oil-detail
                            ref="oilDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal"
                            :readonly="true">
                        </oil-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="业务税率"
                        name="rateDetail"
                        v-if="subTabShow.rate">
                        <rate-detail
                            ref="rateDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal"
                            :readonly="true">
                        </rate-detail>
                    </el-tab-pane>
                    <el-tab-pane label="预签明细" name="prescreenDetail" v-if="subTabShow.prescreen">
                        <prescreen-detail
                            ref="prescreenDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal"
                            :readonly="true">
                        </prescreen-detail>
                    </el-tab-pane>
                    <el-tab-pane label="关联合同明细" name="relationDetail" v-if="subTabShow.relationDetail">
                        <relation-detail
                            ref="relationDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            @subTabDbClick="subTabDbClick"
                            :readonly="true">
                        </relation-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="仓库信息"
                        name="warehouseDetail"
                        v-if="subTabShow.warehouseDetail">
                        <warehouse-detail
                            ref="warehouseDetailRef"
                            :contractCode="detailData.contractCode"
                            :contractStatus="String(detailDataLocal.contractStatus)"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :readonly="true">
                        </warehouse-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="其他变更"
                        name="otherChangeDetail"
                        v-if="subTabShow.otherChange">
                        <other-change-detail
                            ref="otherChangeDetailRef"
                            :activeName="activeName"
                            :contractCode="detailData.contractCode"
                            :detailPageState="detailPageState"
                            @otherChangeDetail="otherChange"
                            :readonly="true">
                        </other-change-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="价格表"
                        name="priceDetail"
                        v-if="subTabShow.price">
                        <price-detail
                            ref="priceDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :readonly="true">
                        </price-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="流程信息"
                        name="iflowDetail"
                        v-if="subTabShow.iflow">
                        <iflow-detail
                            ref="iflowDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            @getNewIflowList="getNewIflowEmit">
                        </iflow-detail>
                    </el-tab-pane>
                </el-tabs>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import { collapse } from './collapseInfosConfig.js';
import CustomerDetail from '@/modules/mdm/views/contractModules/components/customerDetail/Index.vue';
import CompanyDetail from '@/modules/mdm/views/contractModules/components/companyDetail/Index.vue';
import PrescreenDetail from '@/modules/mdm/views/contractModules/components/prescreenDetail/Index.vue';
import RelationDetail from '@/modules/mdm/views/contractModules/components/relationDetails/Index.vue';
import OilDetail from '@/modules/mdm/views/contractModules/components/oilDetail/Index.vue';
import RateDetail from '@/modules/mdm/views/contractModules/components/rateDetail/Index.vue';
import IflowDetail from '@/modules/mdm/views/contractModules/components/iflowDetail/Index.vue';
import OtherChangeDetail from '@/modules/mdm/views/contractModules/components/otherChangeDetail/Index.vue';
import warehouseDetail from '@/modules/mdm/views/contractModules/components/warehouseDetail/Index.vue';
import priceDetail from '@/modules/mdm/views/contractModules/components/priceDetail/Index.vue';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { STATE_NEW, STATE_MAIN, STATE_OTHER_CHANGE, STATE_REPLENISH, STATE_STOP } from '@/modules/mdm/constant/contractManage.js';
import { getContractInfoByIdApi, contractChangeDetailApi } from '@mdm/api/contractManage/detailMainApi.js';
import { getByContractCodeApi } from '@mdm/api/contractManage/contractListApi.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import _ from 'lodash';
import { isOtherChangeShow, isOtherChangeHide } from './config.js';
import bigDataLog from '@/utils/bigDataLog.js';
import utils from '@/components/utils/common.js';
import store from '@/store/index';
import { parseTime } from '@/utils/index.js';
const { MAGIC_NUMBER } = utils;
const contractSourceForJDSK = '6'; // 合同来源京东数科
export default {
    name: '',
    components: {
        lotsButton,
        CollapseInfos,
        CustomerDetail,
        CompanyDetail,
        PrescreenDetail,
        RelationDetail,
        OilDetail,
        RateDetail,
        IflowDetail,
        OtherChangeDetail,
        warehouseDetail,
        priceDetail
    },
    props: {
        code: { // 页面识别码（可能是contractCode 或者 contractChangeCode: 当页面是其他变更并且有变更编号时）
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            // 是否跳转变更页传递了个页面状态过来
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            detailLoading: false,
            priceLoading: false,
            activeName: 'customerDetail',
            collapse: _.cloneDeep(collapse),
            detailDataLocal: _.cloneDeep(this.formatData(this.detailData)),
            authorityCode: btnAuthority.contractManage,
            isHandleEdit: false,
            // projectDisabled: false,
            viewFlowUrl: '',
            viewFlowLoading: false,
            iflowList: [],
            fdId: ''
        };
    },
    watch: {
        detailData: {
            handler(val) {
                this.refleshPage();
            },
            deep: true
        }
    },
    computed: {
        saveHeaderFlag() {
            if (this.detailPageState === STATE_OTHER_CHANGE) {
                if (this.detailDataLocal.contractChangeCode) {
                    return +this.detailDataLocal.changeType === 2
                        ? [1, 5].includes(+this.detailDataLocal.contractChangeStatus) // 日期变更：状态为新建、待提交时可保存
                        : [1].includes(+this.detailDataLocal.contractChangeStatus); // 基础信息、价格信息变更：状态为新建时可保存
                } else {
                    return true;
                }
            } else {
                return [0, 1].includes(+this.detailDataLocal.contractStatus);
            }
        },
        // eslint-disable-next-line complexity
        subTabShow() {
            return {
                fileDetail: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) // 其他变更：基础信息变更不展示
                    : ![STATE_NEW].includes(this.detailPageState), // 新增：不展示
                coustomer: this.detailPageState === STATE_OTHER_CHANGE ? true // 其他变更:展示
                    : ![STATE_NEW].includes(this.detailPageState), // 新增：不展示
                company: true,
                prescreen: this.detailPageState === STATE_OTHER_CHANGE ? true // 其他变更:展示
                    : ![STATE_NEW].includes(this.detailPageState) && String(this.detailDataLocal.contractCharacter) !== '4', // 新签、预签不展示
                relationDetail: [STATE_MAIN].includes(this.detailPageState),
                oilDetail: this.detailPageState === STATE_OTHER_CHANGE ? String(this.detailDataLocal.oilLinkedProportion) !== '0' // 其他变更: 油价联动为否不展示
                    : String(this.detailDataLocal.oilLinkedProportion) !== '0' && ![STATE_NEW].includes(this.detailPageState), // 新签、油价联动为否不展示
                rate: ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState),
                otherChange: ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState),
                iflow: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) : ![STATE_NEW].includes(this.detailPageState),
                warehouseDetail: false,
                price: store.getters.btnPermissions.includes('1200000001')
                    && (this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) : ![STATE_NEW].includes(this.detailPageState))
            };
        },
        // eslint-disable-next-line
        COLLAPSE() { // 配置项动态计算
            const {
                contractCharacter, stampMode, oilLinkedProportion, freightFloatMode, settlementMode, ifWatermarking,
                paymentCollection, oilIsGroupCustomer, businessType, accountEntityName, contractSource, changeType
            } = this.detailDataLocal;
            // eslint-disable-next-line max-lines-per-function
            this.collapse.forEach((v, i) => {
                // eslint-disable-next-line complexity, max-lines-per-function
                v.formList.forEach((v2, i2) => {
                    // 基础信息
                    if (isOtherChangeShow.includes(v2.prop)) {
                        v2.visible = this.detailPageState === STATE_OTHER_CHANGE;
                    }
                    if (isOtherChangeHide.includes(v2.prop)) {
                        v2.visible = this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    // if (v2.prop === 'contractCharacter') { // 新增合同，合同性质可编辑
                    //     v2['disabled'] = this.detailPageState !== STATE_NEW;
                    // }
                    if (v2.prop === 'mainContract') { // 主合同编号: 固定禁用；合同性质为续签、补充、终止是展示;
                        // v2.rule[0].required = ['2'].includes(String(contractCharacter));
                        v2.visible = ['2', '3', '5'].includes(String(contractCharacter));
                    }
                    if (v2.prop === 'accountEntityName') { // 补充/终止协议/其他变更，禁用会计主体
                        v2.disabled = [STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE].includes(this.detailPageState) || (this.detailPageState === STATE_NEW && String(contractCharacter) === '3');
                        // v2.type = v2.disabled ? 'custom' : 'advance';
                    }
                    if (['validTime', 'failTime'].includes(v2.prop) && this.detailPageState === STATE_OTHER_CHANGE) {
                        // 当为其他变更时，合同性质为预签4, 且基础变更时，生效日期、失效日期可编辑, 或变更类型为日期变更时可编辑，其他情况只读
                        v2.disabled = String(changeType) === '0' ? String(contractCharacter) !== '4' : String(changeType) !== '2';
                        // v2.type = v2.disabled ? 'custom' : 'time';
                    }
                    if (v2.prop === 'projectCode') {
                        const isJDSK = +contractSource === +contractSourceForJDSK;
                        v2.rule[0].required = String(oilIsGroupCustomer) === '1' && ![STATE_OTHER_CHANGE, STATE_STOP].includes(this.detailPageState) && !isJDSK; // 项目编码：内外部业务为外部1 且为 非变更合同/终止协议 时，必填;
                        // CRM 且 新签、续签、预签、非变更可编辑
                        v2.disabled = !(['1', '2', '4'].includes(String(contractCharacter)) && this.detailPageState !== STATE_OTHER_CHANGE && String(contractSource) === '1');
                        // v2.type = v2.disabled ? 'custom' : 'advance';
                    }
                    if (v2.prop === 'customerType') { // 仓储运作类型：业务类别包含‘仓储’才展示
                        const businessTypeList = businessType || [];
                        let hasWarehouse = false;
                        if (businessTypeList.length) {
                            businessTypeList.forEach(item => {
                                if (item === '1') hasWarehouse = true;
                            });
                        }
                        v2.visible = hasWarehouse;
                    }
                    if (v2.prop === 'priceType') { // 价格情况：合同性质不为预签 且不为其他变更才展示
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'stampMode') { // 盖章方式：合同性质不为预签 且不为其他变更才展示
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'sealCategory') { // 印章类型：会计主体有数据，才可编辑; 合同性质不为预签，不为其他变更，盖章方式不为电子 才展示
                        v2.disabled = !accountEntityName;
                        // v2.type = v2.disabled ? 'custom' : 'select';
                        v2.placeholder = accountEntityName ? '请选择' : '请先选择会计主体';
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(stampMode) !== '1';
                    }
                    if (v2.prop === 'sealAdminAccount') { // 印章管理员展示：合同性质不为预签，不为其他变更，盖章方式不为电子
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(stampMode) !== '1';
                    }
                    if (v2.prop === 'ifWatermarking') { // 是否生成水印: 合同性质不为预签，不为其他变更
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'noWatermarkingReason') { // 不生成水印原因: 合同性质不为预签，不为其他变更, 并且是否生成水印为否
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(ifWatermarking) === '0';
                    }
                    if (v2.prop === 'isCloudWh') { // 是否云仓: 当内外部业务为内部0时必填，外部时不必填且隐藏
                        v2.visible = String(oilIsGroupCustomer) === '0';
                    }
                    if (v2.prop === 'professionalCompany') { // 专业公司: 当合同来源为非CRM时只读, 内外部业务为内部0 时隐藏
                        v2.disabled = String(contractSource) !== '1';
                        // v2.type = v2.disabled ? 'custom' : 'select';
                        v2.visible = String(oilIsGroupCustomer) !== '0';
                    }
                    if (v2.prop === 'productType') { // 产品类型：来源系统非CRM, 或合同状态为有效、失效时只读
                        v2.disabled = String(contractSource) !== '1' || [2, 5].includes(+this.detailDataLocal.contractStatus);
                        // v2.type = v2.disabled ? 'custom' : 'select';
                        v2.rule[0].required = !v2.disabled;
                    }
                    if (v2.prop === 'businessType') { // 业务类别：日期变更，价格变更只读
                        v2.disabled = this.detailPageState === STATE_OTHER_CHANGE && ['1', '2'].includes(String(changeType));
                        // v2.type = v2.disabled ? 'custom' : 'select';
                    }
                    if (v2.prop === 'archiveCode' || v2.prop === 'receivedCopies') {
                        v2.visible = +this.detailData.stampMode === 2;
                    }
                    // 财务信息
                    const NotCRM = [ 'contractAccountOf', 'grossMarginPlan', 'operatGrossMarginPlan', 'deposit', 'settlementMode', 'acceptanceRate', 'acceptanceDays' ];
                    if (NotCRM.includes(v2.prop)) { // 合同全账期中标采购毛利率/中标项目经营中心利润率/押金/结算方式/承兑比例/承兑期限：来源系统非CRM只读
                        v2.disabled = String(contractSource) !== '1';
                        // v2.type = v2.disabled ? 'custom' : (v2.prop === 'settlementMode' ? 'select' : 'input');
                    }
                    if (v2.prop === 'expectedTrafficPlan') { // 预计业务量：来源系统非CRM 且不为终止协议，只读
                        v2.disabled = String(contractSource) !== '1' && String(contractCharacter) !== '5';
                        // v2.type = v2.disabled ? 'custom' : 'input';
                    }
                    if (v2.prop === 'acceptanceRate') { // 承兑比例：结算方式选择 现汇+承兑3 或承兑2 时，必填; 来源系统非CRM只读
                        v2.rule[0].required = ['2', '3'].includes(String(settlementMode));
                    }
                    if (v2.prop === 'acceptanceDays') { // 承兑期限: 现汇+承兑3 或承兑2 时，必填; 来源系统非CRM只读
                        v2.rule[0].required = ['2', '3'].includes(String(settlementMode));
                    }
                    if (['remittanceDate', 'receiveTo', 'feeRate', 'feePaymentMode', 'minFeeAmount'].includes(v2.prop)) { // 汇款时间/收款至/服务费率(%)/服务费支付方式: 是否代收货款 为 是时必填
                        v2.rule[0].required = String(paymentCollection) === '1';
                    }
                    if (v2.prop === 'remittanceDate') { // 汇款时间:
                        v2.visible = v2.rule[0].required;
                    }
                    if (v2.prop === 'minFeeAmount') { // 最低服务费:
                        v2.visible = v2.rule[0].required;
                    }
                    // 油价信息
                    if (v2.prop === 'oilLinkedProportion') { // 预签和终止协议禁用油价联动，默认为否;
                        v2.disabled = ['4', '5'].includes(String(contractCharacter));
                        // v2.type = v2.disabled ? 'custom' : 'select';
                    }
                    const isShowoilLinkedArr = [ // 是否油价联动字段直接控制 显示/隐藏的字段
                        'linkageType', 'triggerOilPriceFloat', 'freightFloatMode', 'oilLinkageExecutime',
                        'newOilSelectionMethod', 'triggerPriceAgain', 'conditionsDetail'
                    ];
                    if (isShowoilLinkedArr.includes(v2.prop)) {
                        v2.visible = String(oilLinkedProportion) !== '0';
                    }
                    if (v2.prop === 'freightLinkedScale') { // 运价联动比例(%)： 运价浮动方式 为 联动比例2 展示
                        v2.visible = String(freightFloatMode) === '2' && String(oilLinkedProportion) !== '0';
                    }
                    if (v2.prop === 'freightFloatCoefficient') { // 运价浮动系数(%)： 运价浮动方式 为 浮动系数1 展示
                        v2.visible = String(freightFloatMode) === '1' && String(oilLinkedProportion) !== '0';
                    }
                    // 增量10.21版本: 其他变更：日期变更2/价格变更1其余字段只读字段
                    if (['1', '2'].includes(String(changeType))) {
                        if (!['validTime', 'failTime', 'changeReason', 'changeDetail'].includes(v2.prop)) {
                            v2.disabled = true;
                            // v2.type = 'custom';
                        }
                        if (v2.rule) {
                            v2.rule[0].required = !v2.disabled;
                        }
                    }
                });
            });
            return this.collapse;
        }
    },
    mounted() {
        bigDataLog.sendDetailMessageByKey('bigData-page-detailPage');
        this.refleshPage();
        if (this.detailPageState === STATE_NEW) {
            this.activeName = 'companyDetail';
        }
    },
    methods: {
        formatData(data) { // 服务类型字符串数组转换函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            data.validTime && (data.validTime = utils.formatDate(data.validTime));
            data.failTime && (data.failTime = utils.formatDate(data.failTime));
            data.cutOffTime && (data.cutOffTime = parseTime(data.cutOffTime, 'hh:mm:ss'));
            return data;
        },
        async refleshPage() {
            this.isHandleEdit = false;
            if (this.detailPageState !== STATE_OTHER_CHANGE && this.detailData.id) {
                // 请求详情数据
                this.detailLoading = true;
                getContractInfoByIdApi({ id: this.detailData.id }).then(res => {
                    if (+res.code === 0 && res.data) {
                        this.dataInit(res.data);
                    }
                }).finally(() => {
                    this.detailLoading = false;
                });
            } else if (this.detailPageState === STATE_OTHER_CHANGE && this.detailData.contractChangeCode) {
                this.detailLoading = true;
                Promise.all([contractChangeDetailApi({ id: this.detailData.id }), getByContractCodeApi({ contractCode: this.detailData.contractCode })])
                    .then(resArr => {
                        if (+resArr[0].code === 0 && +resArr[1].code === 0) {
                            this.dataInit({ ...resArr[1].data, ...resArr[0].data });
                        }
                    }).finally(() => {
                        this.detailLoading = false;
                    });
            }
        },
        dataInit(data) {
            const newData = this.formatData(data);
            if ([STATE_OTHER_CHANGE, STATE_REPLENISH, STATE_STOP].includes(this.detailPageState)) {
                if (this.detailPageState === STATE_OTHER_CHANGE && +data.changeType !== 0) {
                    this.activeName = ['2', '3', '4', '5'].includes(String(data.contractChangeStatus)) ? 'iflowDetail' : (+data.changeType === 1 ? 'priceDetail' : 'companyDetail');
                } else {
                    this.activeName = 'customerDetail';
                }
            }
            // if (newData.projectCode) { // 项目编码后端有值，就禁用编辑
            //     this.projectDisabled = true;
            // }
            if (['4', '5'].includes(String(newData.contractCharacter))) { // 合同性指为预签/终止协议，油价联动固定为否
                newData['oilLinkedProportion'] = '0';
            }
            this.handleInfosChange({ form: newData || {} });
        },
        handleInfosChange(formObjData = {}) { // 表单组件编辑回调，更新本地数据
            this.detailDataLocal = _.cloneDeep(formObjData.form);
            const { itemSetting } = formObjData;
            if (itemSetting) { this.isHandleEdit = true; } // 监控表单是否编辑过
            if (!itemSetting) {
                this.$refs.upholdHealder.refleshPage(this.detailDataLocal); // 刷新表单子组件
            }
        },
        async otherChange(data) { // 其他变更
            const newPageData = data.id ? data : Object.assign({}, this.detailData);
            if (!data.id) { // 非子页签跳转(主合同详情页点击其他变更按钮)
                return false;
            }
            this.$emit('other-change', newPageData.contractCode, newPageData, 'otherChange');
        },
        subTabDbClick(row) {
            this.$emit('subTabDbClick', row);
        },
        refreshAll() {
            const data = JSON.parse(JSON.stringify(this.detailDataLocal));
            this.$emit('refrash-data', data);
        },
        handleClickTab(data) {
            const editTabArr = [ 'filesDetail', 'customerDetail', 'companyDetail',
                'prescreenDetail', 'oilDetail', 'rateDetail' ];
            const readonlyTabArr = ['relationDetail', 'otherChangeDetail', 'iflowDetail'];
            if (editTabArr.includes(data.name)) { // 解决切换编辑表格时操作列错位问题
                this.$nextTick(() => {
                    const tableRef = this.$refs[`${data.name}Ref`].$refs.editTableBox;
                    if (tableRef) {
                        tableRef.$refs.moduleTable.doLayout();
                    }
                });
            }
            if (readonlyTabArr.includes(data.name)) { // 数纯展示表格，刷新数据（只刷新激活状态的）
                this.$refs[`${data.name}Ref`].getDataFn();
            }
        },
        handleClickMoreInfo(data) { // 解决点击折叠/展开“更多信息”折叠筐，子页签编辑错位问题
            if (data.includes('moreInfo')) {
                this.handleClickTab({ name: this.activeName });
            }
        },
        getNewIflowEmit(list) {
            if (list.length) {
                list.forEach(item => {
                    if (+item.mipType !== MAGIC_NUMBER.TWENTY_THREE) { // 排除合同组流程
                        this.fdId = item.mipId || '';
                        this.iflowList = [item];
                    }
                });
            } else {
                this.fdId = '';
                this.iflowList = [];
            }
        },
        // 获取最新iflow流程子页签流程状态数据函数：
        async getNewIflowList(code) {
            return contractWorkflowList({
                contractCode: code,
                pageSize: 10,
                pageNo: 1
            }).then(res => {
                if (+res.code === 0) {
                    if (res.data && res.data.list && res.data.list.length) {
                        // 保存流程状态数据
                        this.fdId = res.data.list[0].mipId;
                        this.iflowList = res.data.list;
                    }
                    return res;
                }
            });
        },
        // eslint-disable-next-line complexity
        async handleViewFlow() {
            const { contractCode, contractName, contractCharacter, contractChangeCode, changeType } = this.detailDataLocal;
            const isFW = (this.detailPageState !== STATE_OTHER_CHANGE && +contractCharacter !== 4) || (this.detailPageState === STATE_OTHER_CHANGE && +changeType === 2);
            const code = this.detailPageState === STATE_OTHER_CHANGE ? contractChangeCode : contractCode;
            if (!this.viewFlowUrl) {
                // 请求流程信息
                this.viewFlowLoading = true;
                const res = await this.getNewIflowList(code);
                this.viewFlowLoading = false;
                if (+res.code === 0 && res.data && res.data.list && res.data.list.length) {
                    if (!isFW
                        // 特殊情况：日期变更（法务流程）时，mip状态为草稿/撤回/审批驳回时, 直接跳转到本系统mipUrl，不跳法务
                        || (this.detailPageState === STATE_OTHER_CHANGE && +changeType === 2 && ['1', '00', '5', '6', '30', '80'].includes(this.iflowList[0].mipStatus + ''))) {
                        this.viewFlowUrl = res.data.list[0].mipUrl;
                    } else {
                        this.viewFlowUrl = res.data.list[0].legalMipUrl;
                    }
                }
            }
            if (this.viewFlowUrl) {
                if (!isFW
                    || (this.detailPageState === STATE_OTHER_CHANGE && +changeType === 2 && ['1', '00', '5', '6', '30', '80'].includes(this.iflowList[0].mipStatus + ''))) {
                    const query = this.detailPageState === STATE_OTHER_CHANGE
                        ? { fdId: this.fdId, fdTemplateKey: changeType === 2 ? '' : 'CRM_contract_price', contractCode: code, changeType }
                        : { fdId: this.fdId, fdTemplateKey: 'LMDM_customer_contract', contractCode: code, contractName, contractCharacter };
                    const routeData = this.$router.resolve({ name: 'mipIframe', query });
                    window.open(routeData.href, '_blank');
                } else {
                    window.open(this.viewFlowUrl, '_blank');
                    // window.open(this.viewFlowUrl, '_self');
                }
            } else {
                this.$message.warning('未查询到流程信息');
            }
        }
    }
};
</script>

<style lang = "less">
.contract-detail {
    .detail-toolBar {
        .contract-detail-btn {
            margin-right: 10px;
        }
        .item-flow-right {
            float: right;
            margin-left: 10px;
        }
  }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .el-tabs__header {
        margin-bottom: 0px;
    }
    .el-tabs__content {
        .file-detail-btn {
            background-color: #fffbf2;
            .el-button {
                padding: 8px 0px;
            }
        }
        .footer {
            padding: 10px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .detail-more-collapse.el-collapse {
        border-top: none;
        border-bottom: none;
    }
    .detail-toolBar {
        .el-loading-spinner {
            top: 0;
            margin-top: 0;
            height: 100%;
            .circular {
                height: 26px;
                width: 26px;
            }
        }
    }
    &.contract-view {
        .el-col.el-col-6.form-item {
            .el-form-item.el-form-item--mini {
                margin-bottom: 2px;
            }
        }
        .el-col.el-col-24.form-item {
            .el-form-item.el-form-item--mini {
                margin-bottom: 2px;
            }
        }
    }
}
</style>
