import store from '@/store';
export const tableConfig = {
    name: 'addNewMainSearach',
    fields: [
        {
            label: '内外部业务',
            prop: 'oilIsGroupCustomer',
            type: 'select',
            visible: true,
            rule: [
                { required: true, message: '内外部业务不能为空', trigger: ['change', 'blur'] }
            ],
            optionsKey: 'SYS_WM_IN_OR_OUT_CUSTOMER'
        },
        {
            label: '合同性质',
            prop: 'contractCharacter',
            type: 'select',
            visible: true,
            rule: [
                { required: true, message: '合同性质不能为空', trigger: ['change', 'blur'] }
            ],
            placeholder: ' ',
            // optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
            options: []
        },
        {
            label: '关联合同',
            prop: 'mainContract',
            value: 'mainContract',
            type: 'advance',
            visible: true,
            rule: [
                { required: true, message: '主合同编号不能为空', trigger: ['change', 'blur'] }
            ],
            advanceConfig: [
                { 'prop': 'contractCode', 'value': '主合同编码' },
                { 'prop': 'contractName', 'value': '主合同名称' }
            ],
            tableConfig: [
                { 'prop': 'contractCode', 'value': '主合同编码' },
                { 'prop': 'contractName', 'value': '主合同名称' },
                { 'prop': 'validTime', 'value': '生效日期' },
                { 'prop': 'failTime', 'value': '失效日期' }
            ],
            prefix: '/api-lcrm',
            method: 'post',
            advanceUrl: `/contract/info/page`,
            cbParams: ['contractCode#mainContract'],
            beforeRequest: (params) => {
                params.contractCode = params.contractCode ? params.contractCode : null;
                params.contractName = params.contractName ? params.contractName : null;
                params.contractCharacter = '1'; // 合同性质为新签
                params.userCode = store.getters.user.userCode;
                return params;
            }
        },
        {
            label: '关联项目',
            prop: 'projectCode',
            value: 'projectCode',
            type: 'advance',
            visible: true,
            rule: [
                { required: true, message: '项目编码不能为空', trigger: ['change', 'blur'] }
            ],
            advanceConfig: [
                { prop: 'projectCode', value: '项目编码' }
            ],
            tableConfig: [
                { prop: 'projectCode', value: '项目编码' },
                {
                    prop: 'projectStatus',
                    value: '项目状态',
                    label: '项目状态',
                    type: 'select',
                    optionsKey: 'CRM_CUSTOMER_PROJECT_STATUS',
                    options: []
                }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/cdTsCustomerProjects`,
            cbParams: ['projectCode', 'projectStatus', 'isCheck', 'projectType', 'isLinkOppo']
        }
    ],
    height: 325,
    columns: [
        {
            label: '商机号',
            prop: 'opportunityCode',
            width: 130
        },
        {
            label: '商机名称',
            prop: 'opportunityName',
            width: 130
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 140
        },
        {
            label: '实际中标规模(万)',
            prop: 'totalScale',
            width: 120
        },
        {
            label: '目前规模(万)',
            prop: 'businessScale',
            width: 100
        },
        {
            label: '签单规模(万)',
            prop: 'cdExpectBiddingSize',
            width: 100
        },
        {
            label: '销售阶段',
            prop: 'saleStageId'
        },
        {
            label: '中标日期',
            prop: 'bidDate',
            width: 160
        },
        {
            label: '仓库类型',
            prop: 'warehouseType',
            type: 'select',
            optionsKey: 'NEOCRM_WH_TYPE',
            width: 80
        }
    ],
    selectable(row, index) {
        return !row.disabledBid && !row.disabled30;
    }
};
