export const tableConfig = {
    selection: false,
    height: 357,
    columns: [
        {
            label: '变更编号',
            prop: 'contractChangeCode',
            headerType: 'input',
            propLike: 'contractChangeCodeLike'
        },
        {
            label: '变更类型',
            prop: 'changeType',
            type: 'select',
            optionsKey: 'SYS_CRM_CONTRACT_CHANGETYPE',
            headerType: 'select',
            params: {
                params: {
                    optionsKey: 'SYS_CRM_CONTRACT_CHANGETYPE'
                }
            },
            propLike: 'changeType'
        },
        {
            label: '变更状态',
            prop: 'contractChangeStatus',
            type: 'select',
            optionsKey: 'SYS_LMDM_CONTRACT_CHANGE',
            headerType: 'select',
            params: {
                params: {
                    optionsKey: 'SYS_LMDM_CONTRACT_CHANGE'
                }
            },
            propLike: 'contractChangeStatus'
        },
        {
            label: '变更项',
            prop: 'changeDetail',
            headerType: 'input',
            propLike: 'changeDetailLike'
        },
        {
            label: '变更原因',
            prop: 'changeReason',
            headerType: 'input',
            propLike: 'changeReasonLike'
        },
        {
            label: '创建人',
            prop: 'createUserName',
            headerType: 'input',
            propLike: 'createUserNameLike'
        },
        {
            label: '创建时间',
            prop: 'createTime',
            headerType: 'date',
            dateType: 'datetimerange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            propLike: 'createTimeLike'
        },
        {
            label: '修改人',
            prop: 'updateUserName',
            headerType: 'input',
            propLike: 'updateUserNameLike'
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            headerType: 'date',
            dateType: 'datetimerange',
            startPlaceholder: '修改时间从',
            endPlaceholder: '修改时间到',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            propLike: 'updateTimeLike'
        }
    ]
};
