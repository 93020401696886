export const tableConfig = {
    name: 'opportunityListPrivilegeSearach',
    searchFields: [
        {
            name: '项目编码',
            value: 'projectCode',
            isFixed: true,
            type: 'input',
            span: 4
        },
        {
            name: '商机编码',
            value: 'opportunityCode',
            isFixed: true,
            type: 'input',
            span: 4
        },
        {
            name: '客户',
            value: 'opportunityCustomerNames',
            type: 'advanceMulti',
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            tableConfig: [
                { prop: 'customerName', value: '客户名称', 'displayName': 'customerName' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/customer/info/page',
            cbParams: [ 'customerCode#opportunityCustomerCode', 'customerName#opportunityCustomerName' ],
            isFixed: true,
            span: 4
        },
        {
            name: '销售阶段',
            value: 'saleStages',
            optionNum: 'NEOCRM_SALE_STAGE',
            type: 'select',
            multiple: true,
            span: 5,
            isFixed: true
        }
    ],
    height: 325,
    columns: [
        {
            label: '商机编码',
            prop: 'opportunityCode',
            width: 130
        },
        {
            label: '商机名称',
            prop: 'opportunityName',
            width: 130
        },
        {
            label: '项目编码',
            prop: 'projectCode',
            width: 160
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 140
        },
        {
            label: '客户名称',
            prop: 'opportunityCustomerName',
            width: 130
        },
        {
            label: '实际中标规模(万)',
            prop: 'totalScale',
            width: 120
        },
        {
            label: '目前规模(万)',
            prop: 'businessScale',
            width: 100
        },
        {
            label: '签单规模(万)',
            prop: 'cdExpectBiddingSize',
            width: 100
        },
        {
            label: '销售阶段',
            prop: 'saleStage',
            type: 'select',
            optionsKey: 'NEOCRM_SALE_STAGE'
        },
        {
            label: '输单阶段',
            prop: 'lostStage',
            type: 'select',
            optionsKey: 'NEOCRM_SALE_STAGE'
        },
        {
            label: '报签确认',
            prop: 'isSign',
            type: 'select',
            optionsKey: 'NEOCRM_YES_NO'
        },
        {
            label: '中标日期',
            prop: 'winningDate',
            width: 160
        },
        {
            label: '仓库类型',
            prop: 'warehouseType',
            type: 'select',
            optionsKey: 'NEOCRM_WH_TYPE',
            width: 80
        }
    ]
};
