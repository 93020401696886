<template>
    <div class="template-manage-dialog">
        <el-dialog
            :visible="true"
            title="新增计费模型"
            width="1000px"
            v-loading="loading"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <div class="template-search-add-box">
                <search-box
                    ref="searchBox"
                    :search-key="config.name"
                    :fields="config.searchFields"
                    @search-change="priceTemplateList.searchList"
                />
            </div>
            <table-box
                class="template-table"
                v-loading="priceTemplateList.tableLoading"
                ref="tableBox"
                :height="config.height"
                :columns="config.columns"
                :rows="priceTemplateList.totalData"
                :highlight-current-row="true"
                @current-change="handleCurrentChange"
                @row-dblclick="rowDBclick"
            >
            </table-box>
            <lots-pagination
                @size-change="priceTemplateList.sizeChange"
                :current-page.sync="priceTemplateList.pageNo"
                @current-change="priceTemplateList.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="priceTemplateList.pageTotal"
            ></lots-pagination>
            <span slot="footer">
                <el-button type="primary" size="mini" @click="confirm">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from '@vue/composition-api';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { priceTemplate, getOrderType } from '@mdm/api/contractManage/priceApi.js';
import { Message } from 'element-ui';
import { dictWithUrl } from '@/components/lots/api';
export default {
    components: { searchBox, tableBox, lotsPagination },
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        const emit = setupContext.emit;
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                if (condition.businessType && condition.businessType.length) {
                    condition.businessType = String(condition.businessType);
                } else {
                    condition.businessType = String(props.detailData.businessType);
                }
                condition.status = 1;
                return condition;
            };
        };
        const priceTemplateList = new DataDetailClass({
            Api: {
                listApi: priceTemplate
            }
        });
        const businessTypeDictData = ref({});
        onMounted(async() => {
            priceTemplateList.getList();
            await dictWithUrl('SYS_BMS_BUSI_TYPE').then(res => {
                businessTypeDictData.value = res.data['SYS_BMS_BUSI_TYPE'];
            });
            getOrderType().then((res) => {
                if (res.code === '0' && res.data.list && res.data.list.length) {
                    config.searchFields.forEach((item) => {
                        if (item.value === 'orderTypeCode') {
                            // eslint-disable-next-line max-nested-callbacks
                            item.options = res.data.list.map(subItem => {
                                return {
                                    label: subItem.orderTypeName,
                                    value: subItem.orderTypeCode
                                };
                            });
                        }
                        if (item.value === 'businessType') {
                            item.options = [];
                            // eslint-disable-next-line max-nested-callbacks
                            props.detailData.businessType.length && props.detailData.businessType.forEach(key => {
                                const optionItem = {
                                    label: businessTypeDictData.value[key],
                                    value: key
                                };
                                item.options.push(optionItem);
                            });
                        }
                    });
                }
            });
        });
        const rowDBclick = (row) => {
            const {
                templateCode,
                templateName,
                feeName,
                description,
                businessTypeName,
                calculateType,
                collectUnitName,
                priceCols,
                calcRuleCode,
                feeCode,
                orderTypeCode,
                orderTypeName,
                priceTemplateUrl
            } = row;
            emit('confirm', {
                templateCode,
                templateName,
                feeName,
                description,
                businessTypeName,
                calculateType,
                collectUnitName,
                priceCols,
                calcRuleCode,
                feeCode,
                orderTypeCode,
                orderTypeName,
                priceTemplateUrl
            });
        };
        const confirm = () => {
            if (Object.keys(selectedRow.value).length === 0) {
                return Message.warning('请选择数据');
            }
            const {
                templateCode,
                templateName,
                feeName,
                description,
                businessTypeName,
                calculateType,
                collectUnitName,
                priceCols,
                calcRuleCode,
                feeCode,
                orderTypeCode,
                orderTypeName,
                priceTemplateUrl
            } = selectedRow.value;
            emit('confirm', {
                templateCode,
                templateName,
                feeName,
                description,
                businessTypeName,
                calculateType,
                collectUnitName,
                priceCols,
                calcRuleCode,
                feeCode,
                orderTypeCode,
                orderTypeName,
                priceTemplateUrl
            });
        };
        const selectedRow = ref({});
        const handleCurrentChange = (row) => {
            selectedRow.value = row;
        };
        return {
            emit,
            config,
            priceTemplateList,
            rowDBclick,
            selectedRow,
            handleCurrentChange,
            confirm
        };
    }
};
</script>
<style lang="less">
.template-manage-dialog {
    .template-search-add-box {
        display: flex;
        .model-table-search {
            flex: 2;
        }
        .add-btn {
            flex: 1;
            position: relative;
            left: -56px;
        }
    }
    .template-table {
        margin: 10px 0 5px 0;
    }
}
</style>
