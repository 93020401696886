export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '预签合同编号',
            type: 'advance',
            // type: 'slot',
            prop: 'preContractCode',
            // editSlot: true,
            mustFill: true,
            value: 'preContractCode',
            rule: [
                { required: true, message: '预签合同编号不能为空', trigger: ['change', 'blur'] }
            ],
            placeholder: '123456',
            advanceConfig: [
                { 'prop': 'contractCode', 'value': '合同编号' },
                { 'prop': 'contractName', 'value': '合同名称' }
            ],
            tableConfig: [
                { 'prop': 'contractCode', 'value': '合同编号' },
                { 'prop': 'contractName', 'value': '合同名称' }
            ],
            prefix: '/api-lcrm',
            method: 'post',
            advanceUrl: `/contract/info/page`,
            advanceCascade: 'contractCharacter#contractCharacter',
            cbParams: ['contractCode#preContractCode'],
            beforeRequest: (params) => {
                params.contractCode = params.contractCode ? params.contractCode : null;
                params.contractName = params.contractName ? params.contractName : null;
                return params;
            },
            width: 130
        },
        {
            label: '正式签合同编号',
            prop: 'contractCode',
            width: 130
        },
        {
            label: '生效日期',
            prop: 'validTime'
        },
        {
            label: '失效日期',
            prop: 'failTime'
        },
        {
            label: '创建人',
            prop: 'createUserCode'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        },
        {
            label: '修改人',
            prop: 'updateUserCode'
        },
        {
            label: '修改时间',
            prop: 'updateTime'
        }
    ]
};
