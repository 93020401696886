/*eslint-disable*/
import store from '@/store';
import _ from 'lodash';
const tenantCode = store.getters.currentTenant.tenantCode;
export const validateNull = (rule, value, callback) => {
    if (!value) { callback(); }// 当不是必填项时，可以提交空字符串
    if (!/^\d+\.?\d{0,2}$/.test(value)) {
        return callback(new Error('请输入数字'));
    }
    callback();
};
export const validateNullAhundred = (rule, value, callback) => {
    if (!/^(\d|[1-9]\d|100)(\.\d{1,2})?$/.test(value)) {
        return callback(new Error('请输入0-100间的数字'));
    }
    callback();
};

const accountFormData = { tenantCode: 'annto', supplierCode: '' };
const originContractFormData = { suppliersCode: '', businessType: '', contractCharacter: '1', contractStatus: '50,60,80' };
const supplierFormData = { tenantCode: tenantCode, businessType: '', servicesType: '' };
const cityFormData = { provinceCode: '' };

const pickerOptions = {
    disabledDate(time) {
        const start = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0));
        return time.getTime() < start;
    }
};

// 表单配置信息
export const collapse = [
    {
        title: '基本信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                prop: 'contractChangeCode',
                label: '变更编号',
                placeholder: ' ',
                // type: 'input',
                type: 'custom',
                visible: false,
                disabled: true
            },
            {
                prop: 'contractChangeStatus',
                label: '变更状态',
                placeholder: ' ',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_LMDM_CONTRACT_CHANGE',
                visible: false,
                disabled: true
            },
            {
                prop: 'contractCode',
                label: '单据编码',
                placeholder: ' ',
                // type: 'input',
                type: 'custom',
                visible: true,
                disabled: true
            },
            {
                prop: 'mipContractCode',
                label: '法务合同编码',
                placeholder: ' ',
                // type: 'input',
                type: 'custom',
                visible: true,
                disabled: true
            },
            {
                label: '合同性质',
                prop: 'contractCharacter',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: true,
                rule: [
                    { required: false, message: '合同性质不能为空', trigger: 'blur' }
                ],
                placeholder: ' ',
                optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
            },
            {
                label: '合同状态',
                prop: 'contractStatus',
                // type: 'select',
                type: 'custom',
                value: '6',
                placeholder: ' ',
                visible: true,
                disabled: true,
                optionsKey: 'SYS_CR_CONTRACT_STATUS'
            },
            {
                prop: 'contractName',
                label: '合同名称',
                // type: 'input',
                type: 'custom',
                maxlength: 256,
                visible: true,
                rule: [
                    { required: true, message: '合同名称不能为空', trigger: ['change', 'blur'] }
                ],
            },
            {
                label: '主合同编号',
                prop: 'mainContract',
                value: 'mainContract',
                // type: 'advance',
                type: 'custom',
                visible: true,
                rule: [
                    { required: false, message: '主合同编号不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'contractCode', 'value': '主合同编码' },
                    { 'prop': 'contractName', 'value': '主合同名称' }
                ],
                tableConfig: [
                    { 'prop': 'contractCode', 'value': '主合同编码' },
                    { 'prop': 'contractName', 'value': '主合同名称' },
                    { 'prop': 'validTime', 'value': '生效日期' },
                    { 'prop': 'failTime', 'value': '失效日期' }
                ],
                prefix: '/api-lcrm',
                method: 'post',
                advanceUrl: `/contract/info/page`,
                cbParams: ['contractCode#mainContract'],
                beforeRequest: (params) => {
                    params.contractCode = params.contractCode ? params.contractCode : null;
                    params.contractName = params.contractName ? params.contractName : null;
                    params.contractCharacter = '1'; // 合同性质为新签
                    params.userCode = store.getters.user.userCode;
                    return params;
                },
                disabled: true
            },
            {
                label: '会计主体',
                prop: 'accountEntityName',
                value: 'accountEntityName',
                // type: 'advance',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '会计主体不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'operatingUnitName', 'value': '会计主体名称' },
                    { 'prop': 'operatingUnitId', 'value': '会计主体编码' }
                ],
                tableConfig: [
                    { 'prop': 'operatingUnitId', 'value': '会计主体编码' },
                    { 'prop': 'operatingUnitName', 'value': '会计主体名称' }
                ],
                prefix: '/api-lcrm',
                // method: 'post',
                advanceUrl: `/efOrganRelations`,
                cbParams: ['operatingUnitId#accountEntityId', 'operatingUnitName#accountEntityName', 'legalEntityName'], // legalSysId: 会计主体id
                beforeRequest: (params) => {
                    if (params.operatingUnitName) {
                        return params;
                    }
                    const inputKey = params.operatingUnitId?.trim();
                    const reg = new RegExp(/^-?\d+$/); // 数字正则
                    if (!reg.test(inputKey)) {
                        params.operatingUnitName = inputKey;
                        params.operatingUnitId = null;
                    } else {
                        params.operatingUnitId = inputKey;
                        params.operatingUnitName = null;
                    }
                    return params;
                },
                afterResponse: (res) => {
                    if (res && res.data && Array.isArray(res.data.list)) {
                        res.data.list = res.data.list.filter(item => !_.isNil(item));
                    }
                    return _.cloneDeep(res);
                }
            },
            {
                label: '开发单位',
                prop: 'developmentUnitName',
                value: 'developmentUnitName',
                // type: 'advance',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '开发单位不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'companyNameCn', 'value': '开发单位名称' },
                    { 'prop': 'companyCode', 'value': '开发单位编码' }
                ],
                tableConfig: [
                    { 'prop': 'companyCode', 'value': '开发单位编码' },
                    { 'prop': 'companyNameCn', 'value': '开发单位名称' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/esCompany/popCompany`,
                cbParams: ['companyCode#developmentUnit', 'companyNameCn#developmentUnitName']
            },
            {
                label: '主要开发人',
                prop: 'salesStaffName',
                value: 'salesStaffName',
                // type: 'advance',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '主要开发人不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusLoginName', 'value': '美信号' }
                ],
                tableConfig: [
                    { 'prop': 'esusId', 'value': 'ID' },
                    { 'prop': 'esusLoginName', 'value': '美信号' },
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusPositionName', 'value': '职位' },
                    { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/selectUserCompany`,
                cbParams: ['esusUserNameCn#salesStaffName', 'esusId#salesStaffCode']
            },
            {
                label: '服务经理',
                prop: 'serviceManagerName',
                value: 'serviceManagerName',
                // type: 'advance',
                type: 'custom',
                visible: true,
                advanceConfig: [
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusLoginName', 'value': '美信号' }
                    // { 'prop': 'esusId', 'value': 'ID' },
                ],
                tableConfig: [
                    { 'prop': 'esusId', 'value': 'ID' },
                    { 'prop': 'esusLoginName', 'value': '美信号' },
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusPositionName', 'value': '职位' },
                    { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/selectUserCompany`,
                cbParams: ['esusUserNameCn#serviceManagerName', 'esusId#serviceManager']
            },
            {
                label: '项目编码',
                prop: 'projectCode',
                value: 'projectCode',
                // type: 'advance',
                type: 'custom',
                // method: 'post',
                visible: true,
                rule: [
                    { required: true, message: '项目编码不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'projectCode', 'value': '项目编码' }
                ],
                tableConfig: [
                    { 'prop': 'projectCode', 'value': '项目编码' },
                    {
                        prop: 'projectStatus',
                        value: '项目状态',
                        label: '项目状态',
                        type: 'select',
                        optionsKey: 'CRM_CUSTOMER_PROJECT_STATUS',
                        options: []
                    }
                ],
                prefix: '/api-lcrm',
                advanceUrl: `/cdTsCustomerProjects`,
                cbParams: ['projectCode', 'projectStatus', 'isCheck', 'projectType', 'isLinkOppo']
            },
            {
                label: '生效日期',
                prop: 'validTime',
                // type: 'time',
                type: 'custom',
                timeType: 'date',
                rule: [
                    { required: true, message: '生效日期不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false,
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd'
            },
            {
                label: '失效日期',
                prop: 'failTime',
                // type: 'time',
                type: 'custom',
                timeType: 'date',
                rule: [
                    { required: true, message: '失效日期不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false,
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd'
            },
            {
                label: '业务大类',
                prop: 'bigBusinessType',
                // type: 'select',
                type: 'custom',
                rule: [
                    { required: true, message: '业务大类不能为空', trigger: 'change' }
                ],
                visible: true,
                placeholder: '',
                optionsKey: 'SYS_LMDM_BUS_TYPE'
            },
            {
                label: '业务类别',
                prop: 'businessType',
                // type: 'select',
                type: 'custom',
                multiple: true,
                // "collapse-tags": true,
                // multipleLimit: 2,
                visible: true,
                rule: [
                    { required: false, message: '业务类别不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SYS_BMS_BUSI_TYPE'
            },
            {
                label: '仓储运作类型',
                prop: 'customerType',
                // type: 'select',
                type: 'custom',
                optionsKey: 'CONTRACT_CUSTOMER_TYPE',
                visible: false,
                rule: [
                    { required: true, message: '仓储运作类型不能为空', trigger: 'change' }
                ],
            },
            {
                label: '专业公司',
                prop: 'professionalCompany',
                // type: 'select',
                type: 'custom',
                visible: true,
                optionsKey: 'SYS_PROFESSIONAL_COMPANY'
            },
            {
                label: '是否云仓',
                prop: 'isCloudWh',
                // type: 'select',
                type: 'custom',
                visible: false,
                rule: [
                    { required: true, message: '是否云仓不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SYS_CRM_IS_CLOUND_WH'
            },
            {
                label: '投保方',
                prop: 'insurant',
                // type: 'select',
                type: 'custom',
                rule: [
                    { required: true, message: '投保方不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_WM_THE_INSURED',
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '签单情况',
                prop: 'isNew',
                // type: 'select',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '签单情况不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_LMDM_SIGNING_SITUATION'
            },
            {
                label: '截单日期',
                prop: 'cutOffTime',
                // type: 'time',
                type: 'custom',
                timeType: 'date',
                placeholder: '',
                visible: true,
                onlyTime: true,
                valueFormat: 'HH:mm:ss'
            },
            {
                prop: 'contractCount',
                label: '合同份数',
                // type: 'input',
                type: 'custom',
                maxlength: 2,
                visible: true,
                rule: [
                    { required: true, message: '合同份数必填', trigger: 'blur' }
                ],
            },
            {
                label: '盖章方式',
                prop: 'stampMode',
                // type: 'select',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '盖章方式不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SYS_CONTRACT_STAMP_MODE'
            },
            {
                label: '印章类型',
                prop: 'sealCategory',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CONTRACT_SEAL_CATEGORY',
                visible: false,
                disabled: false,
                rule: [
                    { required: true, message: '印章类型不能为空', trigger: 'change' }
                ]
            },
            {
                label: '印章管理员',
                prop: 'sealAdminAccount',
                // type: 'select',
                type: 'custom',
                options: [],
                visible: false,
                rule: [
                    { required: true, message: '印章管理员不能为空', trigger: 'change' }
                ]
            },
            // {
            //     label: '印章管理员',
            //     prop: 'sealAdminName',
            //     value: 'sealAdminName',
            //     type: 'advance',
            //     placeholder: '',
            //     advanceConfig: [
            //         { 'prop': 'contractUnitId', 'value': '客户编码' },
            //         { 'prop': 'contractUnitName', 'value': '客户名称' }
            //     ],
            //     tableConfig: [
            //         { 'prop': 'contractUnitId', 'value': '客户编码' },
            //         { 'prop': 'contractUnitName', 'value': '客户名称' }
            //     ],
            //     prefix: '/api-lcrm',
            //     method: 'post',
            //     advanceUrl: `/contract/querySealAdmin`, //30003357
            //     advanceCascade: "accountEntity1#belongs,sealCategory#type",
            //     cbParams: [ 'contractUnitId#contractUnitId', 'contractUnitName#sealAdminName' ]
            // },
            {
                label: '是否生成水印',
                prop: 'ifWatermarking',
                // type: 'select',
                type: 'custom',
                visible: false,
                rule: [
                    { required: true, message: '是否生成水印不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SRM_YES_NO'
            },
            {
                label: '不生成水印原因',
                prop: 'noWatermarkingReason',
                // type: 'input',
                type: 'custom',
                maxlength: 4000,
                visible: false,
                rule: [
                    { required: true, message: '不生成水印原因不能为空', trigger: 'change' }
                ],
            },
            {
                label: '产品类型',
                prop: 'productType',
                // type: 'select',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '产品类型不能为空', trigger: 'change' }
                ],
                placeholder: ' ',
                optionsKey: 'SYS_CONTRACT_PRODUCT_TYPE'
            },
            // {
            //     label: '分公司',
            //     prop: 'companyName',
            //     value: 'companyName',
            //     type: 'advance',
            //     rule: [
            //         { required: true, message: '分公司不能为空', trigger: ['change', 'blur'] }
            //     ],
            //     advanceConfig: [
            //         { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' },
            //         { 'prop': 'companyCode', 'value': '分公司编码' }  
            //     ],
            //     tableConfig: [
            //         { 'prop': 'companyCode', 'value': '分公司编码' },
            //         { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
            //     ],
            //     prefix: '/api-mdm',
            //     advanceUrl: `/esCompany/searchSite?escoCompanyType=C`,
            //     cbParams: ['companyCode#companyCode', 'escoCompanyNameCn#companyName']
            // },
            // {
            //     label: '我方合同联系人',
            //     prop: 'supplierContactPersonName',
            //     type: 'advance',
            //     rule: [
            //         { required: true, message: '我方合同联系人不能为空', trigger: 'blur' }
            //     ],
            //     value: 'supplierContactPersonName',
            //     advanceConfig: [
            //         { 'prop': 'esusId', 'value': 'ID' },
            //         { 'prop': 'esusLoginName', 'value': '美信号' }
            //     ],
            //     tableConfig: [
            //         { 'prop': 'esusId', 'value': 'ID' },
            //         { 'prop': 'esusLoginName', 'value': '美信号' },
            //         { 'prop': 'esusUserNameCn', 'value': '姓名' },
            //         { 'prop': 'esusPositionName', 'value': '职位' },
            //         { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            //     ],
            //     prefix: '/api-mdm',
            //     advanceUrl: `/selectUserCompany`,
            //     cbParams: [ 'esusUserNameCn#supplierContactPersonName', 'esusId#supplierContactPersonCode' ]
            // },
            {
                label: '内外部业务',
                prop: 'oilIsGroupCustomer',
                // type: 'select',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '内外部业务不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_WM_IN_OR_OUT_CUSTOMER',
                disabled: true
            },
            {
                label: '合同来源',
                prop: 'contractSource',
                // type: 'select',
                type: 'custom',
                visible: true,
                placeholder: '',
                disabled: true,
                optionsKey: 'CONTRACT_SOURCE'
            },
            {
                label: '回收份数',
                prop: 'receivedCopies',
                type: 'custom',
                visible: true,
            },
            {
                label: '纸质合同归档码',
                prop: 'archiveCode',
                type: 'custom',
                visible: true,
            },
            {
                label: '变更类型',
                prop: 'changeType',
                // type: 'select',
                type: 'custom',
                visible: true,
                placeholder: '',
                optionsKey: 'SYS_CRM_CONTRACT_CHANGETYPE',
                rule: [
                    { required: false, message: '变更类型不能为空', trigger: 'change' }
                ],
                disabled: true
            },
            {
                prop: 'changeReason',
                label: '变更原因',
                inputType: 'textarea',
                // type: 'input',
                type: 'custom',
                maxlength: 1024,
                showWordLimit: true,
                visible: false,
                rule: [
                    { required: true, message: '变更原因不能为空', trigger: ['change', 'blur'] }
                ],
                rows: 3,
                span: 24
            },
            {
                label: '变更项',
                prop: 'changeDetail',
                // type: 'input',
                type: 'custom',
                maxlength: 1024,
                showWordLimit: true,
                inputType: 'textarea',
                resize: 'none',
                placeholder: '',
                visible: false,
                disabled: true,
                rows: 5,
                span: 24
            }
        ]
    },
    {
        title: '油价联动',
        ref: 'oilForm',
        formName: 'oilPriceInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                label: '是否油价联动',
                prop: 'oilLinkedProportion',
                // type: 'select',
                type: 'custom',
                rule: [
                    { required: true, message: '是否油价联动不能为空', trigger: 'change' }
                ],
                optionsKey: 'SRM_YES_NO',
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '联动类型',
                prop: 'linkageType',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CR_LINKAGE_TYPE',
                placeholder: '',
                rule: [
                    { required: true, message: '联动类型不能为空', trigger: 'change' }
                ],
                visible: true
            },
            {
                label: '触发联动比例(%)',
                prop: 'triggerOilPriceFloat',
                // type: 'input',
                type: 'custom',
                maxlength: 5,
                rule: [
                    { required: true, message: '触发联动比例不能为空', trigger: 'blur' }
                ],
                visible: true
            },
            {
                label: '运价浮动方式',
                prop: 'freightFloatMode',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CR_FREIGHT_FLOAT_MODE',
                placeholder: '',
                rule: [
                    { required: true, message: '运价浮动方式不能为空', trigger: 'change' }
                ],
                visible: true
            },
            {
                label: '运价联动比例(%)',
                prop: 'freightLinkedScale',
                // type: 'input',
                type: 'custom',
                maxlength: 6,
                rule: [
                    { required: true, message: '运价联动比例不能为空', trigger: 'blur' }
                ],
                visible: true
            },
            {
                label: '运价浮动系数(%)',
                prop: 'freightFloatCoefficient',
                // type: 'input',
                type: 'custom',
                maxlength: 6,
                rule: [
                    { required: true, message: '运价浮动系数不能为空', trigger: 'blur' }
                ],
                visible: true
            },
            {
                label: '联动执行时间',
                prop: 'oilLinkageExecutime',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CR_OIL_LINKAGE_EXECUTIME',
                placeholder: '',
                rule: [
                    { required: true, message: '联动执行时间不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '新油价取值方式',
                prop: 'newOilSelectionMethod',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CR_NEW_OIL_SELECTION_METHOD',
                placeholder: '',
                rule: [
                    { required: true, message: '新油价取值方式不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '再次触发基准油价',
                prop: 'triggerPriceAgain',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CR_TRIGGER_PRICE_AGAIN',
                placeholder: '',
                rule: [
                    { required: true, message: '再次触发基准油价不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '详细条款说明',
                prop: 'conditionsDetail',
                // type: 'input',
                type: 'custom',
                maxlength: 4000,
                showWordLimit: true,
                inputType: 'textarea',
                resize: 'none',
                rows: 3,
                span: 24,
                rule: [
                    { required: true, message: '详细条款说明不能为空', trigger: 'blur' }
                ],
                visible: true
            }
        ]
    }
];
