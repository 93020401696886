export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '合同编号',
            prop: 'contractCode'
        },
        {
            label: '合同性质',
            type: 'select',
            optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
            prop: 'contractCharacter'
        },
        {
            label: '生效日期',
            prop: 'validTime'
        },
        {
            label: '失效日期',
            prop: 'failTime'
        },
        {
            label: '创建人',
            prop: 'createUserName'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ]
};
