<template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button
            type="text"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="handleAddRow"
            :disabled="isDisableBtn"
            >新增</lots-button
            >
            <lots-button
            type="text"
            icon="el-icon-circle-check"
            size="medium"
            @click="handleSaveRows"
            :disabled="isDisableBtn"
            >保存</lots-button
            >
            <lots-button type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows()" :disabled="isDisableBtn"
            >删除</lots-button
            >
            <!-- <lots-button type="text" icon="el-icon-upload2" size="medium" @click="handleImport()" :disabled="isDisableBtn">导入</lots-button>
            <lots-button type="text" icon="el-icon-circle-plus-outline" size="medium" v-has="authorityCode.rateAdd" @click="handleAddRow">特权新增</lots-button>
            <lots-button type="text" icon="el-icon-circle-check" size="medium" v-has="authorityCode.rateSave" @click="handleSaveRows" :disabled="detailPageState === statePage">特权保存</lots-button>
            <lots-button type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows" v-has="authorityCode.rateDelete">特权删除</lots-button> -->
        </div>
        <div class="file-detail-table">
            <edit-table-box
                class="prescreen"
                v-loading="subTabTable.tableLoading"
                ref="editTableBox"
                :selection="config.selection"
                :control="false"
                :columns="config.columns"
                :height="tableHeight"
                :tableData="subTabTable.totalData"
                :actions="config.actions"
                @selection-change="handleSelectedRow"
                @element-change="switchStatus"
            ></edit-table-box>
            <div class="footer">
                <lots-pagination
                @size-change="subTabTable.sizeChange"
                :current-page.sync="subTabTable.pageNo"
                @current-change="subTabTable.pageChange"
                size="small"
                layout="total, sizes, prev, pager, next, jumper"
                :total="subTabTable.pageTotal"
                />
            </div>
            <!-- 导入弹窗 -->
            <el-dialog
                @close="closeLog"
                title="导入附件"
                :visible.sync="addDialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                width="470px"
            >
                <el-form
                :model="filesForm"
                label-position="right"
                class="form-dialog"
                label-width="80px"
                :rules="staticConfig.formConfig.rules"
                ref="filesFormDialog"
                >
                <el-form-item label="附件名称" prop="accessoryName">
                    <el-input v-model="filesForm.accessoryName" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="文件类型" prop="fileType">
                    <lots-select
                    optionsKey="SYS_CR_CONTRACT_FILE_TYPE"
                    v-model="filesForm.fileType"
                    @change="fileTypeChange"
                    @clear="filesTypeClear"
                    clearable
                    style="width: 100%"
                    />
                </el-form-item>
                <!-- <el-form-item
                    v-if="hasFilesType && filesForm.fileType === 'ContractNeedStamp'"
                    label="选择模板"
                    prop="templateName"
                >
                    <otpAdvance
                    @getAdvanceValue="getAdvanceValue"
                    :mValue="filesForm.templateName"
                    :params="staticConfig.formConfig.templateAdvance"
                    clearable
                    :disabled="false"
                    >
                    </otpAdvance>
                </el-form-item> -->
                </el-form>
                <span slot="footer" class="dialog-footer">
                <lots-button size="mini" @click="closeLog">取 消</lots-button>
                <lots-button
                    size="mini"
                    type="primary"
                    @click="upload('filesFormDialog')"
                    :disabled="uploadOrHangdleForm"
                    >上传附件</lots-button
                >
                <!-- <lots-button
                    size="mini"
                    type="primary"
                    v-loading="btnLoading"
                    :disabled="!uploadOrHangdleForm"
                    @click="handleSave('filesFormDialog')"
                    >生成合同</lots-button
                > -->
                </span>
            </el-dialog>
            <dialog-import-file
                ref="Import"
                :ImportConfig="staticConfig.dialogImportConfig.ImportConfig"
                :showDownLoad="false"
                @uploadClose="uploadClose"
            ></dialog-import-file>
        </div>
    </div>
</template>

<script>
import {
    ref,
    reactive,
    computed,
    watch
} from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import DialogImportFile from '@/components/lots/DialogImportFile/Index';
import lotsButton from '@/components/lots/lotsButton';
// TODO
// import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    warehouseInfoList,
    warehouseInfoDelete,
    warehouseInfoSave
} from '@mdm/api/contractManage/contractFootPageApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { importConfig, tableConfig } from './config.js';
import { Message } from 'element-ui';
import { STATE_OTHER_CHANGE, STATE_NEW } from '@/modules/mdm/constant/contractManage.js';

export default {
    name: '',
    components: {
        lotsPagination,
        lotsSelect,
        // otpAdvance,
        DialogImportFile,
        lotsButton,
        editTableBox
    },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        contractCode: {
            type: String,
            default() {
                return '';
            }
        },
        contractChangeCode: {
            type: String,
            default() {
                return '';
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        contractStatus: {
            type: String,
            default() {
                return '';
            }
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        const staticConfig = reactive(importConfig);
        const { user } = setupContext.root.$store.getters;
        const isDisableBtn = ref(false);
        const listApi = reactive(warehouseInfoList);
        const deleteApi = reactive(warehouseInfoDelete);
        class FilesDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.contractCode;
                return condition;
            };
        }
        const subTabTable = new FilesDetailClass({
            Api: {
                listApi,
                deleteApi
            }
        });
        const addDialog = ref(false);
        const hasFilesType = computed(() => !!filesForm.value.fileType);
        const uploadOrHangdleForm = computed(
            () => !!(hasFilesType && filesForm.value.templateName)
        );
        const filesForm = ref({
            accessoryName: '',
            fileType: '',
            templateName: '',
            draftModel: ''
        });

        const btnLoading = ref(false);
        const filesBasicParams = reactive({
            contractCode: '',
            userCode: '',
            contractCharacter: ''
        });
        const handleAddRow = () => {
            setupContext.refs.editTableBox.addRow();
        };
        const handleImport = () => { addDialog.value = true; }; // 导入打开弹窗进行附件上传
        const upload = (formName) => {
            setupContext.refs[formName].validate((valid) => {
                if (valid) {
                    const { contractCode }
            = filesBasicParams;
                    const { fileType, accessoryName } = filesForm.value;// tenantCode
                    let urlObject = {
                        contractCode,
                        fileType,
                        accessoryName
                    };
                    if (props.detailPageState === STATE_OTHER_CHANGE) {
                        urlObject = { ...urlObject, contractChangeCode: props.contractChangeCode || '001' };
                    }
                    const urlParams = Object.keys(urlObject).reduce((pre, cur) => {
                        if (urlObject[cur]) {
                            if (pre.length !== 1) {
                                pre = `${pre}&${cur}=${urlObject[cur]}`;
                            } else {
                                pre = `${pre}${cur}=${urlObject[cur]}`;
                            }
                        }
                        return pre;
                    }, '?');
                    let baseUrl = staticConfig.dialogImportConfig.ImportConfig.baseUrl;
                    if (props.detailPageState === STATE_OTHER_CHANGE) {
                        baseUrl = baseUrl.replace('contractCustomerInfo', 'contractCustomerChange');
                    }
                    staticConfig.dialogImportConfig.ImportConfig.action = baseUrl.concat(urlParams);
                    setupContext.refs.Import.orderImportC();
                }
            });
        };
        const getAdvanceValue = (val) => {
            filesForm.value.templateId = val.templateId;
            filesForm.value.templateName = val.templateName;
            filesForm.value.attachmentId = val.attachmentId;
            filesForm.value.draftModel = val.draftModel;
        };
        /**
     * @description: 清空文件类型时初始化联动表单模板数据
     */
        const filesTypeClear = (data) => {
            filesForm.value.templateId = '';
            filesForm.value.templateName = '';
            filesForm.value.attachmentId = '';
            filesForm.value.draftModel = '';
        };
        const fileTypeChange = (data) => {
            if (data !== 'ContractNeedStamp') {
                filesTypeClear();
            }
        };
        // 导入关闭弹窗
        const uploadClose = () => {
            closeLog();
        };
        const closeLog = () => {
            filesForm.value = {
                accessoryName: '',
                fileType: '',
                templateName: '',
                draftModel: ''
            };
            addDialog.value = false;
            subTabTable.getList();
        };
        const selections = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selections.value = selection.map(v => {
                v.contractCode = props.contractCode;
                return v;
            });
        };
        const switchStatus = (changeObjData) => {
            // const { prop, val, row, index } = changeObjData;
            // console.log(prop, val, row, index);
            // config.columns.forEach(v => {
            //     if (v.prop === 'templateName') {
            //         v.mustFill = !(val === '99');
            //     }
            // });
            // row.disableProps = val === '99' ? ['templateName'] : '';
            // console.log(config);
        };
        const handleSaveRows = async () => { // 调批量保存接口
            if (setupContext.refs.editTableBox.saveData() === false) return;
            if (selections.value.length === 0) {
                Message.error('请选择需要保存的条目');
                return;
            }
            selections.value.forEach((e) => {
                e.contractCode = props.contractCode === '0000' ? '' : props.contractCode;
            });
            const res = await warehouseInfoSave(selections.value);
            if (res && +res.code === 0) {
                Message({
                    message: '成功保存' + res.data + '条数据',
                    type: 'success'
                });
                subTabTable.getList(); // 刷新列表
            }
        };
        const handleDeleteRows = async () => { // 调批量删除接口
            if (selections.value.length === 0) {
                Message.error('请选择需要删除的条目');
                return;
            }
            const ids = JSON.parse(JSON.stringify(selections.value)).map((v) => v.id);
            subTabTable.deleteRow({ ids }, false);
        };
        watch(
            props,
            (newVal, oldVal) => {
                const { detailPageState, contractStatus, contractCode } = newVal;
                if (detailPageState === STATE_NEW) {
                    isDisableBtn.value = true;
                } else { // 这里判断当合同状态是新增，草稿时，新增，保存，删除，导入操作都启用，其他状态下禁用
                    isDisableBtn.value = !(contractStatus === '0' || contractStatus === '1');
                }
                if (contractCode) {
                    const { userCode } = user;
                    filesBasicParams.contractCode = props.contractCode;
                    filesBasicParams.userCode = userCode;
                    filesBasicParams.contractCharacter = props.contractCharacter;
                    if (props.activeName === 'warehouseDetail') {
                        if (detailPageState !== STATE_NEW) {
                            subTabTable.getList();
                        }
                    }
                }
            },
            {
                immediate: true
            }
        );
        return {
            isDisableBtn,
            config,
            staticConfig,
            subTabTable,
            addDialog,
            hasFilesType,
            uploadOrHangdleForm,
            filesForm,
            btnLoading,
            getAdvanceValue,
            handleAddRow,
            handleImport,
            filesTypeClear,
            fileTypeChange,
            upload,
            uploadClose,
            closeLog,
            handleSelectedRow,
            switchStatus,
            handleSaveRows,
            handleDeleteRows
        };
    }
};
</script>

<style lang = "less" scoped>
/* ::v-deep .prescreen .el-table .el-table__header th:nth-last-of-type(2){
    display: none;
}
::v-deep .prescreen .el-table .el-table__body td:nth-last-of-type(1){
    display: none;
} */
</style>
