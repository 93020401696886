export const tableConfig = {
    name: 'priceTemplateSearach',
    searchFields: [
        { value: 'feeName', name: '费用名称', isFixed: true, type: 'input', span: 4 },
        { value: 'templateName', name: '模板名称', isFixed: true, type: 'input', span: 4 },
        { value: 'ruleName', name: '价格模板', isFixed: true, type: 'input', span: 4 },
        { value: 'collectUnitName', name: '汇总条件', isFixed: true, type: 'input', span: 4 },
        { value: 'templateCode', name: '模板编码', isFixed: true, type: 'input', span: 4 },
        {
            value: 'orderTypeCode',
            name: '单据类型',
            isFixed: true,
            type: 'select',
            span: 4,
            options: []
        },
        {
            value: 'businessType',
            name: '业务类型', // SYS_BMS_BUSI_TYPE
            isFixed: true,
            type: 'select',
            multiple: true,
            span: 4,
            options: []
        }
    ],
    height: 325,
    columns: [
        { prop: 'feeName', label: '费用名称' },
        { prop: 'templateName', label: '模板名称' },
        { prop: 'ruleName', label: '价格模板' },
        { prop: 'collectUnitName', label: '汇总条件' },
        { prop: 'templateCode', label: '模板编码' },
        { prop: 'orderTypeName', label: '单据类型' },
        { prop: 'businessTypeName', label: '业务类型' }
    ]
};
