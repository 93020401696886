import configurl from '@/config/user.env';
export const importConfig = {
    dialogImportConfig: {
        ImportConfig: {
            baseUrl: configurl.apiUrl + '/api-lcrm/contractCustomerInfo/excel',
            action: configurl.apiUrl + '/api-lcrm/contractCustomerInfo/excel', // 必选参数，上传的地址
            multiple: false, // 是否支持多选文件
            accept: 'EXCEL',
            showFileList: false,
            fileType: '只能上传excel文件。',
            manualUpload: false // 是否在选取文件后立即进行上传
        }
    },
    formConfig: {
        rules: {
            accessoryName: [
                { required: true, message: '请输入附件名称', trigger: 'change' }
            ],
            fileType: [
                { required: true, message: '请选择文件类型', trigger: 'change' }
            ]
        },
        templateAdvance: {
            name: '模板名称',
            value: 'templateName',
            advanceLabel: '模板弹出框',
            advanceConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' }
            ],
            tableConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' },
                { prop: 'templateType', value: '模板类型' },
                { prop: 'templateUrl', value: '预览url' }
            ],
            prefix: 'api-lcrm/',
            advanceUrl: 'contractEnclosureInfo/getTemplate',
            cbParams: ['templateCode#templateCode', 'templateName#templateName', 'templateId#templateId', 'attachmentId#attachmentId', 'draftModel#draftModel']
        }
    }
};
export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '客户编码',
            prop: 'customerCode',
            sort: true,
            width: 114
        },
        {
            label: '客户名称',
            prop: 'customerName',
            mustFill: true,
            type: 'advance',

            name: '请输入客户名称或编码查询',
            value: 'customerName',
            width: 200,
            // advanceConfig配置搜索条件，tableConfig配置弹窗中的表格列信息
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' }
            ],
            tableConfig: [
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerChannel', value: '内外渠', type: 'select', optionsKey: 'LMDM_CUSTOMER_CHANNEL' },
                { prop: 'customerStatus', value: '客户状态', type: 'select', optionsKey: 'SYS_WM_CUSTOMER_STATUS' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: '/customer/info/page',
            method: 'post',
            cbParams: ['customerCode', 'customerName#customerName', 'customerChannel'],
            beforeRequest: (params) => {
                params.customerStatus = '1';
                const inputKey = (params.customerName ? params.customerName : (params.customerCode ? params.customerCode : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.customerName = inputKey;
                    params.customerCode = null;
                } else {
                    params.customerCode = inputKey;
                    params.customerName = null;
                }
                return params;
            },
            sort: true
            // advanceCode: 'advanceCode'
        },
        {
            label: '签约主体',
            prop: 'stampSubject',
            type: 'select',
            optionsKey: 'SYS_YES_NO',
            mustFill: true,
            sort: true,
            width: 114
        },
        {
            label: '运作比例',
            prop: 'proportion',
            type: 'input',
            mustFill: true,
            sort: true,
            width: 114
        },
        {
            label: '对账方',
            prop: 'contrastAccount',
            type: 'select',
            defaultValue: 'CU',
            optionsKey: 'ACCOUNTER_TYPE',
            disabled: true,
            sort: true,
            width: 114
        },
        {
            label: '开票方',
            prop: 'openInvoice',
            type: 'select',
            defaultValue: 'CU',
            optionsKey: 'ACCOUNTER_TYPE',
            disabled: true,
            sort: true,
            width: 114
        },
        {
            label: '记账方',
            prop: 'recordAccount',
            type: 'select',
            defaultValue: 'CU',
            optionsKey: 'ACCOUNTER_TYPE',
            disabled: true,
            sort: true,
            width: 114
        },
        {
            label: '印章管理员',
            prop: 'sealAdminName',
            type: 'slot',
            editSlot: true,
            sort: true,
            width: 114
        },
        {
            label: '创建人',
            sort: true,
            prop: 'createUserCode',
            width: 114
        },
        {
            label: '创建时间',
            prop: 'createTime',
            sort: true,
            width: 154
        },
        {
            label: '修改人',
            sort: true,
            prop: 'updateUserCode',
            width: 114
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            sort: true,
            width: 154
        }
    ]
};
export const tableJDSKConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '签约角色',
            prop: 'templateCustomerRole',
            type: 'select',
            optionsKey: 'TEMPLATE_CUSTOMER_ROLE',
            sort: true,
            width: 114
        },
        {
            label: '客户编码',
            prop: 'customerCode',
            sort: true,
            width: 114
        },
        {
            label: '客户名称',
            prop: 'customerName',
            mustFill: true,
            type: 'advance',

            name: '请输入客户名称或编码查询',
            value: 'customerName',
            width: 200,
            // advanceConfig配置搜索条件，tableConfig配置弹窗中的表格列信息
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' }
            ],
            tableConfig: [
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerChannel', value: '内外渠', type: 'select', optionsKey: 'LMDM_CUSTOMER_CHANNEL' },
                { prop: 'customerStatus', value: '客户状态', type: 'select', optionsKey: 'SYS_WM_CUSTOMER_STATUS' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: '/customer/info/page',
            method: 'post',
            cbParams: ['customerCode', 'customerName#customerName', 'customerChannel'],
            beforeRequest: (params) => {
                params.customerStatus = '1';
                const inputKey = (params.customerName ? params.customerName : (params.customerCode ? params.customerCode : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.customerName = inputKey;
                    params.customerCode = null;
                } else {
                    params.customerCode = inputKey;
                    params.customerName = null;
                }
                return params;
            },
            sort: true
            // advanceCode: 'advanceCode'
        },
        {
            label: '签约主体',
            prop: 'stampSubject',
            type: 'select',
            optionsKey: 'SYS_YES_NO',
            mustFill: true,
            sort: true,
            width: 114
        },
        {
            label: '运作比例',
            prop: 'proportion',
            type: 'input',
            mustFill: true,
            sort: true,
            width: 114
        },
        {
            label: '对账方',
            prop: 'contrastAccount',
            type: 'select',
            defaultValue: 'CU',
            optionsKey: 'ACCOUNTER_TYPE',
            disabled: true,
            sort: true,
            width: 114
        },
        {
            label: '开票方',
            prop: 'openInvoice',
            type: 'select',
            defaultValue: 'CU',
            optionsKey: 'ACCOUNTER_TYPE',
            disabled: true,
            sort: true,
            width: 114
        },
        {
            label: '记账方',
            prop: 'recordAccount',
            type: 'select',
            defaultValue: 'CU',
            optionsKey: 'ACCOUNTER_TYPE',
            disabled: true,
            sort: true,
            width: 114
        },
        {
            label: '印章管理员',
            prop: 'sealAdminName',
            type: 'slot',
            editSlot: true,
            sort: true,
            width: 114
        },
        {
            label: '社会信用代码',
            sort: true,
            prop: 'socialCreditCode',
            width: 124
        },
        {
            label: '住所',
            sort: true,
            prop: 'residence',
            width: 114
        },
        {
            label: '法定代表人',
            sort: true,
            prop: 'legalRepresentative',
            width: 114
        },
        {
            label: '联系人',
            sort: true,
            prop: 'contact',
            width: 114
        },
        {
            label: '电话',
            sort: true,
            prop: 'contactNumber',
            width: 114
        },
        {
            label: '电子邮箱',
            sort: true,
            prop: 'email',
            width: 114
        },
        {
            label: '创建人',
            sort: true,
            prop: 'createUserCode',
            width: 114
        },
        {
            label: '创建时间',
            prop: 'createTime',
            sort: true,
            width: 154
        },
        {
            label: '修改人',
            sort: true,
            prop: 'updateUserCode',
            width: 114
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            sort: true,
            width: 154
        }
    ]
};
export const searchBoxConfig = {
    name: 'customerSubTabSearch',
    searchFields: [
        {
            name: '请输入客户名称或编码搜索',
            value: 'customerName',
            type: 'input',
            isFixed: true,
            span: 4
        }
    ]
};
