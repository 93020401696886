<template>
    <div class="price-content">
        <div class="file-detail-subtab price-main-subtab">
            <div class="file-detail-btn" v-if="!readonly">
                <lots-button
                    type="text"
                    icon="el-icon-circle-plus-outline"
                    size="medium"
                    v-has="authorityCode.addNewPriceMain"
                    v-if="isDetailShow"
                    @click="addTemplate(1)"
                    :disabled="!ableEdit">新增
                </lots-button>
                <lots-button
                    v-has="authorityCode.addNewPriceMainMD"
                    v-if="+detailData.contractSource === 3"
                    type="text"
                    icon="el-icon-circle-plus-outline"
                    size="medium"
                    :loading="prefillAddLoading"
                    @click="handelPrefillAdd"
                    :disabled="!ableEdit">产品中台新增
                </lots-button>
                <!-- <lots-button
                    v-has="authorityCode.addPriceDetailS"
                    v-if="[2, 5, 7, 8].includes(detailData.contractStatus)"
                    type="text"
                    icon="el-icon-circle-plus-outline"
                    size="medium"
                    @click="addTemplate(2)">
                    特权新增
                </lots-button> -->

                <lots-button
                    v-has="authorityCode.deletePriceDetail"
                    type="text"
                    icon="el-icon-delete"
                    size="medium"
                    v-if="isDetailShow"
                    @click="closeTab('删除价格表', 'del')"
                    :disabled="!ableEdit">删除
                </lots-button>
                <lots-button
                    type="text"
                    icon="el-icon-copy-document"
                    size="medium"
                    v-if="copyShow"
                    :disabled="!copyEdit"
                    @click="copyTemplate()">
                    复制
                </lots-button>
                <!-- <lots-button
                    v-has="authorityCode.deletePriceDetailS"
                    v-if="[2, 5, 7, 8].includes(+detailData.contractStatus)"
                    type="text"
                    icon="el-icon-delete"
                    size="medium"
                    @click="closeTab('特权删除价格表', 'privilegeDel')">
                    特权删除
                </lots-button> -->
                <span class="loading-box" v-if="+detailData.changeType === 1 && showImportLoading">
                <!-- <span class="loading-box" v-if="true"> -->
                    <i class="el-icon-loading loading-icon"></i>
                    <span class="loading-text">数据处理中</span>
                </span>
            </div>
            <div class="file-detail-table">
                <table-box
                    v-loading="subTabTable.tableLoading"
                    :selection="config.selection"
                    :columns="config.columns"
                    :index="config.index"
                    :height="tableHeight"
                    :rows="subTabTable.totalData"
                    highlight-current-row
                    @sort-change="handleSort"
                    @current-change="handleCurrentChange">
                   <template #col-header="score">
                        <span>{{ score.col.label }}</span>
                        <el-popover
                            placement="bottom"
                            title="筛选条件"
                            :width="200"
                            >
                               <el-input v-if="score.col.headerType === 'input'" v-model="searchHeaderParams[score.col.prop]"
                                    @blur="getheaderParamList()"  @keyup.enter.native="getheaderParamList()"
                                    :placeholder="score.col.placeholder || `请输入${ score.col.label }`"  clearable @clear="getheaderParamList()"></el-input>
                                <lots-select
                                    v-else-if="score.col.headerType === 'select'"
                                    :optionsKey="score.col.params.params.optionsKey ||'' "
                                    :placeholder="score.col.placeholder || `请选择${ score.col.label }`"
                                    v-model="searchHeaderParams[score.col.prop]"
                                    @change="getheaderParamList()"
                                    clearable
                                    style="width: 100%" />
                                <!-- <otpAdvanceMulti
                                    v-else-if="score.col.headerType === 'advance'"
                                    :params="score.col.params"
                                    @getAdvanceValue="(data) => { getMultiInfo(data, score.col); }"
                                    :disabled="_.get(score.col, 'disabled', false)"
                                    :mValue="searchHeaderParams[score.col.prop]"
                                    >
                                </otpAdvanceMulti> -->
                                <lots-md-input
                                    v-else-if="score.col.headerType==='advance'"
                                    :label="searchHeaderParams[score.col.prop]"
                                    :disabled="_.get(score.col, 'disabled', false)"
                                    :config="score.col.params.params"
                                    @change="(data) => { getAdvanceValue(data, score.col); }"
                                ></lots-md-input>
                                <i slot="reference" v-if="score.col.headerType"  @click.stop="changeVisible()"
                                :class="[changeIconActive(searchHeaderParams[score.col.prop]), 'price-icon iconfont icon-ad-search']"></i>
                        </el-popover>
                    </template></table-box>
                <div class="footer">
                    <lots-pagination
                        @size-change="subTabTable.sizeChange"
                        :current-page.sync="subTabTable.pageNo"
                        @current-change="subTabTable.pageChange"
                        size="small"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="subTabTable.pageTotal"
                    />
                </div>
            </div>
            <!-- 新增模板弹窗 -->
            <priceTemplateList
                v-if="priceTemplateListvisible"
                :loading="addPriceLoading"
                :detailData="detailData"
                @handleClose="updateTemplateVisible"
               @confirm="detaliSearchSure">>
            </priceTemplateList>
            <div id="closeMainVisible"></div>
            <CopyPriceTemplate :detailData="detailData" :parentTotalData="subTabTable.totalData" @getDataList="getDataList"  ref="copyPriceRef"/>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, toRefs } from '@vue/composition-api';
import tableBox from '@/components/lots/tableBox/Index';
import lotsButton from '@/components/lots/lotsButton';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import priceTemplateList from '../priceTemplateList/Index.vue';
import { addPriceApi, getPricePageApi, addPrivilegePriceApi, priceDel, pricePrivilegeDel, prefillAddApi, priceImportLock } from '@mdm/api/contractManage/priceApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE, STATE_MAIN } from '@/modules/mdm/constant/contractManage.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import store from '@/store/index';
import { Message, MessageBox } from 'element-ui';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import lotsMdInput from '@/components/lots/lotsMdInput';
import _ from 'lodash';
import CopyPriceTemplate from './CopyPriceTemplate.vue';
export default {
    name: '',
    components: {
        lotsPagination,
        tableBox,
        lotsButton,
        priceTemplateList,
        lotsSelect,
        lotsMdInput,
        CopyPriceTemplate
    },
    emits: ['handleCurrentMain'],
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        activeName: {
            type: String,
            default: () => ''
        },
        tableHeight: {
            type: Number,
            default: 600
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const config = reactive(tableConfig);
        const reference = ref(null);
        const state = reactive({
            searchHeaderParams: {},
            showImportLoading: false,
            _
        });
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                const { contractCode, mainContract, contractChangeCode, contractCharacter } = props.detailData;
                const code = ['3', '5'].includes(String(contractCharacter)) ? mainContract : contractCode;
                condition.contractCode = code;
                condition.orderBy = orderBy.value;
                condition.orderByType = orderByType.value;
                if (isSpecialContract.value) { // 非补充、终止、其他变更，不传originCode（变更来源）
                    condition.originCode = isOtherChange.value ? contractChangeCode : contractCode;
                }
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0 && res.data) {
                    this.totalData.value = res.data.list?.map(it => {
                        it.feeCode = it.feeCode?.toString();
                        return it;
                    }) || [];
                    this.pageNo.value = res.data.pageNo;
                    this.pageSize.value = res.data.pageSize;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: getPricePageApi
            }
        });
        const copyShow = computed(() => {
            // 合同性质:新签、续签、预签 && 其他变更 && 价格信息变更：1
            return [1, 2, 4].includes(+props.detailData.contractCharacter) && [STATE_OTHER_CHANGE].includes(props.detailPageState) && +props.detailData.changeType === 1;
        });
        const copyEdit = computed(() => {
            // contractStatus 草稿 新增 生效 失效 审批通过  审批中 && contractChangeStatus 新建、待提交
            return [0, 1, 2, 5, 6, 9].includes(+props.detailData.contractStatus) && [1, 5].includes(+props.detailData.contractChangeStatus);
        });
        const ableEdit = computed(() => {
            // return ![STATE_REPLENISH, STATE_STOP].includes(props.detailPageState);
            if ([STATE_REPLENISH, STATE_STOP].includes(props.detailPageState)) {
                // 补充协议 终止协议
                return false;
            } else if (isOtherChange.value) {
                // 新建、待提交  && changeType:1价格信息
                return [1, 5].includes(+props.detailData.contractChangeStatus) && +props.detailData.changeType === 1;
            } else {
                // 草稿 新增
                return ['0', '1'].includes(props.detailData.contractStatus + ''); // 注意：+null === 0
            }
        });
        const isDetailShow = computed(() => {
            // 合同详情不展示 主合同、终止协议 补充协议
            if ([STATE_MAIN, STATE_REPLENISH, STATE_STOP].includes(props.detailPageState)) {
                return false;
            }
            return true;
        });
        const isOtherChange = computed(() => {
            return props.detailPageState === STATE_OTHER_CHANGE;
        });
        const isSpecialContract = computed(() => {
            return [STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE].includes(props.detailPageState);
        });
        const btnLoading = ref(false);
        const currentRow = ref(null);
        const handleCurrentChange = (row) => {
            if (row && JSON.stringify(row) !== '{}') {
                currentRow.value = row;
            } else {
                currentRow.value = null;
            }
            setupContext.emit('handleCurrentMain', currentRow.value);
        };
        const getDataList = () => {
            subTabTable.getList();
            currentRow.value = null; // 重置当前行
            setupContext.emit('handleCurrentMain', null);
        };
        const addType = ref(1); // 1-新增 2-特权新增
        const addTemplate = (val) => {
            addType.value = val;
            updateTemplateVisible(true);
        };
        // 新增价目表
        const priceTemplateListvisible = ref(false);
        const updateTemplateVisible = (val) => {
            priceTemplateListvisible.value = val;
        };
        const addPriceLoading = ref(false);
        // 选择计费模板回调函数 ==> 新增价格表操作
        const detaliSearchSure = async (data) => {
            const { contractCode, mainContract, contractChangeCode, contractCharacter } = props.detailData;
            const code = ['3', '5'].includes(String(contractCharacter)) ? mainContract : contractCode;
            const params = {
                ...data,
                contractCode: code,
                originCode: isOtherChange.value ? contractChangeCode : contractCode,
                createUserCode: store.getters.user.userCode,
                updateUserCode: store.getters.user.userCode
            };
            let addPriceData = null;
            addPriceLoading.value = true;
            if (addType.value === 1) {
                addPriceData = await addPriceApi(params);
            } else {
                addPriceData = await addPrivilegePriceApi(params);
            }
            addPriceLoading.value = false;
            if (addPriceData && +addPriceData.code === 0) {
                updateTemplateVisible(false);
                Message.success('新增成功');
                getDataList();
            }
        };
        const detailLoading = ref(false);
        const closeTab = (name, type) => { // type === del 删除价格表，privilegeDel 特权删除价格表，
            if (JSON.stringify(currentRow.value) === '{}' || !currentRow.value) {
                return Message.warning('请选中一条价格表数据操作');
            }
            if (+currentRow.value.status === 1 && +currentRow.value.changeType === 1) { // changeType: 0新增/1删除/2修改
                return Message.warning('状态为启用时，变更类型为删除时，不能删除');
            }
            MessageBox.confirm(`删除将清空当前价格表所有明细且不可逆，请确认！`, `${name}`, {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const apiList = {
                    del: priceDel,
                    privilegeDel: pricePrivilegeDel
                };
                const { contractCode, mainContract, contractCharacter, contractChangeCode } = props.detailData;
                const code = ['3', '5'].includes(String(contractCharacter)) ? mainContract : contractCode;
                const params = {
                    del: {
                        priceCode: currentRow.value.priceCode,
                        contractCode: code,
                        originCode: isOtherChange.value ? contractChangeCode : contractCode
                    },
                    privilegeDel: {
                        priceCode: currentRow.value.priceCode,
                        contractCode: code,
                        originCode: isOtherChange.value ? contractChangeCode : contractCode
                    }
                };
                detailLoading.value = true;
                apiList[type](params[type]).then(res => {
                    if (+res.code === 0) {
                        Message.success('删除成功');
                        getDataList();
                    }
                }).finally(() => {
                    detailLoading.value = false;
                });
            }).catch(() => {});
        };
        // 产品中台新增
        const prefillAddLoading = ref(false);
        const handelPrefillAdd = () => {
            const { contractCode, mainContract, contractCharacter, contractChangeCode } = props.detailData;
            const code = ['3', '5'].includes(String(contractCharacter)) ? mainContract : contractCode;
            prefillAddLoading.value = true;
            prefillAddApi({
                contractCode: code,
                originCode: isOtherChange.value ? contractChangeCode : contractCode
            }).then(res => {
                if (+res.code === 0) {
                    Message.success('价格表信息预填充成功');
                    getDataList();
                }
            }).finally(() => {
                prefillAddLoading.value = false;
            });
        };
        const changeVisible = () => {
            document.getElementById('closeMainVisible').click(); // doClose失效 ,Visible 绑定无法更新到视图 用于关闭其他el-popover弹窗
        };
        const getAdvanceValue = (data, item) => {
            state.searchHeaderParams[item.prop] = data[item.prop];
            subTabTable.getList(state.searchHeaderParams);
        };
        const getheaderParamList = () => {
            subTabTable.getList(state.searchHeaderParams);
            // changeVisible();
        };
        const orderBy = ref('');
        const orderByType = ref('');

        // 排序
        const handleSort = ({ prop, order }) => {
            orderBy.value = prop.replace(/([A-Z])/g, '_$1').toLowerCase();
            orderByType.value = order === 'ascending' ? 'asc' : 'desc';
            subTabTable.getList(state.searchHeaderParams);
        };
        const copyTemplate = () => {
            setupContext.refs.copyPriceRef.show();
        };
        const errlist = [undefined, null, ''];
        const changeIconActive = (row) => {
            return errlist.includes(row) ? '' : 'icon-active';
        };
        let timer = null;
        const checkTime = 5000;
        const pollingImportLock = async () => {
            if (props.readonly) return; // 合同查询不需要,能导入的才需要
            await checkPriceImportLock();
            if (state.showImportLoading) {
                clearInterval(timer);
                timer = setInterval(checkPriceImportLock, checkTime);
            }
        };
        const checkPriceImportLock = () => {
            if (!props.detailData.contractCode || !isOtherChange.value) {
                timer && clearInterval(timer);
                return;
            };
            return priceImportLock({
                contractCode: props.detailData.contractCode
            }).then(res => {
                if (res && +res.code === 0) {
                    // true为加锁
                    state.showImportLoading = res.data;
                    if (!res.data) {
                        timer && clearInterval(timer);
                        setupContext.emit('priceDetailList');
                    }
                }
            }).catch(() => {
                timer && clearInterval(timer);
            });
        };
        return {
            ...toRefs(state),
            config,
            authorityCode,
            subTabTable,
            btnLoading,
            getDataList,
            handleCurrentChange,
            addTemplate,
            priceTemplateListvisible,
            updateTemplateVisible,
            addPriceLoading,
            detaliSearchSure,
            closeTab,
            prefillAddLoading,
            handelPrefillAdd,
            isOtherChange,
            ableEdit,
            reference,
            changeVisible,
            getAdvanceValue,
            getheaderParamList,
            handleSort,
            copyTemplate,
            copyEdit,
            copyShow,
            changeIconActive,
            isDetailShow,
            pollingImportLock
        };
    }
};
</script>
<style lang="less" scoped>
.file-detail-table{
    .price-icon{
        font-size: 12px;
        cursor: pointer;
        color: #000;
    }
}
.icon-active{
    color: #4285f5!important;
}
</style>
<style lang="less">
.price-main-subtab .loading-box{
    float: right;
    margin-right: 10px;
    line-height: 32px;
    .loading-text{
        display: inline-block;
        color: #FF5252;
    }
    .loading-icon {
        color: #FF5252;
        margin-right: 5px;
        animation: spin 2s linear infinite!important;
    }
    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
}
</style>
