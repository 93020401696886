<template>
    <div>
        <el-dialog width="1200px" :visible.sync="dialogVisible" destroy-on-close :append-to-body="true"
        @opened="dialogOpen" @close="dialogCloseAction">
            <div slot="title" class="column-config_title">
                {{ dialogTitle }}
            </div>
        <!-- 表格 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <div>
                        <search-box ref="searchBox" :search-key="priceConfig.name" :fields="priceConfig.searchFields" :showResetBtn="true"
                            @search-change="subTabTable.searchList" />
                        <table-box ref="priceTableRef" :height="tableHeight" v-loading="subTabTable.tableLoading"
                            :selection="priceConfig.selection" :index="priceConfig.index" :columns="priceConfig.columns"
                            :rows="subTabTable.totalData" @row-click="rowClick"
                            >
                        </table-box>
                        <lots-pagination class="mt10" @size-change="subTabTable.sizeChange" :current-page.sync="subTabTable.pageNo"
                            @current-change="subTabTable.pageChange" layout="total, sizes, prev, pager, next, jumper"
                            :total="subTabTable.pageTotal">
                        </lots-pagination>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="right-table">
                        <search-box ref="tempSearchBox" :search-key="copyPriceConfig.name" :fields="copyPriceConfig.searchFields" :showResetBtn="true"
                            @search-change="tempTable.searchList" />
                        <table-box ref="copyTempRef" :height="tableHeight" v-loading="tempTable.tableLoading"
                            :selection="copyPriceConfig.selection" :index="copyPriceConfig.index" :columns="copyPriceConfig.columns"
                            :rows="tempTable.totalData" @row-click="rowCopyClick"  @selection-change="handleSelectionChange"
                        >
                        </table-box>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="dialogCloseAction">取 消</el-button>
                                <el-button type="primary" @click="confirm">确 定</el-button>
                            </span>
                    </div>
                </el-col>
            </el-row>

        </el-dialog>
        <el-dialog width="450px" :visible.sync="selectDialogVisible" destroy-on-close :append-to-body="true"
        @close="statusConfirmCloseAction">
            <div slot="title" class="column-config_title">
                价格表明细状态
            </div>
            <div>
                <el-form
                    ref="formRef"
                    :model="formData"
                    label-width="120px"
                    label-position="left"
                    class="form-box"
                    size="small"
                    :rules="rules">
                        <el-form-item label="价格表明细状态" prop="status">
                            <el-radio-group v-model="formData.status">
                                <el-radio :label="item.label" v-for="item in options" :key="item.key" @change="radioChangeHandle()">{{item.val}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                </el-form>
                <div class="form-tips" v-if="priceTempTips">价格模版：{{ priceTempTips }}价格明细为空</div>
                <span slot="footer" class="dialog-footer-cofirm">
                    <el-button @click="statusConfirmCloseAction">取消复制</el-button>
                    <el-button type="primary" :loading="statusConfirmLoading" @click="statusConfirm">复 制</el-button>
                </span>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import { ref, reactive, toRefs } from '@vue/composition-api';
import { Message, MessageBox } from 'element-ui';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { getContractInfoAuthList } from '@mdm/api/contractManage/contractListApi.js';
import { saveCopyPriceTemplateApi, getCopyPriceTemplateApi, getCheckPriceTemplate } from '@mdm/api/contractManage/priceApi.js';
import _ from 'lodash';
import { priceConfig, copyPriceConfig } from './config.js';
import tableBox from '@/components/lots/tableBox/Index';
import searchBox from '@/components/lots/searchBox';
import lotsPagination from '@/components/lots/lotsPagination/Index';
export default {
    name: '',
    components: {
        tableBox,
        searchBox,
        lotsPagination
    },
    props: {
        detailData: {
            type: Object,
            default () {
                return {};
            }
        },
        tableHeight: {
            type: Number,
            default: 355
        },
        dialogTitle: {
            type: String,
            default: () => '合同复制计费模版'
        },
        parentTotalData: {
            type: Array,
            default: () => []
        }
    },
    /* eslint-disable max-lines-per-function */
    setup (props, setupContext) {
        const state = reactive({
            dialogVisible: false,
            selectDialogVisible: false,
            _,
            priceConfig, // 合同列表配置
            copyPriceConfig, // 复制的计费模版配置
            formData: {
                status: '1' // 默认启用状态
            },
            currentRow: {},
            options: [
                { label: '1', val: '启用' },
                { label: '0', val: '草稿' },
                { label: '0,1', val: '草稿+启用' }
            ],
            statusConfirmLoading: false,
            priceTempTips: '' // 价格模版明细为空提示
        });
        const rules = reactive({
            status: [
                { required: true, message: '请选择', trigger: 'change' }
            ]
        });
        class DataDetailClass extends AdvanceListClass {
            beforeGetList (condition) {
                condition.rejectContractCode = props.detailData.contractCode;
                return condition;
            };
            afterGetList (res) {
                if (+res.code === 0 && res.data) {
                    this.totalData.value = res.data.list?.map(it => {
                        it.feeCode = it.feeCode?.toString();
                        return it;
                    }) || [];
                    this.pageNo.value = res.data.pageNo;
                    this.pageSize.value = res.data.pageSize;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: getContractInfoAuthList
            }
        });
        class TempDataDetailClass extends AdvanceListClass {
            beforeGetList (condition) {
                condition.contractCode = state.currentRow.contractCode;
                return condition;
            };
            afterGetList (res) {
                if (+res.code === 0 && res.data) {
                    this.totalData.value = res.data;
                }
            };
        }
        // 根据页面类型来获取对应的接口
        const tempTable = new TempDataDetailClass({
            Api: {
                listApi: getCopyPriceTemplateApi
            }
        });
        const dialogCloseAction = () => {
            state.dialogVisible = false;
            selectedList.value = [];
            state.currentRow = {};
        };
        // 打开初始化列表参数
        const dialogOpen = () => {
            const queryParams = {
                contractStatuss: ['2', '5'] // 默认选中有效、失效
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
            const tempQueryParams = {
                businessTypeList: props.detailData.businessType
            };
            setupContext.refs.tempSearchBox.setQueryParams(tempQueryParams);
        };
        const show = () => {
            state.dialogVisible = true;
        };
        const confirm = () => {
            if (!selectedList.value.length) {
                return Message.warning('请选择需要复制的计费模版');
            }
            state.selectDialogVisible = true;
            getPriceTempDetailInfo();
        };
        // 校验是否重复
        const statusConfirm = () => {
            setupContext.refs.formRef.validate((flag) => {
                if (flag) {
                    const commonObjects = _.intersectionWith(props.parentTotalData, selectedList.value, (obj1, obj2) => obj1.templateCode === obj2.templateCode);
                    if (commonObjects.length) {
                        const _repeatText = commonObjects.map(item => item.templateName).join('、');
                        MessageBox.confirm(`计费模板${_repeatText}已存在, 是否覆盖明细?`, '提示', {
                            confirmButtonText: '覆盖明细',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            saveCopyPriceHandle();
                        }).catch(() => {
                            state.selectDialogVisible = false;
                        });
                    } else {
                        saveCopyPriceHandle();
                    }
                }
            });
        };
        // 复制确定事件
        const saveCopyPriceHandle = () => {
            const params = {
                copyContractCode: state.currentRow.contractCode,
                contractCode: props.detailData.contractCode,
                originCode: props.detailData.contractChangeCode,
                templateCodeList: selectedList.value.map(it => {
                    return it.templateCode;
                }),
                statusList: state.formData.status.split(',')
            };
            state.statusConfirmLoading = true;
            saveCopyPriceTemplateApi(params).then(res => {
                if (res && +res.code === 0) {
                    Message.success('复制成功');
                    dialogCloseAction();
                    setupContext.emit('getDataList');
                };
            }).finally(() => {
                state.dialogVisible = false;
                state.selectDialogVisible = false;
                state.statusConfirmLoading = false;
            });
        };
        // 关闭弹窗
        const statusConfirmCloseAction = () => {
            state.selectDialogVisible = false;
            state.formData.status = '1'; // 初始化为启用状态
        };
        const rowClick = (row) => {
            if (row.contractCode === props.detailData.contractCode) {
                tempTable.totalData.value = [];
                selectedList.value = [];
                return Message.warning('不可复制当前合同，请选择其他合同！');
            }
            state.currentRow = row;
            tempTable.getList({
                contractCode: row.contractCode
            });
        };
        const rowCopyClick = (row) => {
            setupContext.refs.copyTempRef.$refs.elTable.toggleRowSelection(row);
        };
        const selectedList = ref([]);
        const handleSelectionChange = (data) => {
            selectedList.value = data;
        };
        // 获取计费模版-价格明细是否为空
        const getPriceTempDetailInfo = () => {
            const params = {
                copyContractCode: state.currentRow.contractCode,
                statusList: state.formData.status.split(','),
                templateCodeList: selectedList.value.map(it => {
                    return it.templateCode;
                })
            };
            getCheckPriceTemplate(params).then(res => {
                if (res && +res.code === 0) {
                    state.priceTempTips = res.data?.map(it => {
                        return it;
                    }).join('，') || '';
                }
            });
        };
        const radioChangeHandle = () => {
            getPriceTempDetailInfo();
        };
        return {
            ...toRefs(state),
            subTabTable,
            tempTable,
            dialogCloseAction,
            show,
            confirm,
            rowClick,
            dialogOpen,
            handleSelectionChange,
            statusConfirm,
            statusConfirmCloseAction,
            rules,
            rowCopyClick,
            getPriceTempDetailInfo,
            radioChangeHandle
        };
    }
};
</script>
<style lang="less" scoped>
.dialog-footer{
    float: right;
    margin: 10px;
}
.mt10{
    margin-top: 10px;
}
.form-box{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-tips{
    padding-bottom: 30px;
    margin: 0 35px;
    color: rgb(255, 82, 82);
    font-size: 12px;
}
.dialog-footer-cofirm{
    display: flex;
    justify-content: flex-end;
    padding: 0 30px 10px 0;
}
</style>
