<template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button type="text" icon="el-icon-circle-plus-outline" size="medium" @click="handleAddRow" :disabled="isDisableBtn" v-has="authorityCode.subAddNewOil">新增</lots-button>
            <lots-button type="text" icon="el-icon-circle-check" size="medium" @click="handleSaveRows" :disabled="isDisableBtn" v-has="authorityCode.subSaveOil" :loading="saveLoadong">保存</lots-button>
            <lots-button type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows" :disabled="isDisableBtn"
                v-has="authorityCode.subDeleteOil">删除</lots-button>
        </div>
        <div class="file-detail-table">
                <edit-table-box
                    class="prescreen"
                    :control="true"
                    v-loading="subTabTable.tableLoading"
                    ref="editTableBox"
                    :selection="config.selection"
                    :columns="config.columns"
                    :height="tableHeight"
                    :tableData="subTabTable.totalData"
                    :actions="config.actions"
                    :editDisableRule="() => isDisableBtn"
                    @selection-change="handleSelectedRow"
                    @element-change="switchStatus">
                </edit-table-box>
            <div class="footer">
                <lots-pagination
                    @size-change='subTabTable.sizeChange'
                    :current-page.sync='subTabTable.pageNo'
                    @current-change='subTabTable.pageChange'
                    size="small" layout='total, sizes, prev, pager, next, jumper'
                    :total='subTabTable.pageTotal'/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch, onActivated } from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    oilInfoList,
    oilInfoDelete,
    oilInfoSave,
    otherChangeOilInfoList,
    otherChangeOilInfoDelete,
    otherChangeOilInfoSave
} from '@mdm/api/contractManage/contractFootPageApi.js';
import { STATE_OTHER_CHANGE, STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { Message } from 'element-ui';
import lotsButton from '@/components/lots/lotsButton';

export default {
    name: '',
    components: { lotsPagination, editTableBox, lotsButton },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const config = reactive(tableConfig);
        const isDisableBtn = ref(false);
        let listApi = reactive({});
        let deleteApi = reactive({});
        if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
            listApi = otherChangeOilInfoList;
            deleteApi = otherChangeOilInfoDelete;
        } else {
            listApi = oilInfoList;
            deleteApi = oilInfoDelete;
        }
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.detailData.contractCode === '0000' ? '' : props.detailData.contractCode;
                if (props.detailPageState === STATE_OTHER_CHANGE && +props.detailData.changeType === 0) {
                    condition.contractChangeCode = props.detailData.contractChangeCode;
                }
                return condition;
            };
        };
        const subTabTable = new DataDetailClass({
            Api: {
                listApi,
                deleteApi
            }
        });

        const btnLoading = ref(false);
        const handleAddRow = () => {
            setupContext.refs.editTableBox.addRow();
        };
        const selectedRows = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selectedRows.value = selection;
        };
        const switchStatus = () => {};
        const saveLoadong = ref(false);
        const handleSaveRows = async () => {
            if (!subTabTable.totalData.value.length) {
                return Message.warning('目前无数据无法保存！');
            }
            try {
                subTabTable.totalData.value.forEach(v => {
                    if (setupContext.refs.editTableBox.saveItemData(v) === false) {
                        throw Error();
                    }
                });
            } catch (err) { return; }
            subTabTable.totalData.value.forEach(e => {
                e.contractCode = props.detailData.contractCode === '0000' ? '' : props.detailData.contractCode;
                e.contractChangeCode = props.detailData.contractChangeCode || null;
                e.reletiveProvinceCode = String(e.reletiveProvinceCode);
            });
            let res = '';
            saveLoadong.value = true;
            if (props.detailPageState === STATE_OTHER_CHANGE) {
                res = await otherChangeOilInfoSave(subTabTable.totalData.value);
            } else {
                res = await oilInfoSave(subTabTable.totalData.value);
            }
            saveLoadong.value = false;
            if (res && +res.code === 0) {
                Message({
                    message: '成功保存',
                    type: 'success'
                });
                subTabTable.getList(); // 刷新列表
            }
        };
        const handleDeleteRows = async () => { // 调批量删除接口
            if (selectedRows.value.length === 0) {
                Message.error('请选择需要删除的条目');
                return;
            }
            let passFlag = true;
            selectedRows.value.forEach(item => {
                if (!item.id) {
                    passFlag = false;
                }
            });
            if (!passFlag) {
                return Message.warning('存在新增未保存的数据，请先保存或取消编辑');
            }
            const ids = JSON.parse(JSON.stringify(selectedRows.value)).map((v) => v.id);
            subTabTable.deleteRow({ ids }, false);
        };
        watch(
            () => [props.detailPageState, props.detailData.contractChangeStatus, props.detailData.contractStatus, props.activeName, props.detailData.contractCode],
            // eslint-disable-next-line complexity
            (newVal) => {
                if (newVal[3] === 'oilDetail') {
                    if (newVal[0] === STATE_NEW) {
                        isDisableBtn.value = false;
                    } else if (newVal[0] === STATE_OTHER_CHANGE) {
                        isDisableBtn.value = newVal[1] + '' === 'undefined' || newVal[1] + '' !== '1' || ([1, 2].includes(+props.detailData.changeType)); // 价格信息、日期信息变更只读
                    } else {
                        isDisableBtn.value = !(newVal[2] + '' === '0' || newVal[2] + '' === '1');
                    }
                    if (newVal[4]) {
                        if ((newVal[0] !== STATE_NEW) && (newVal[0] !== STATE_OTHER_CHANGE)) {
                            subTabTable.getList();
                        } else if (newVal[0] === STATE_OTHER_CHANGE && props.detailData.contractChangeCode) {
                            subTabTable.getList();
                        }
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout();
        });
        return {
            isDisableBtn,
            config,
            subTabTable,
            btnLoading,
            handleAddRow,
            handleSelectedRow,
            switchStatus,
            handleSaveRows,
            handleDeleteRows,
            authorityCode,
            saveLoadong
        };
    }
};
</script>
