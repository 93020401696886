<template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button type="text" icon="el-icon-delete" size="medium" @click="handleDeleteRows" :disabled="isDisableBtn"
                v-has="authorityCode.deleteMain">删除</lots-button>
        </div>
        <div class="file-detail-table">
            <table-box
                ref="tableBoxRead"
                :height="tableHeight"
                :selection="true"
                v-loading="subTabTable.tableLoading"
                :columns="config.columns"
                :rows="subTabTable.totalData"
                :actions="actions"
                @selection-change="handleSelectedRow"
                @action-click="actionClick"
                @row-dblclick="rowDBclick"></table-box>
            <div class="footer">
                <lots-pagination
                    @size-change='subTabTable.sizeChange'
                    :current-page.sync='subTabTable.pageNo'
                    @current-change='subTabTable.pageChange'
                    size="small" layout='total, sizes, prev, pager, next, jumper'
                    :total='subTabTable.pageTotal'/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch, onActivated } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { getContractInfoList, contractDelete } from '@mdm/api/contractManage/contractListApi.js';
import { STATE_OTHER_CHANGE, STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { Message } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';

export default {
    name: '',
    components: { tableBox, lotsPagination, lotsButton },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const btnPermissionsArr = store.getters.btnPermissions;
        const config = reactive(tableConfig);
        const actions = reactive({
            fixedWidth: 160,
            list: [
                {
                    label: '删除',
                    event: 'delItem',
                    displayRule: (row) => {
                        return row.contractStatus === 0 && btnPermissionsArr.includes(authorityCode.deleteMain) && !props.readonly;
                    }
                }
            ]
        });
        const actionClick = (event, row) => {
            if (event === 'delItem') {
                subTabTable.deleteRow(row);
            }
        };
        const isDisableBtn = ref(false);
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.mainContract = props.detailData.contractCode;
                return condition;
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        v.validTime = utils.formatDate(v.validTime);
                        v.failTime = utils.formatDate(v.failTime);
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        };
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: getContractInfoList,
                deleteApi: contractDelete
            }
        });

        const btnLoading = ref(false);
        const selectedRows = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selectedRows.value = selection;
        };
        const handleDeleteRows = async () => { // 调批量删除接口
            if (selectedRows.value.length === 0) {
                Message.error('请选择需要删除的条目');
                return;
            }
            const ids = JSON.parse(JSON.stringify(selectedRows.value)).map((v) => {
                if (+v.contractStatus === 0) {
                    return v.id;
                } else {
                    return null;
                }
            });
            subTabTable.deleteRow({ ids }, false);
        };
        const rowDBclick = async (row) => {
            setupContext.emit('subTabDbClick', row);
        };
        watch(
            () => [props.detailPageState, props.detailData.contractStatus, props.detailData.contractChangeStatus, props.activeName, props.detailData.contractCode],
            (newVal) => {
                if (newVal[3] === 'relationDetail') { // props.activeName
                    if (newVal[0] === STATE_NEW) {
                        isDisableBtn.value = false;
                    } else if (newVal[0] === STATE_OTHER_CHANGE) {
                        isDisableBtn.value = newVal[2] + '' === 'undefined' || newVal[2] + '' !== '1';
                    } else {
                        isDisableBtn.value = !(newVal[1] + '' === '0' || newVal[1] + '' === '1');
                    }
                    if (newVal[4]) { // contractCode
                        if ((newVal[0] !== STATE_NEW) && (newVal[0] !== STATE_OTHER_CHANGE)) {
                            subTabTable.getList();
                        }
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        onActivated(() => {
            getDataFn();
        });
        const getDataFn = () => {
            props.activeName === 'relationDetail' && subTabTable.getList();
        };
        return {
            config,
            actions,
            actionClick,
            isDisableBtn,
            subTabTable,
            btnLoading,
            handleSelectedRow,
            handleDeleteRows,
            rowDBclick,
            authorityCode,
            getDataFn
        };
    }
};
</script>
